<template>
  <div class="wrapper">
    <Sidebar/>
    <div class="content-wrapper">
      <AlertErrorMsg
          :title="errorMessage"
          class="page-alert-error"
          ref="alert"
      />
      <HeaderRegistered :returnPath="returnPath" :subTitle="caseTitle" />
      <main class="main">
        <section class="case-export-section">
          <div class="case-action-btn-wrap">
            <div class="format-choose-wrapper">
              <p class="format-choose-text">Choose the format</p>
              <CustomSelect
                :default="formats[0].name"
                :typeSelect="'filterMessengers'"
                v-model="currentFormat"
                :custom-class="'select-databases'"
                :options="formats" />
            </div>
            <button
                :disabled="!checkPermission(['export_case']) || isTimerRunning"
                class="btn"
                @click="actionExportCase"
                style="height: 40px"
            >Export Case</button>
            <div class="timer-wrapper">
              <div class="timer" v-if="isTimerRunning">
                <span>Time remaining for another case export request: </span>{{ remainingTime }} sec.</div>
            </div>
          </div>
          <div class="holder-table">
            <table cellpadding="0" cellspacing="0" border="0">
              <TableHeader>
                <tr>
                  <th>
                    <div
                        class="table-header-box"
                    >
                      <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.0625 0.5C3.17853 0.5 3.28981 0.549386 3.37186 0.637294C3.45391 0.725201 3.5 0.84443 3.5 0.96875V1.4375H10.5V0.96875C10.5 0.84443 10.5461 0.725201 10.6281 0.637294C10.7102 0.549386 10.8215 0.5 10.9375 0.5C11.0535 0.5 11.1648 0.549386 11.2469 0.637294C11.3289 0.725201 11.375 0.84443 11.375 0.96875V1.4375H12.25C12.7141 1.4375 13.1592 1.63504 13.4874 1.98667C13.8156 2.33831 14 2.81522 14 3.3125V13.625C14 14.1223 13.8156 14.5992 13.4874 14.9508C13.1592 15.3025 12.7141 15.5 12.25 15.5H1.75C1.28587 15.5 0.840752 15.3025 0.512563 14.9508C0.184374 14.5992 0 14.1223 0 13.625V5.1875H14V4.25H0V3.3125C0 2.81522 0.184374 2.33831 0.512563 1.98667C0.840752 1.63504 1.28587 1.4375 1.75 1.4375H2.625V0.96875C2.625 0.84443 2.67109 0.725201 2.75314 0.637294C2.83519 0.549386 2.94647 0.5 3.0625 0.5V0.5Z" fill="#D7DCE6"/>
                      </svg>
                      <span class="header-table-text">Date</span>
                    </div>
                  </th>
                  <th class="w-300">
                    <div
                        class="table-header-box"
                    >
                      <UserIcon />
                      <span class="header-table-text">User Name</span>
                    </div>
                  </th>

                  <th>
                    <div
                        :id="`sort_export_in_case_${caseId}_by_size`"
                        class="table-header-box">
                      <CaseIcon />
                      <span class="header-table-text">Case name</span>
                    </div>
                  </th>

                  <th>
                    <div
                        :id="`sort_export_in_case_${caseId}_by_status`"

                        class="table-header-box">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8 16C6.55208 16 5.21354 15.6432 3.98438 14.9297C2.75521 14.2161 1.78385 13.2448 1.07031 12.0156C0.356771 10.7865 0 9.44792 0 8C0 6.55208 0.356771 5.21354 1.07031 3.98438C1.78385 2.75521 2.75521 1.78385 3.98438 1.07031C5.21354 0.356771 6.55208 0 8 0C9.44792 0 10.7865 0.356771 12.0156 1.07031C13.2448 1.78385 14.2161 2.75521 14.9297 3.98438C15.6432 5.21354 16 6.55208 16 8C16 9.44792 15.6432 10.7865 14.9297 12.0156C14.2161 13.2448 13.2448 14.2161 12.0156 14.9297C10.7865 15.6432 9.44792 16 8 16ZM1 8C1 8.94792 1.17969 9.84896 1.53906 10.7031C1.89844 11.5573 2.40104 12.3073 3.04688 12.9531L8 8V1C7.05208 1 6.14583 1.1849 5.28125 1.55469C4.41667 1.92448 3.67188 2.42188 3.04688 3.04688C2.42188 3.67188 1.92448 4.41667 1.55469 5.28125C1.1849 6.14583 1 7.05208 1 8Z"
                            fill="#D7DCE6"/>
                      </svg>
                      <span class="header-table-text">status</span>
                    </div>
                  </th>
                  <th></th>
                </tr>
              </TableHeader>
              <CaseExportItem
                  :caseExportList="caseExportList"
                  @downloadItem="downloadItem"
              />
              <div class="loader-bg" v-if="loadFlag">
                <div class="loader-p"></div>
              </div>
            </table>
            <pagination v-if="caseExportList && caseExportList.length && count !== 1"
                        v-model="page"
                        :total-pages="count"
                        :link-gen="linkGen"
            ></pagination>
          </div>
        </section>
      </main>
    </div>
    <CommonDialog
      ref="createCaseDialog"
      :show-footer="false"
      :closable="false"
    >
      <h2 class="dialog-message-title">Exporting case</h2>
      <p class="dialog-create-description" style="display:block; font-size: 14px;">Please wait a bit. The case is being exported.</p>
      <div class="loader-p"></div>
    </CommonDialog>
  </div>
</template>

<script>
import checkPermission from "../../../utils/permission";
import CaseIcon from "../../icons/CaseIcon"
import UserIcon from "../../icons/UserIcon";
import Sidebar from "../../Sidebar";
import AlertErrorMsg from "../../profile/AlertErrorMsg";
import HeaderRegistered from "../../layout/HeaderRegistered";
import TableHeader from "../../table/TableHeader";
import CaseExportItem from "./CaseExportItem";
import Pagination from "@/components/common/Pagination.vue";
import CustomSelect from "@/components/inputs/CustomSelect.vue";
import CommonDialog from "@/components/popups/CommonDialog.vue";
import capitalizeFirstLetter from "@/mixins/capitalizeFirstLetter";
export default {
  name: "CaseExport",
  components: {
    CommonDialog,
    CustomSelect,
    Pagination, CaseIcon, UserIcon, CaseExportItem, TableHeader, HeaderRegistered, AlertErrorMsg, Sidebar},
  data() {
    return {
      returnPath: true,
      caseId: this.$route.params.slug,
      caseExportList: [],
      loadFlag: false,
      errorMessage: '',
      caseTitle: '',
      page: 1,
      size: 10,
      count: 0,
      pageSize: 2,
      currentFormat: null,
      formats: [
        { name: 'pdf', id: 0},
        { name: 'csv', id: 1},
        { name: 'ufdr', id: 2},
        { name: 'rsmf', id: 3},
      ],
      dialog: {
        status: "",
        text: "Starting..."
      },
      progressPercentage: 0,
      remainingTime: 60,
      timerInterval: null,
      isTimerRunning: false,
    }
  },
  computed: {
    subTitle() {
      return ` Case ${this.caseId}`
    }
  },
  watch: {
    '$route.query.page': {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.page = newVal
          this.getCaseExport()
        } else {
          this.page = 1
        }
      }
    },
  },
  mixins: [capitalizeFirstLetter],
  methods: {
    checkPermission,
    linkGen(pageNum) {
      return {
        path: `/cases/${this.caseId}/export`,
        query: {page: pageNum}
      }
    },
    downloadItem(item) {
      window.$axiosFiles.get(`/cases/${this.$route.params.slug || this.$route.params.case_id}/export/${item.exportId}/download`, {
        responseType: "blob",
      })
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", item.key);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
          })
          .catch(error => {
            this.errorMessage = error.response.data.message;
          });
    },
    actionExportCase() {
      this.$load(async () => {
        this.$refs.createCaseDialog.openPopups();

        const response = await this.$api.cases.exportCases(this.caseId, this.currentFormat);
        // eslint-disable-next-line no-undef
        const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();

        await this.readStreamData(reader);

        if (!this.dialog.status.includes("error")) {
          this.dialog.text = "Starting...";
          this.dialog.status = "";
          this.progressPercentage = 0;
        }

        this.startTimer();
        await this.getCaseExport();

        setTimeout(() => {
          this.loadFlag = false;
        }, 1000);
      });
    },
    async readStreamData(reader) {
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          this.$refs.createCaseDialog.closePopups();
          break;
        }
        const splitChunks = value.toString().split("\n");
        for (let i = 0; i < splitChunks.length; i++) {
          if (splitChunks[i].trim()) {
            if (splitChunks[i].includes("event:")) {
              const dataStatus = splitChunks[i].split("event:").find(chunk => chunk.trim() !== '');
              if (dataStatus && typeof dataStatus !== "undefined") {
                this.dialog.status = dataStatus;
              }
            }
            if (splitChunks[i].includes("data:")) {
              const dataText = splitChunks[i].split("data:").find(chunk => chunk.trim() !== '');
              if (dataText && typeof dataText !== "undefined") {
                this.dialog.text = dataText;
              }
            }
          }
        }
        this.progressPercentage += 25;
      }
    },
    getCaseExport() {
      this.$load(async () => {
        // this.loadFlag = true
        await this.$api.cases.getExportCases({
          caseId: this.caseId,
          page: this.page,
          size: this.size,
        }).then((res) => {
          this.caseExportList = res.data.body.content
          this.count = res.data.body.totalPages
          this.pageSize = res.data.body.size
        }, (error) => {
          if (error.response) {
            this.errorMessage = error.response.data.errors.message[0]
          } else {
            this.errorMessage = error.data.errors.message[0]
          }
          this.$refs.alert.showAlert()
        })
        setTimeout(() => {
          this.loadFlag = false
        }, 1000)
      })
    },
    startTimer() {
      const endTime = Date.now() + this.remainingTime * 1000;
      localStorage.setItem('endTime', new Date(endTime));

      this.isTimerRunning = true;
      this.timerInterval = setInterval(() => {
        if (this.remainingTime > 0) {
          this.remainingTime--;
        } else {
          this.resetTimer();
        }
      }, 1000);
    },
    resetTimer() {
      clearInterval(this.timerInterval);
      localStorage.removeItem('endTime');
      this.isTimerRunning = false;
      this.remainingTime = 60;
    },
  },
  created() {
    const savedEndTime = localStorage.getItem('endTime');

    if (savedEndTime) {
      const timeDifference = Math.floor((new Date(savedEndTime) - Date.now()) / 1000);

      if (timeDifference > 0) {
        this.remainingTime = timeDifference;
        this.startTimer();
      }
    }
  },
  mounted() {
    this.page = this.$route.query.page || 1
    this.caseTitle = localStorage.getItem('title')
    this.$store.dispatch('profile/getPermissionPerCase', {caseId: this.$route.params.slug})
    this.getCaseExport()
  }
}
</script>

<style scoped>
.case-export-section {
  padding: 24px 55px 24px 35px;
  min-height: 100vh;
}

.case-action-btn-wrap {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.timer-wrapper {
  padding: 20px 0 0 20px;
}

.timer span {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: var(--secondary-text-color);
}

.format-choose-wrapper {
  display: flex;
  flex-direction: column;
  width: 195px;
  margin-right: 16px;
}

.format-choose-text {
  font-size: 14px;
  font-weight: 700;
  color: var(--secondary-black-color);
  margin-bottom: 9px;
}

.btn {
  align-self: flex-end;
}

.dialog-message-title {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  color: #292B2F;
  text-align: center;
  margin: 25px 0 0;
  display: block;
  width: 100%;
}

.dialog-message-description {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #696F79;
  display: block;
  width: 100%;
  margin-top: 20px;
}

.dialog-create-description {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: center;
  color: var(--light-gray);
  margin: 10px 0 0;
}

.dialog-info-wrapper {
  display: flex;
  flex-direction: column;
  width: 406px;
  margin: 25px 20px 50px;
  border-radius: 6px;
  background-color: var(--hover-accent-color);
}

.dialog-info-text {
  display: block;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: center;
  color: var(--accent-color);
  margin: 20px 15px;
}

.progress-bar {
  width: 345px;
  background-color: #EBEFF6;
  border-radius: 5px;
  overflow: hidden;
  margin: 0 30px 30px;
}

.progress {
  height: 5px;
  background-color: var(--accent-color);
  width: 0;
  transition: width 0.3s;
}

.loader-p {
  width: 50px;
  height: 50px;
  position: relative;
  margin: 20px 40px 20px 0;
}
</style>
