<template>
  <tbody>
  <tr v-for="(item, i) in processingList" :key="item + i">
    <td class="holder-checkbox">
      <CustomCheckbox
          :id="`checkbox_${i+1}`"
          :labelText="`checkbox_${i+1}`"
          :input-element="item"
      />
    </td>
    <td class="w-200">
      <div class="holder-info">
        <div class="info-name">
          <span class="info-mail">{{ item.email }}</span>
        </div>
      </div>
    </td>
    <td class="roles">
      <span class="span-cases" v-if="!item.roles.length">-</span>
      <span class="span-cases" v-else-if="item.roles.length > 2">{{ item.roles.length }} roles</span>
      <span v-else-if="item.roles.length === 1"
            v-text="getRoleNameFE(item.roles[0])"
            class="span-cases"
            :class="`span-cases-` + item.roles[0].toLowerCase()"
      ></span>
      <div
          v-else
          class="span-cases-wrap"
      >
          <span
              v-text="getRoleNameFE(item.roles[0]) + ','"
              class="span-cases"
              :class="`span-cases-` + item.roles[0].toLowerCase()"
          ></span>
        <span
            v-text="getRoleNameFE(item.roles[1])"
            class="span-cases"
            :class="`span-cases-` + item.roles[1].toLowerCase()"
        ></span>
      </div>
    </td>
    <td>
      <span class="span-cases">{{ item.cases.length + " " + (item.cases.length === 1 ? "case" : "cases") }}</span>
    </td>
    <td>
      <span class="span-cases">{{ item.requestDate | moment("D MMM, YYYY") }}</span>
    </td>
    <td class="status">
      <div class="holder-info">
            <span
                class="status-text"
                :class="`status-text-${item.status}`"
            >{{ item.status }}</span>
      </div>

    </td>
    <td class="w-300">
      <div class="holder-btns">
        <button
            class="btn-disabled btn-resend"
            :id="`resend_processing_${i}`"
            @click="resendUser(item)"
            :disabled="item.status !== 'expired'"
        >
          <span>Resend</span>
          <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.76729 7.06238L14.7073 2.79488C14.8469 2.72941 15.0033 2.70855 15.1552 2.73516C15.3071 2.76177 15.4471 2.83454 15.5561 2.94358C15.6651 3.05261 15.7379 3.19261 15.7645 3.34449C15.7911 3.49638 15.7703 3.65278 15.7048 3.79238L11.4373 15.7324C11.3995 15.8981 11.3066 16.046 11.1738 16.152C11.0409 16.258 10.876 16.3157 10.706 16.3157C10.5361 16.3157 10.3712 16.258 10.2383 16.152C10.1055 16.046 10.0126 15.8981 9.97479 15.7324L8.09229 10.8424C8.05487 10.739 7.99515 10.6451 7.91739 10.5673C7.83962 10.4895 7.74571 10.4298 7.64229 10.3924L2.75229 8.52488C2.58695 8.48546 2.43991 8.39103 2.33526 8.25708C2.23061 8.12314 2.17457 7.95762 2.17631 7.78765C2.17805 7.61768 2.23748 7.45334 2.34485 7.32157C2.45223 7.1898 2.60118 7.09841 2.76729 7.06238Z"/>
            <path
                d="M7.96479 10.5349L10.2148 8.28488M2.76729 7.06238L14.7073 2.79488C14.8469 2.72941 15.0033 2.70855 15.1552 2.73516C15.3071 2.76177 15.4471 2.83454 15.5561 2.94358C15.6651 3.05261 15.7379 3.19261 15.7645 3.34449C15.7911 3.49638 15.7703 3.65278 15.7048 3.79238L11.4373 15.7324C11.3995 15.8981 11.3066 16.046 11.1738 16.152C11.0409 16.258 10.876 16.3157 10.706 16.3157C10.5361 16.3157 10.3712 16.258 10.2383 16.152C10.1055 16.046 10.0126 15.8981 9.97479 15.7324L8.09229 10.8424C8.05487 10.739 7.99515 10.6451 7.91739 10.5673C7.83962 10.4895 7.74571 10.4298 7.64229 10.3924L2.75229 8.52488C2.58695 8.48546 2.43991 8.39103 2.33526 8.25709C2.23061 8.12314 2.17457 7.95762 2.17631 7.78765C2.17805 7.61768 2.23748 7.45334 2.34485 7.32157C2.45223 7.1898 2.60118 7.09841 2.76729 7.06238V7.06238Z"
                stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
        <button class="btn btn-deactivate" @click.stop="confirmDeactivateUser(item)">
                  <span
                      class="btn-span"
                      :id="`deactivate_processing_${i}`"
                  >Deactivate</span>
          <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10 0.499969C4.47 0.499969 0 4.96997 0 10.5C0 16.03 4.47 20.5 10 20.5C15.53 20.5 20 16.03 20 10.5C20 4.96997 15.53 0.499969 10 0.499969ZM15 14.09L13.59 15.5L10 11.91L6.41 15.5L5 14.09L8.59 10.5L5 6.90997L6.41 5.49997L10 9.08997L13.59 5.49997L15 6.90997L11.41 10.5L15 14.09Z"
                fill="#D7DCE6"/>
          </svg>
        </button>
      </div>
    </td>
  </tr>
  </tbody>
</template>

<script>
import '@/assets/css/table.css';

import CustomCheckbox from "../inputs/CustomCheckbox";
import getRoleName from "@/mixins/getRoleName";

export default {
  name: "ProcessingRequestsItem",
  components: {
    CustomCheckbox,
  },
  data() {
    return {
      el: {
        isActive: false
      },
    }
  },
  props: {
    processingList: {
      type: Array
    },
  },
  mixins: [getRoleName],
  methods: {
    deactivateUser(item) {
      this.$emit('deactivateUser', item)
    },
    confirmDeactivateUser(item) {
      this.$emit('confirmDeactivateUser', item)
    },
    resendUser(item) {
      this.$emit('resendUser', item)
    },
  },
}
</script>

<style scoped>

.processing-table tbody td {
  min-height: 67px;
}

.table-body tr:hover {
  box-shadow: 0px 4px 20px rgba(77, 106, 147, 0.15);
}

.requestdate span {
  color: var(--secondary-text-color);
  font-weight: 400;
}

.status {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.status .status-text {
  font-size: 12px;
  font-weight: 500;
  border-radius: 20px;
  border: 1px solid transparent;
  padding: 6px 12px;
  color: #FFA04D;
}

.status .status-text-pending {
  background: #FFF3E7;
}

.status .status-text-expired {
  border: 1px solid #FFC107;
}

.btn-resend {
  width: 118px;
  height: 35px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
}

.holder-btns .btn-resend span {
  color: #696F79;
}

.holder-btns .btn-resend:disabled span {
  color: #D7DCE6;
}

.holder-btns .btn-resend svg path {
  fill: var(--accent-color)
}

.holder-btns .btn-resend:disabled svg path {
  fill: var(--background-button-disabled-color)
}

.roles .span-cases {
  text-decoration: none;
}

.roles .span-cases-admin {
  //color: var(--admin-color);
}

.roles .span-cases-lawyer {
  //color: #9B7EDE;
}

.roles .span-cases-associate {
  //color: #EE6352;
}

</style>
