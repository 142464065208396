<template>
  <section class="dashboard-section">
    <div class="case-email-table">
      <div class="case-folders-bar">
        <div class="case-email-bar-sort">
          <div
            v-if="requestCompleted"
            class="holder-email-folders case-email-bar-sort-item"
          >
            <label :for="`sort_email_folder_case_${caseId}`">
              Choose the folder
            </label>
            <CustomSelectFolders
              :id="`sort_email_folder_case_${caseId}`"
              :customId="`folders-${caseId}`"
              :default="folderNameModel"
              :typeSelect="'filterFolder'"
              v-model="folderNameModel"
              :custom-class="'selectMessages'"
              :options="searchedFolderList"
              @search="searchFolderModel = $event"
            />
          </div>
          <div class="holder-datepicker case-email-bar-sort-item">
            <label :for="`sort_emails_folders_case_${caseId}`">
              Filter by date:
            </label>
            <date-picker
              ref="datePicker"
              v-model="dataRange"
              range
              id="emails-folders-date-picker"
              :format="'DD/MM/YYYY'"
              :range-separator="' - '"
              :editable="false"
              :input-class="'notifications-input'"
              :popup-class="'notifications-popup'"
              :placeholder="placeholderDate"
            >
              <template #icon-calendar>
                <svg class="datepicker-icon" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20.1452 11.4137C23.3452 13.3528 24.3653 17.5156 22.4238 20.7115C21.2063 22.7157 19.0372 23.948 16.6902 23.9688C14.5905 23.973 12.6114 22.9896 11.3483 21.3145C10.422 20.144 9.92486 18.6921 9.93972 17.2002C9.95437 13.487 12.9724 10.4846 16.6902 10.4846C17.9051 10.4744 19.0998 10.7955 20.1452 11.4137Z" fill="#082655"/>
                  <path d="M20.1445 8.22949V11.4147C19.0991 10.7966 17.9044 10.4754 16.6896 10.4857C12.9717 10.4856 9.95372 13.488 9.93907 17.2013C9.92422 18.6931 10.4213 20.1451 11.3477 21.3156H2.12549C1.24484 21.3156 0.530884 20.6025 0.530884 19.7229V8.22954H20.1445V8.22949Z" fill="white"/>
                  <path d="M20.1445 4.37973V8.22856H0.530884V4.37973C0.530884 3.50017 1.24484 2.78711 2.12549 2.78711H4.3048V4.43283H6.82962V2.78711H13.8193V4.43283H16.3441V2.78711H18.55C19.4306 2.78711 20.1445 3.50012 20.1445 4.37973Z" fill="#082655"/>
                  <path d="M16.3447 2.78757V4.43328H13.8198V2.78757V0.956055H16.3447V2.78757Z" fill="#082655"/>
                  <path d="M6.83018 2.78757V4.43328H4.30542V2.78757V0.956055H6.83018V2.78757Z" fill="#082655"/>
                  <path d="M11.3217 21.8718H2.12615C0.951924 21.8718 0 20.9211 0 19.7483V4.35299C0 3.18023 0.951924 2.22949 2.12615 2.22949H4.30545V3.29124H2.12615C1.53901 3.29124 1.06307 3.76658 1.06307 4.35299V19.7483C1.06307 20.3347 1.53901 20.81 2.12615 20.81H11.3217V21.8718Z" fill="#082655"/>
                  <path d="M20.7558 11.175V4.35327C20.7558 3.18534 19.7193 2.36248 18.5499 2.36248H16.7693V0.929186C16.8011 0.726418 16.6624 0.536301 16.4594 0.504496C16.4212 0.498501 16.3822 0.498501 16.3441 0.504496H13.8193C13.5269 0.504496 13.3143 0.637202 13.3143 0.929186V2.36253H7.46743V0.929186C7.46743 0.637202 7.12192 0.504496 6.82958 0.504496H4.30481C4.01247 0.504496 3.74672 0.637202 3.74672 0.929186V4.43293C3.75898 4.74021 3.99812 4.99038 4.30481 5.0169H6.82963C7.15306 4.99577 7.41829 4.75291 7.46748 4.43293V3.42428H13.3144V4.43293C13.2988 4.73127 13.5215 4.98886 13.8194 5.0169H16.3442C16.6365 5.0169 16.7694 4.72492 16.7694 4.43293V3.42428H18.55C19.1347 3.42428 19.6928 3.76935 19.6928 4.35332V7.67127H0.557449V8.73302H19.6928V10.5911C16.0518 8.91973 11.7437 10.5128 10.0703 14.1492C9.62624 15.1142 9.39997 16.1649 9.40755 17.227C9.43649 21.2317 12.6799 24.471 16.6896 24.4999C20.7113 24.5152 23.984 21.2714 23.9993 17.2547C24.0086 14.8128 22.7903 12.5293 20.7558 11.175ZM6.40436 3.95515H4.80975V1.56624H6.40436V3.95515ZM15.7062 3.95515H14.3774V1.56624H15.7062V3.95515ZM16.6896 23.4382C14.7517 23.4342 12.9255 22.532 11.7463 20.9962C9.662 18.2697 10.1854 14.3718 12.9154 12.2901C15.6453 10.2084 19.548 10.7312 21.6323 13.4577C23.7166 16.1843 23.1931 20.0821 20.4632 22.1638C19.3793 22.9904 18.0534 23.4381 16.6896 23.4382Z" fill="#082655"/>
                  <path d="M19.1884 18.2886H16.3447C16.0511 18.2886 15.8132 18.0509 15.8132 17.7577V14.0416C15.8132 13.7484 16.0511 13.5107 16.3447 13.5107C16.6383 13.5107 16.8762 13.7484 16.8762 14.0416V17.2269H19.1884C19.4669 17.2118 19.7049 17.4251 19.72 17.7032C19.7209 17.7214 19.7209 17.7395 19.72 17.7577C19.72 18.0509 19.482 18.2886 19.1884 18.2886Z" fill="white"/>
                </svg>
              </template>
            </date-picker>
          </div>
          <div class="holder-search case-email-bar-sort-item">
            <CustomSearch
              :id="`search_in_emails_folders_case_${caseId}`"
              :modelValue="searchText"
              @update:modelValue="searchText = $event"
            />
          </div>
        </div>
      </div>
      <div class="case-email-wrapper">
        <TableHeader
          :class="checkAll || checkSome.length >= 2 ? 'active folder-header' : 'folder-header'"
        >
          <div class="table-col check">
            <div
              v-if="!showEmptyMessage"
              class="custom-checkbox-group"
            >
              <div class="checkbox-wrap">
                <input
                  id="checkAll_in_email_integration"
                  class="input-checkbox"
                  type="checkbox"
                  :disabled="checkForAllImported"
                  v-model="checkAll"
                >
                <label
                  for="checkAll_in_email_integration"
                  class="label-checkbox checkbox-title"
                >
                </label>
              </div>
              <span
                v-if="checkSome.length >= 2"
                class="checkbox-unselect"
                @click="unselectAll"
                v-text="checkAll ? 'Unselect All' : 'Select All'"
              />
            </div>
          </div>

          <div class="table-col sender-name ellipsis-text" v-if="!checkAll && checkSome.length <= 1">
            <div class="table-header-box table-header-box-doesnt-hover">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.40896 7.51465C9.04299 7.81854 8.45799 8.04257 7.99993 8.04257C7.54187 8.04257 6.95674 7.81895 6.59076 7.51507C5.97632 7.88104 5.61965 8.53326 5.57007 9.42215H10.4298C10.3802 8.53326 10.0235 7.88076 9.40896 7.51465Z"
                  fill="#DDE3E3"/>
                <path
                  d="M8.00004 6.85858C8.43545 6.86053 8.82906 6.59969 8.99712 6.19803C9.16504 5.79622 9.0742 5.33289 8.76698 5.02428C8.45976 4.71567 7.9967 4.62275 7.5942 4.789C7.19184 4.95511 6.9292 5.34761 6.9292 5.78303C6.92851 6.37553 7.40753 6.85678 8.00004 6.85858Z"
                  fill="#DDE3E3"/>
                <path
                  d="M6.52746 7.06322C6.61093 7.02127 6.71204 7.03849 6.7769 7.10572C7.04246 7.3821 7.60273 7.61544 8.00023 7.61544C8.39773 7.61544 8.95773 7.38169 9.22329 7.1053C9.28829 7.03808 9.3894 7.02113 9.47273 7.06335C10.3027 7.47933 10.8001 8.31988 10.8572 9.4221H10.9512V2.87988H5.01343V9.4221H5.14301C5.20023 8.31988 5.69746 7.47919 6.52746 7.06322ZM8.00009 4.28072C8.60815 4.27891 9.15732 4.64363 9.39134 5.20488C9.62537 5.76599 9.49801 6.41294 9.06871 6.84349C8.63954 7.27419 7.99301 7.40349 7.43107 7.17127C6.86912 6.93905 6.50259 6.39099 6.50259 5.78294C6.50218 4.95502 7.17204 4.28294 8.00009 4.28072Z"
                  fill="#DDE3E3"/>
                <path
                  d="M6.16548 1.20867H9.83493C9.95048 1.20867 10.0483 1.13617 10.0483 1.04867C10.0483 0.961172 9.95048 0.888672 9.83493 0.888672H6.16548C6.04993 0.888672 5.95215 0.961172 5.95215 1.04867C5.95215 1.13617 6.04993 1.20867 6.16548 1.20867Z"
                  fill="#DDE3E3"/>
                <path
                  d="M12.4802 0H3.52024C3.24538 0 3.02246 0.222917 3.02246 0.497778V15.5022C3.02246 15.7771 3.24538 16 3.52024 16H12.4802C12.7551 16 12.978 15.7771 12.978 15.5022V0.497778C12.978 0.222917 12.7551 0 12.4802 0ZM6.16552 0.462222H9.83496C10.1877 0.462222 10.475 0.724583 10.475 1.04889C10.475 1.37319 10.1877 1.63556 9.83496 1.63556H6.16552C5.81274 1.63556 5.52552 1.37319 5.52552 1.04889C5.52552 0.724583 5.81274 0.462222 6.16552 0.462222ZM4.58691 2.80889C4.58691 2.6125 4.74607 2.45333 4.94246 2.45333H11.0225C11.2189 2.45333 11.378 2.6125 11.378 2.80889V9.49333C11.378 9.68972 11.2189 9.84889 11.0225 9.84889H4.94246C4.74607 9.84889 4.58691 9.68972 4.58691 9.49333V2.80889ZM4.90691 11.52C4.78913 11.52 4.69357 11.4244 4.69357 11.3067C4.69357 11.1889 4.78913 11.0933 4.90691 11.0933H11.058C11.1758 11.0933 11.2714 11.1889 11.2714 11.3067C11.2714 11.4244 11.1758 11.52 11.058 11.52H4.90691ZM10.738 12.7644C10.738 12.8822 10.6425 12.9778 10.5247 12.9778H5.44024C5.32246 12.9778 5.22691 12.8822 5.22691 12.7644C5.22691 12.6467 5.32246 12.5511 5.44024 12.5511H10.5247C10.6425 12.5511 10.738 12.6467 10.738 12.7644ZM11.5914 14.4H4.37357C4.25579 14.4 4.16024 14.3044 4.16024 14.1867C4.16024 14.0689 4.25579 13.9733 4.37357 13.9733H11.5914C11.7091 13.9733 11.8047 14.0689 11.8047 14.1867C11.8047 14.3044 11.7091 14.4 11.5914 14.4Z"
                  fill="#D7DCE6"/>
              </svg>
              <span class="header-table-text">Sender Name</span>
            </div>
          </div>

          <div class="table-col folder-name ellipsis-text" v-if="!checkAll && checkSome.length <= 1">
            <div class="table-header-box table-header-box-doesnt-hover">
              <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_9847_26107)">
                  <path
                    d="M4.78125 9.5625C4.64035 9.5625 4.50523 9.50653 4.4056 9.4069C4.30597 9.30727 4.25 9.17215 4.25 9.03125C4.25 8.89035 4.30597 8.75523 4.4056 8.6556C4.50523 8.55597 4.64035 8.5 4.78125 8.5H12.2188C12.3596 8.5 12.4948 8.55597 12.5944 8.6556C12.694 8.75523 12.75 8.89035 12.75 9.03125C12.75 9.17215 12.694 9.30727 12.5944 9.4069C12.4948 9.50653 12.3596 9.5625 12.2188 9.5625H4.78125Z"
                    fill="#D7DCE6"/>
                  <path
                    d="M4.25 11.1562C4.25 11.0154 4.30597 10.8802 4.4056 10.7806C4.50523 10.681 4.64035 10.625 4.78125 10.625H12.2188C12.3596 10.625 12.4948 10.681 12.5944 10.7806C12.694 10.8802 12.75 11.0154 12.75 11.1562C12.75 11.2971 12.694 11.4323 12.5944 11.5319C12.4948 11.6315 12.3596 11.6875 12.2188 11.6875H4.78125C4.64035 11.6875 4.50523 11.6315 4.4056 11.5319C4.30597 11.4323 4.25 11.2971 4.25 11.1562Z"
                    fill="#D7DCE6"/>
                  <path
                    d="M4.78125 13.8125C4.64035 13.8125 4.50523 13.7565 4.4056 13.6569C4.30597 13.5573 4.25 13.4221 4.25 13.2812C4.25 13.1404 4.30597 13.0052 4.4056 12.9056C4.50523 12.806 4.64035 12.75 4.78125 12.75H9.03125C9.17215 12.75 9.30727 12.806 9.4069 12.9056C9.50653 13.0052 9.5625 13.1404 9.5625 13.2812C9.5625 13.4221 9.50653 13.5573 9.4069 13.6569C9.30727 13.7565 9.17215 13.8125 9.03125 13.8125H4.78125Z"
                    fill="#D7DCE6"/>
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M9.87381 0H4.25C3.68641 0 3.14591 0.223883 2.7474 0.622398C2.34888 1.02091 2.125 1.56141 2.125 2.125V14.875C2.125 15.4386 2.34888 15.9791 2.7474 16.3776C3.14591 16.7761 3.68641 17 4.25 17H12.75C13.3136 17 13.8541 16.7761 14.2526 16.3776C14.6511 15.9791 14.875 15.4386 14.875 14.875V5.00119C14.8749 4.71942 14.763 4.44921 14.5637 4.25L10.625 0.311313C10.4258 0.112041 10.1556 6.01772e-05 9.87381 0ZM10.0938 1.59375V3.71875C10.0938 4.00054 10.2057 4.27079 10.4049 4.47005C10.6042 4.66931 10.8745 4.78125 11.1562 4.78125H13.2812L10.0938 1.59375ZM4.4056 9.4069C4.50523 9.50653 4.64035 9.5625 4.78125 9.5625H12.2188C12.3596 9.5625 12.4948 9.50653 12.5944 9.4069C12.694 9.30727 12.75 9.17215 12.75 9.03125C12.75 8.89035 12.694 8.75523 12.5944 8.6556C12.4948 8.55597 12.3596 8.5 12.2188 8.5H4.78125C4.64035 8.5 4.50523 8.55597 4.4056 8.6556C4.30597 8.75523 4.25 8.89035 4.25 9.03125C4.25 9.17215 4.30597 9.30727 4.4056 9.4069ZM4.4056 10.7806C4.30597 10.8802 4.25 11.0154 4.25 11.1562C4.25 11.2971 4.30597 11.4323 4.4056 11.5319C4.50523 11.6315 4.64035 11.6875 4.78125 11.6875H12.2188C12.3596 11.6875 12.4948 11.6315 12.5944 11.5319C12.694 11.4323 12.75 11.2971 12.75 11.1562C12.75 11.0154 12.694 10.8802 12.5944 10.7806C12.4948 10.681 12.3596 10.625 12.2188 10.625H4.78125C4.64035 10.625 4.50523 10.681 4.4056 10.7806ZM4.4056 13.6569C4.50523 13.7565 4.64035 13.8125 4.78125 13.8125H9.03125C9.17215 13.8125 9.30727 13.7565 9.4069 13.6569C9.50653 13.5573 9.5625 13.4221 9.5625 13.2812C9.5625 13.1404 9.50653 13.0052 9.4069 12.9056C9.30727 12.806 9.17215 12.75 9.03125 12.75H4.78125C4.64035 12.75 4.50523 12.806 4.4056 12.9056C4.30597 13.0052 4.25 13.1404 4.25 13.2812C4.25 13.4221 4.30597 13.5573 4.4056 13.6569Z"
                        fill="#D7DCE6"/>
                </g>
                <defs>
                  <clipPath id="clip0_9847_26107">
                    <rect width="17" height="17" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
              <span class="header-table-text">Folder Name</span>
            </div>
          </div>

          <div class="table-col subject ellipsis-text" v-if="!checkAll && checkSome.length <= 1">
            <div class="table-header-box table-header-box-doesnt-hover">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M14.3888 3.35802C14.8384 3.35802 15.2194 3.52263 15.5316 3.85185C15.8439 4.18107 16 4.58272 16 5.05679V14.3012C16 14.7753 15.8439 15.177 15.5316 15.5062C15.2194 15.8354 14.8384 16 14.3888 16H1.61124C1.16159 16 0.78064 15.8354 0.468384 15.5062C0.156128 15.177 0 14.7753 0 14.3012V5.05679C0 4.58272 0.156128 4.18107 0.468384 3.85185C0.78064 3.52263 1.16159 3.35802 1.61124 3.35802H4.79625V1.65926C4.79625 1.21152 4.95238 0.823045 5.26464 0.493827C5.57689 0.164609 5.95785 0 6.40749 0H9.59251C10.0422 0 10.4231 0.164609 10.7354 0.493827C11.0476 0.823045 11.2037 1.21152 11.2037 1.65926V3.35802H14.3888ZM9.59251 3.35802V1.65926H6.40749V3.35802H9.59251Z"
                  fill="#D7DCE6"/>
              </svg>
              <span class="header-table-text">Subject</span>
            </div>
          </div>

          <div class="table-col attachment" v-if="!checkAll && checkSome.length <= 1" />

          <div class="table-col date" v-if="!checkAll && checkSome.length <= 1">
            <div class="table-header-box table-header-box-doesnt-hover">
              <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_9847_26118)">
                  <path
                    d="M3.0625 0C3.17853 0 3.28981 0.049386 3.37186 0.137294C3.45391 0.225201 3.5 0.34443 3.5 0.46875V0.9375H10.5V0.46875C10.5 0.34443 10.5461 0.225201 10.6281 0.137294C10.7102 0.049386 10.8215 0 10.9375 0C11.0535 0 11.1648 0.049386 11.2469 0.137294C11.3289 0.225201 11.375 0.34443 11.375 0.46875V0.9375H12.25C12.7141 0.9375 13.1592 1.13504 13.4874 1.48667C13.8156 1.83831 14 2.31522 14 2.8125V13.125C14 13.6223 13.8156 14.0992 13.4874 14.4508C13.1592 14.8025 12.7141 15 12.25 15H1.75C1.28587 15 0.840752 14.8025 0.512563 14.4508C0.184374 14.0992 0 13.6223 0 13.125V4.6875H14V3.75H0V2.8125C0 2.31522 0.184374 1.83831 0.512563 1.48667C0.840752 1.13504 1.28587 0.9375 1.75 0.9375H2.625V0.46875C2.625 0.34443 2.67109 0.225201 2.75314 0.137294C2.83519 0.049386 2.94647 0 3.0625 0V0Z"
                    fill="#D7DCE6"/>
                </g>
                <defs>
                  <clipPath id="clip0_9847_26118">
                    <rect width="14" height="15" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
              <span class="header-table-text">Date</span>
            </div>
          </div>

          <div class="table-col import" v-if="!checkAll && checkSome.length <= 1"/>

          <button
            v-if="checkAll || checkSome.length >= 2"
            id="import_several_emails_case"
            class="import-btn"
            data-title="Import all"
            @click.stop="importEmailFromCaseSeveral"
          >
            <span class="btn-span">Import all</span>
            <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_10238_7298)">
                <path
                  d="M9.17501 13.0891C8.1676 11.3084 8.47937 9.07335 9.93541 7.63616C11.3916 6.19897 13.6305 5.91661 15.3979 6.94718C17.1653 7.97774 18.0222 10.0655 17.4886 12.0405C16.9549 14.0157 15.1633 15.3878 13.1172 15.388C11.4838 15.3924 9.97577 14.513 9.17501 13.0891Z"
                  fill="white"/>
                <path
                  d="M10.9562 6.05705C11.4615 6.05636 11.9606 6.16855 12.4169 6.38546C10.9493 6.61581 9.68782 7.5511 9.04099 8.88844C8.39417 10.2258 8.44409 11.7953 9.17468 13.0888H2.61365C2.01259 13.0885 1.43636 12.8486 1.0124 12.4224C0.58858 11.9961 0.351984 11.4185 0.355035 10.8174C0.354896 10.8143 0.355035 10.8114 0.355312 10.8085L0.358918 10.6891C0.361414 9.2633 1.33387 8.04842 2.66913 7.74137V7.74027C2.66954 5.81144 4.02005 4.14625 5.90727 3.74767C7.79449 3.34909 9.70307 4.32613 10.4833 6.09006C10.64 6.06801 10.798 6.05705 10.9562 6.05705Z"
                  fill="#082655"/>
                <path
                  d="M13.1169 5.97672C12.901 5.977 12.6853 5.99156 12.4712 6.02013C11.9941 5.80919 11.478 5.70088 10.9562 5.70227C10.8694 5.70227 10.7823 5.70532 10.6955 5.71128C9.93427 4.23401 8.41179 3.30538 6.74979 3.30469C4.39535 3.30469 2.46334 5.14878 2.32229 7.46925C1.69544 7.67311 1.1432 8.05838 0.735328 8.57609C0.263801 9.1777 0.00654113 9.91939 0.00390612 10.6837L0.000855062 10.7902C0.00016164 10.8006 2.29552e-05 10.8092 2.29552e-05 10.8157C-0.00288942 11.5097 0.271428 12.1762 0.762233 12.667C1.25304 13.1578 1.91969 13.4323 2.61367 13.4292H8.9736C9.86312 14.8849 11.4314 15.7355 13.1169 15.7355C15.8095 15.7355 18 13.5486 18 10.8561C18 8.1635 15.8095 5.97672 13.1169 5.97672ZM1.26413 12.1648C0.907019 11.8092 0.707452 11.3254 0.710087 10.8214C0.710087 10.8195 0.710226 10.8195 0.710226 10.8175L0.713832 10.6991C0.713832 10.6957 0.713832 10.693 0.713832 10.6896C0.714664 10.0826 0.91853 9.49335 1.29284 9.01558C1.65869 8.55002 2.17196 8.22258 2.74847 8.0875C2.9099 8.04992 3.02404 7.9061 3.0239 7.74052C3.02431 5.97936 4.2575 4.45896 5.98065 4.09519C7.70367 3.73142 9.44624 4.62344 10.1585 6.23398C10.2229 6.37905 10.3756 6.46392 10.5327 6.44187C10.673 6.42218 10.8145 6.41233 10.9561 6.41233C11.002 6.41233 11.0476 6.41358 11.093 6.41566C9.35401 7.20783 8.23677 8.94139 8.23386 10.8523C8.23178 11.4935 8.35895 12.1284 8.60761 12.7192H2.61367C2.10761 12.7232 1.62124 12.5235 1.26413 12.1648ZM13.1169 15.0328C10.8123 15.0328 8.94392 13.1646 8.94392 10.8598C8.94392 8.55515 10.8123 6.68679 13.1169 6.68679C15.4217 6.68679 17.2901 8.55515 17.2901 10.8598C17.2838 13.162 15.4191 15.0267 13.1169 15.0328Z"
                  fill="#082655"/>
                <path
                  d="M14.199 10.9328L13.4379 11.6622V9.2188C13.4379 9.0227 13.279 8.86377 13.0829 8.86377C12.8868 8.86377 12.7278 9.0227 12.7278 9.2188V11.6622L11.9843 10.9328C11.9176 10.8673 11.8273 10.8313 11.7339 10.8328C11.6404 10.8343 11.5514 10.8732 11.4867 10.9407C11.3508 11.0822 11.3554 11.3068 11.4967 11.4428L12.8524 12.7557C12.9904 12.8891 13.2093 12.8891 13.3473 12.7557L14.702 11.4428C14.8415 11.305 14.8432 11.0804 14.7055 10.9407C14.5673 10.7999 14.3416 10.7963 14.199 10.9328Z"
                  fill="#082655"/>
              </g>
              <defs>
                <clipPath id="clip0_10238_7298">
                  <rect width="18" height="18" fill="white" transform="translate(0 0.5)"/>
                </clipPath>
              </defs>
            </svg>
          </button>

        </TableHeader>

        <div
          v-if="!loadFlag && !showEmptyMessage"
          class="table-body folder-list"
        >
          <div
            class="table-row"
            v-for="(item, index) in casesEmailMessages"
            :key="item.id"
            :tabindex="index + 1"
          >
            <router-link
              class="card"
              :to="{ name: 'E-mail integration specific message', params: {messageId: item.id} }"
            >
              <div class="table-col check" @click.stop>
                <CustomCheckbox
                  :disable-checkbox="item.imported"
                  :item="item"
                  :id="item.id"
                  :labelText="item.sender"
                  :input-element="item"
                />
              </div>
              <div class="table-col sender-name ellipsis-text">
                {{ item.sender }}
              </div>
              <div class="table-col folder-name ellipsis-text">
                {{ item.folder }}
              </div>
              <div class="table-col subject ellipsis-text">
                <span class="email-topic">{{ item.subject }}</span>
                <span> - </span>
                <span class="email-content">{{ item.content }}</span>
              </div>
              <div class="table-col attachment">
                <div v-if="item.attachments > 0" class="email-attachments">
                  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.6136 5.98566L6.63566 10.9636C6.02582 11.5734 5.19871 11.916 4.33628 11.916C3.47385 11.916 2.64674 11.5734 2.0369 10.9636C1.42707 10.3537 1.08447 9.52663 1.08447 8.6642C1.08447 7.80177 1.42707 6.97466 2.0369 6.36482L7.01482 1.38691C7.42138 0.980353 7.97278 0.751953 8.54774 0.751953C9.12269 0.751953 9.6741 0.980353 10.0807 1.38691C10.4872 1.79346 10.7156 2.34487 10.7156 2.91982C10.7156 3.49478 10.4872 4.04619 10.0807 4.45274L5.09732 9.43066C4.89404 9.63394 4.61834 9.74814 4.33086 9.74814C4.04339 9.74814 3.76768 9.63394 3.56441 9.43066C3.36113 9.22738 3.24693 8.95168 3.24693 8.6642C3.24693 8.37672 3.36113 8.10102 3.56441 7.89774L8.16316 3.30441" stroke="#8692A6" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  <span v-text="item.attachments" />
                </div>
              </div>
              <div class="table-col date ellipsis-text">{{ parseDate(+item.date) }} {{ parseDateTime(+item.date) }}</div>
              <div class="table-col import" @click.prevent.stop>
                <button
                  :id="`import_email_item_${item.id}`"
                  :disabled="item.imported"
                  class="import-btn"
                  data-title="Import"
                  @click.prevent.stop="importEmailRequest([item.id])"
                >
                  <span class="btn-span" v-text="item.imported ? 'Imported' : 'Import'" />
                  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_10238_7298)">
                      <path
                        d="M9.17501 13.0891C8.1676 11.3084 8.47937 9.07335 9.93541 7.63616C11.3916 6.19897 13.6305 5.91661 15.3979 6.94718C17.1653 7.97774 18.0222 10.0655 17.4886 12.0405C16.9549 14.0157 15.1633 15.3878 13.1172 15.388C11.4838 15.3924 9.97577 14.513 9.17501 13.0891Z"
                        fill="white"/>
                      <path
                        d="M10.9562 6.05705C11.4615 6.05636 11.9606 6.16855 12.4169 6.38546C10.9493 6.61581 9.68782 7.5511 9.04099 8.88844C8.39417 10.2258 8.44409 11.7953 9.17468 13.0888H2.61365C2.01259 13.0885 1.43636 12.8486 1.0124 12.4224C0.58858 11.9961 0.351984 11.4185 0.355035 10.8174C0.354896 10.8143 0.355035 10.8114 0.355312 10.8085L0.358918 10.6891C0.361414 9.2633 1.33387 8.04842 2.66913 7.74137V7.74027C2.66954 5.81144 4.02005 4.14625 5.90727 3.74767C7.79449 3.34909 9.70307 4.32613 10.4833 6.09006C10.64 6.06801 10.798 6.05705 10.9562 6.05705Z"
                        fill="#082655"/>
                      <path
                        d="M13.1169 5.97672C12.901 5.977 12.6853 5.99156 12.4712 6.02013C11.9941 5.80919 11.478 5.70088 10.9562 5.70227C10.8694 5.70227 10.7823 5.70532 10.6955 5.71128C9.93427 4.23401 8.41179 3.30538 6.74979 3.30469C4.39535 3.30469 2.46334 5.14878 2.32229 7.46925C1.69544 7.67311 1.1432 8.05838 0.735328 8.57609C0.263801 9.1777 0.00654113 9.91939 0.00390612 10.6837L0.000855062 10.7902C0.00016164 10.8006 2.29552e-05 10.8092 2.29552e-05 10.8157C-0.00288942 11.5097 0.271428 12.1762 0.762233 12.667C1.25304 13.1578 1.91969 13.4323 2.61367 13.4292H8.9736C9.86312 14.8849 11.4314 15.7355 13.1169 15.7355C15.8095 15.7355 18 13.5486 18 10.8561C18 8.1635 15.8095 5.97672 13.1169 5.97672ZM1.26413 12.1648C0.907019 11.8092 0.707452 11.3254 0.710087 10.8214C0.710087 10.8195 0.710226 10.8195 0.710226 10.8175L0.713832 10.6991C0.713832 10.6957 0.713832 10.693 0.713832 10.6896C0.714664 10.0826 0.91853 9.49335 1.29284 9.01558C1.65869 8.55002 2.17196 8.22258 2.74847 8.0875C2.9099 8.04992 3.02404 7.9061 3.0239 7.74052C3.02431 5.97936 4.2575 4.45896 5.98065 4.09519C7.70367 3.73142 9.44624 4.62344 10.1585 6.23398C10.2229 6.37905 10.3756 6.46392 10.5327 6.44187C10.673 6.42218 10.8145 6.41233 10.9561 6.41233C11.002 6.41233 11.0476 6.41358 11.093 6.41566C9.35401 7.20783 8.23677 8.94139 8.23386 10.8523C8.23178 11.4935 8.35895 12.1284 8.60761 12.7192H2.61367C2.10761 12.7232 1.62124 12.5235 1.26413 12.1648ZM13.1169 15.0328C10.8123 15.0328 8.94392 13.1646 8.94392 10.8598C8.94392 8.55515 10.8123 6.68679 13.1169 6.68679C15.4217 6.68679 17.2901 8.55515 17.2901 10.8598C17.2838 13.162 15.4191 15.0267 13.1169 15.0328Z"
                        fill="#082655"/>
                      <path
                        d="M14.199 10.9328L13.4379 11.6622V9.2188C13.4379 9.0227 13.279 8.86377 13.0829 8.86377C12.8868 8.86377 12.7278 9.0227 12.7278 9.2188V11.6622L11.9843 10.9328C11.9176 10.8673 11.8273 10.8313 11.7339 10.8328C11.6404 10.8343 11.5514 10.8732 11.4867 10.9407C11.3508 11.0822 11.3554 11.3068 11.4967 11.4428L12.8524 12.7557C12.9904 12.8891 13.2093 12.8891 13.3473 12.7557L14.702 11.4428C14.8415 11.305 14.8432 11.0804 14.7055 10.9407C14.5673 10.7999 14.3416 10.7963 14.199 10.9328Z"
                        fill="#082655"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_10238_7298">
                        <rect width="18" height="18" fill="white" transform="translate(0 0.5)"/>
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              </div>
            </router-link>
          </div>
          <pagination v-if="totalPages > 1"
                      v-model="currentPage"
                      :total-pages="totalPages"
                      :link-gen="linkGen"
          ></pagination>
        </div>

        <div v-else-if="loadFlag" class="loader-bg">
          <div class="loader-p"></div>
        </div>

        <p v-else-if="showEmptyMessage" class="no-found">
          <span>Emails are absent</span>
        </p>

      </div>
    </div>
    <SuccessPopup
      ref="successPopup"
      :text="successMessage"
    />
  </section>
</template>

<script>

import CustomSearch from "../../../inputs/CustomSearch";
import DatePicker from "vue2-datepicker";
import CustomSelectFolders from "../../../inputs/CustomSelectFolders";
import TableHeader from "../../../table/TableHeader";
import linkGenerator from "../../../../constants/linkGen";
import CustomCheckbox from "../../../inputs/CustomCheckbox";
import parseDateTime from "../../../../mixins/parseTime";
import parseDate from "../../../../mixins/parseDate";
import debounce from "@/mixins/debounce";
import SuccessPopup from "@/components/popups/SuccessPopup.vue";
import Pagination from "@/components/common/Pagination.vue";

export default {
  name: 'CaseEmailFolderList',
  data() {
    return {
      loadFlag: true,
      requestCompleted: false,
      folderList: [],
      searchedFolderList: [],
      casesEmailMessages: [],
      folderNameModel: '',
      placeholderDate: 'Search by date',
      caseId: this.$route.params.slug,
      emailId: this.$route.params.emailId,
      searchText: '',
      currentPage: +this.$route.query.page || 1,
      pageSize: 10,
      totalPages: null,
      dateFrom: '',
      dateTo: '',
      dataRange: '',
      successMessage: '',
      searchFolderModel: ''
    }
  },
  components: {
    Pagination,
    CustomSearch,
    DatePicker,
    CustomSelectFolders,
    TableHeader,
    CustomCheckbox,
    SuccessPopup
  },
  mixins: [parseDateTime, parseDate, debounce],
  computed: {
    checkAll: {
      get() {
        let allowedMessages = this.casesEmailMessages.filter(el => !el.imported);
        if (allowedMessages && allowedMessages.length) {
          for (let i = 0; i < allowedMessages.length; i++) {
            if (!allowedMessages[i].isActive) return false;
          }
          return true;
        }
        return false
      },
      set(value) {
        this.casesEmailMessages.forEach((el) => {
          if (!el.imported) {
            el.isActive = value;
          }
        });
      }
    },
    checkSome() {
      return this.casesEmailMessages.filter(i => i.isActive)
    },
    showEmptyMessage() {
      return !this.loadFlag && this.requestCompleted && (!this.folderList.length || !this.casesEmailMessages.length);
    },
    checkForAllImported() {
      const importedMails = [];
      this.casesEmailMessages.forEach(el => {
        if (el.imported) {
          importedMails.push(el);
        }
      });
      return this.casesEmailMessages.length && this.casesEmailMessages.length === importedMails.length;
    }
  },
  watch: {
    searchText(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.debounce(this.getCaseEmailMessages, 500);
      }
    },
    searchFolderModel(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.debounce(this.searchInFolderList, 300);
      }
    },
    '$route.path'() {
      this.dataRange = '';
    },
    dataRange(value) {
      this.dateFrom = isNaN(Date.parse(value[0])) === false ? Date.parse(value[0]) + 10800000 : '';
      this.dateTo = isNaN(Date.parse(value[1])) === false ? Date.parse(value[1]) + 97200000 : '';
      this.currentPage = 1;
      this.getCaseEmailMessages();
    },
    folderNameModel(newVal, oldVal) {
      if (newVal !== oldVal && oldVal !== '') {
        this.currentPage = 1;
        this.getCaseEmailMessages();
        this.getCaseEmailFolderList(true);
      }
    },
    currentPage(newVal, oldVal) {
      if (newVal !== oldVal && +this.$router.currentRoute.query.page !== newVal) {
        this.$router.push(this.linkGen(newVal));
      }
    },
    '$route.query.page': {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.currentPage = +newVal;
          this.getCaseEmailMessages();
        } else {
          this.currentPage = 1;
        }
      }
    }
  },
  methods: {
    linkGen(pageNum) {
      return linkGenerator(pageNum, `/cases/${this.caseId}/emails/${this.emailId}/list`);
    },
    unselectAll() {
      this.casesEmailMessages.forEach((item) => {
        if (!item.imported) {
          item.isActive = false;
        }
      })
    },
    showErrorMessage(errorText) {
      this.$emit('error-show', errorText);
    },
    importEmailFromCaseSeveral() {
      const activeItems = this.casesEmailMessages.filter(el => el.isActive).map(i => i.id);
      this.importEmailRequest(activeItems);
    },
    importEmailRequest(idList = []) {
      const body = {
        folderName: this.folderNameModel,
        messageIds: idList
      };

      if (idList.length) {
        this.$load(async () => {
          await this.$api.cases.importEmailFromCase(this.caseId, this.emailId, body).then(() => {
            // this.getCaseEmailMessages();
            idList.forEach(id => {
              this.casesEmailMessages.forEach(message => {
                if (id === message.id) {
                  message.imported = true;
                  message.isActive = false;
                }
              });
            });
            this.successMessage = idList.length > 1
              ? `${idList.length} letters have been successfully imported!`
              : `The letter was successfully imported!`;
            this.$refs.successPopup.openPopup();
          });
        }, (error) => {
          if (error) {
            if (error.response) {
              this.showErrorMessage(error.response.data.errors.message[0]);
            } else {
              this.showErrorMessage(error.data.errors.message[0]);
            }
          }
        });
      }
    },
    searchInFolderList() {
      this.searchedFolderList = [];

      this.$load(async () => {
        await this.$api.cases.getCaseEmailFolders(this.caseId, this.emailId, this.searchFolderModel).then((res) => {
          this.searchedFolderList = res.data.body.foldersNames.map(i => {
            return {
              name: i.folderName,
              count: i.messageCount
            };
          });
        })
      }, (error) => {
        if (error) {
          if (error.response) {
            this.showErrorMessage(error.response.data.errors.message[0]);
          } else {
            this.showErrorMessage(error.data.errors.message[0]);
          }
        }
      });
    },
    getCaseEmailFolderList(updateFolders = false) {
      this.loadFlag = true;
      this.folderList = [];

      return this.$load(async () => {
        await this.$api.cases.getCaseEmailFolders(this.caseId, this.emailId, this.searchFolderModel).then((res) => {
          this.folderList = res.data.body.foldersNames.map(i => {
            return {
              name: i.folderName,
              count: i.messageCount
            };
          });
          this.searchedFolderList = this.folderList;
          if (!updateFolders) this.folderNameModel = this.folderList[0].name;
        })
      }, (error) => {
        this.loadFlag = false;

        if (error) {
          if (error.response) {
            this.showErrorMessage(error.response.data.errors.message[0]);
          } else {
            this.showErrorMessage(error.data.errors.message[0]);
          }
        }
      })
    },
    getCaseEmailMessages() {
      this.loadFlag = true;
      this.casesEmailMessages = [];

      this.$load(async () => {
        const params = {
          folderName: this.folderNameModel,
          keySearch: this.searchText,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo
        }
        await this.$api.cases.getCaseEmailMessages(this.caseId, this.emailId, this.currentPage, this.pageSize, params).then((res) => {
          this.casesEmailMessages = res.data.body.content.map(i => {
            return {
              isActive: false,
              ...i
            }
          });

          this.totalPages = res.data.body.totalPages;

          this.loadFlag = false;
          this.requestCompleted = true;
        })
      }, (error) => {
        this.loadFlag = false;

        if (error) {
          if (error.response) {
            this.showErrorMessage(error.response.data.errors.message[0]);
          } else {
            this.showErrorMessage(error.data.errors.message[0]);
          }
        }
      })
    },
    getCaseEmails() {
      this.$load(async () => {
        await this.$api.cases.getCaseEmail(this.caseId, '').then((res) => {
          const result = res.data.body.caseEmails.find(email => +email.emailId === +this.emailId).email;
          this.$emit('title-show', result);
        });
      });
    },
  },
  mounted() {
    this.currentPage = +this.$route.query.page || 1;

    this.getCaseEmails();
    // this.getCaseEmailFolderList();
    this.getCaseEmailFolderList().then(() => {
      this.getCaseEmailMessages();
    });
  }
}
</script>

<style scoped>

.case-email-wrapper {
  position: relative;
}

.loader-bg {
  position: static;
  transform: none;
  height: 300px;
}

.case-email-bar-sort {
  display: flex;
  align-items: self-end;
  justify-content: flex-start;
}

.case-email-bar-sort-item:not(:last-child) {
  margin-right: 24px;
}

.case-email-bar-sort-item label {
  margin-bottom: 10px;
}

.holder-search.case-email-bar-sort-item {
  width: 310px;
}

.holder-email-folders.case-email-bar-sort-item {
  width: 195px;
}

.holder-datepicker.case-email-bar-sort-item {
  width: 222px;
}

.mx-datepicker-range {
  width: 100%;
}

th {
  padding: 20px 15px;
  text-align: left;
}

.case-folders-bar {
  margin-bottom: 30px;
}

.table-header-box {
  display: flex;
  align-items: center;
  cursor: initial;
}

.table-header-box svg {
  min-width: 16px;
}

.table-header {
  max-height: 56px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 20px;
  border-radius: 5px;
}

.table-header:not(.active) svg {
  margin-right: 10px;
}

.table-header:not(.active) span {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 10px;
  color: var(--secondary-black-color);
  margin-right: 10px;
}

.card {
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
  cursor: pointer;
  transition: .2s ease;
}

.card:hover {
  background-color: var(--case-card-hover-bg);
}

.card:hover .table-col {
  color: var(--accent-color);
}

.table-row:not(:last-child) .card {
  border-bottom: 1px solid var(--case-btn-border-color);
}

.email-content {

}

.email-topic {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: var(--secondary-black-color);
}

.folder-header .table-col,
.folder-list .table-col {
  padding-right: 35px;
  transition: .2s ease;
}

.folder-header .check,
.folder-list .check {
  min-width: 44px;
  width: 5%;
}

.folder-list .sender-name {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: var(--secondary-black-color);
}

.folder-header .sender-name,
.folder-list .sender-name {
  width: 15%;
}

.folder-list .folder-name {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #696F79;
}

.folder-header .folder-name,
.folder-list .folder-name {
  width: 10%;
}

.folder-list .subject {
  display: inline-block;
}

.folder-header .subject,
.folder-list .subject {
  width: 35%;
}

.folder-header .attachment,
.folder-list .attachment {
  width: 10%;
  display: flex;
  justify-content: flex-end;
}

.folder-list .attachment {

}

.folder-list .date {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #696F79;
}

.folder-header .date,
.folder-list .date {
  width: 15%;
}

.folder-header .import,
.folder-list .import {
  width: 10%;
}

.import-btn {
  min-width: 105px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border: 1px solid var(--case-btn-border-color);
  background-color: #FFFFFF;
  border-radius: 79px;
  cursor: pointer;
  transition: .2s all;
}

.import-btn .btn-span {
  text-transform: unset;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  color: var(--secondary-text-color);
  transition: .2s all;
}

.import-btn svg path {
  transition: .2s all;
}

.import-btn:not(:disabled):hover {
  border-color: var(--accent-color);
}

.import-btn:not(:disabled):hover .btn-span {
  color: var(--accent-color);
}

.import-btn:disabled {
  pointer-events: auto;
  cursor: not-allowed;
  background-color: var(--disabled-bgcolor);
  color: var(--disabled-bgcolor);
}

.import-btn:disabled svg path:nth-child(2) {
  fill: var(--disabled-bgcolor);
}

.ellipsis-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.custom-checkbox-group .label-checkbox {
  margin-bottom: 0;
}

.table-header.active {
  background-color: var(--accent-color);
  justify-content: space-between;
  padding-right: 30px;
}

.folder-header.active .check {
  width: auto;
}

.folder-header.active .import-btn {
  width: 155px;
}

.folder-list .table-col:last-child {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
}

.custom-checkbox-group {
  display: flex;
  align-items: center;
}

.checkbox-wrap {
  width: auto;
  margin-right: 12px;
}

span.checkbox-unselect {
  text-transform: capitalize;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  margin: 0;
}

.email-attachments {
  display: flex;
  align-items: center;
  padding: 9px 17px;
  background-color: #FFFFFF;
  border: 0.5px solid #D7DCE6;
  border-radius: 79px;
  width: fit-content;
  max-width: inherit;
}

.email-attachments svg {
  margin-right: 5px;
}

.email-attachments > span {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: var(--light-gray);
}
</style>