<template>
  <div class="wrapper">
    <div v-if="users.length === 0" class="empty-contacts">
      <p class="no-found">No custodians found</p>
    </div>
    <div class="holder-table" v-if="users.length > 0">
      <AlertErrorMsg
          :title="errorMessage"
          class="page-alert-error"
          ref="alert"
      />
      <TableHeader
          :class="checkAll || checkSome.length >= 2 ? 'active ' + contactsView : contactsView"
      >
        <div class="check">
          <div class="custom-checkbox-group">
            <div class="checkbox-wrap">
              <input
                  id="checkAll_clients_in_contacts"
                  class="input-checkbox"
                  type="checkbox"
                  v-model="checkAll">
              <label
                  for="checkAll_clients_in_contacts"
                  class="label-checkbox checkbox-title"
              >
              </label>
              <span class="checkbox-unselect" @click="unselectAll">{{ checkAll || checkSome.length >= 2 ? 'Unselect All' : '' }}</span>
            </div>
          </div>
        </div>
        <div v-if="!checkAll && checkSome.length <= 1"
             id="sort_clients_in_contacts_by_mail"
             @click="sortedBy('emailUser')"
             class="mail"
             :class="{
               'sort-active': this.sortBy === 'emailUser',
               'sorted-desc': this.order === 'desc',
             }"
        >
          <div class="table-header-box">
            <EmailIcon />
            <span class="header-table-text">Email</span>
            <arrowDown class="arrow" />
          </div>
        </div>

        <div v-if="!checkAll && checkSome.length <= 1"
             class="device">
          <div class="table-header-box table-header-box-doesnt-hover">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M13.4737 3.2H6.73684C6.5135 3.2 6.29931 3.28429 6.14138 3.43431C5.98346 3.58434 5.89474 3.78783 5.89474 4V14.4H0.842105C0.618765 14.4 0.404572 14.3157 0.246647 14.1657C0.0887215 14.0157 0 13.8122 0 13.6V0.8C0 0.587827 0.0887215 0.384344 0.246647 0.234315C0.404572 0.0842854 0.618765 0 0.842105 0H12.6316C12.8549 0 13.0691 0.0842854 13.227 0.234315C13.385 0.384344 13.4737 0.587827 13.4737 0.8V3.2ZM8.42105 4.8H15.1579C15.3812 4.8 15.5954 4.88429 15.7534 5.03431C15.9113 5.18434 16 5.38783 16 5.6V15.2C16 15.4122 15.9113 15.6157 15.7534 15.7657C15.5954 15.9157 15.3812 16 15.1579 16H8.42105C8.19771 16 7.98352 15.9157 7.82559 15.7657C7.66767 15.6157 7.57895 15.4122 7.57895 15.2V5.6C7.57895 5.38783 7.66767 5.18434 7.82559 5.03431C7.98352 4.88429 8.19771 4.8 8.42105 4.8Z"
                  fill="#D7DCE6"/>
            </svg>
            <span class="header-table-text">Device</span>
          </div>
        </div>

        <div v-if="!checkAll && checkSome.length <= 1"
             class="case">
          <div class="table-header-box table-header-box-doesnt-hover">
            <CaseIcon />
            <span class="header-table-text">Case</span>
          </div>
        </div>

        <div v-if="!checkAll && checkSome.length <= 1"
             class="holder-btn">
          <div class="table-header-box">

          </div>
        </div>

        <div v-if="checkAll || checkSome.length >= 2"
             class="holder-deactivate-btn"
             id="disabled-deactivate-all"
             ref="disabledDeactivateAll"
        >
          <button
              id="deactivate_clients_in_contacts"
              :disabled="!checkPermission(['manage_contacts'])"
              class="btn btn-deactivate"
              @click.stop="openDeactivatePopup">
            <span class="btn-span">Deactivate all</span>
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M10 0.499969C4.47 0.499969 0 4.96997 0 10.5C0 16.03 4.47 20.5 10 20.5C15.53 20.5 20 16.03 20 10.5C20 4.96997 15.53 0.499969 10 0.499969ZM15 14.09L13.59 15.5L10 11.91L6.41 15.5L5 14.09L8.59 10.5L5 6.90997L6.41 5.49997L10 9.08997L13.59 5.49997L15 6.90997L11.41 10.5L15 14.09Z"
                  fill="#D7DCE6"/>
            </svg>
          </button>
          <tooltip v-if="!checkPermission(['manage_contacts'])" :target-ref="$refs.disabledDeactivateAll">
            <span>To be able to deactivate users from a system - you need to have the 'Manage contact's' permission in system</span>
          </tooltip>
        </div>

      </TableHeader>
      <div class="table-body"
           :class='contactsView'>
        <div
            class="table-row"
            v-for="(item, i) in users"
            :key="item + i"
        >
          <div class="card">
            <div class="check">
              <CustomCheckbox
                  @checked="changeCheckbox"
                  :id="item.id"
                  :label="item.name"
                  :input-element="item"
              />
            </div>
            <div class="mail">
              <div class="holder-info">
                <div
                    :id="`goto_client_profile_${item.id}`"
                    @click="gotoProfile(item.id)">
                  <span class="info-mail">{{ item.email }}</span>
                </div>
              </div>
            </div>
            <div
                class="device"
            >
              <div class="holder-info">
                <div class="holder-device">
                  <img :src="phoneDeviceIcon" alt="phone" />
                </div>
                <div class="holder-device">
                  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="16" cy="16" r="16" fill="#F8F5FF"/>
                    <path
                        d="M24.0549 19.7443V18.9878V14.4468V9.90569C24.0549 9.49008 23.7053 9.14917 23.2774 9.14917H7.72255C7.29471 9.14917 6.94412 9.48841 6.94412 9.90569V14.4468V18.9878V19.7443L5 22.0149C5 22.6404 5.52207 23.1492 6.16667 23.1492H24.8333C25.4769 23.1492 26 22.6402 26 22.0149L24.0549 19.7443ZM17.0566 22.7721H13.9451C13.8376 22.7721 13.7505 22.6881 13.7505 22.5827C13.7505 22.4774 13.8376 22.3934 13.9451 22.3934H17.0569C17.1634 22.3934 17.2515 22.4774 17.2515 22.5827C17.2485 22.6883 17.1632 22.7721 17.0566 22.7721ZM12.294 22.0156L12.8874 21.3225H18.1113L18.7057 22.0156H12.294ZM22.9603 18.4058C22.9603 18.7709 22.6548 19.0663 22.2823 19.0663H8.71749C8.345 19.0663 8.03943 18.7683 8.03943 18.4058V10.4891C8.03943 10.124 8.345 9.8286 8.71749 9.8286H22.2825C22.655 9.8286 22.9606 10.1259 22.9606 10.4891C22.9603 10.4891 22.9603 18.4058 22.9603 18.4058Z"
                        fill="#9B7EDE"/>
                  </svg>
                </div>
              </div>
            </div>

            <div class="case">
              <div class="holder-info">
                <span class="case-span">{{ item.caseCount + " " + (item.caseCount === 1 ? "case" : "cases") }}</span>
              </div>
            </div>

            <div class="holder-btn permission" :id="`disabled-deactivate-client_${item.id}`" :ref="`disabledDeactivateClient_${item.id}`">
              <button
                  :disabled="!checkPermission(['manage_contacts'])"
                  :id="`deactivate_client_${item.id}`"
                  class="btn btn-deactivate"
                  data-title="Deactivate"
                  @click.stop="confirmDeactivateUser(item)"
              >
                <span class="btn-span">Deactivate</span>
                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M10 0.499969C4.47 0.499969 0 4.96997 0 10.5C0 16.03 4.47 20.5 10 20.5C15.53 20.5 20 16.03 20 10.5C20 4.96997 15.53 0.499969 10 0.499969ZM15 14.09L13.59 15.5L10 11.91L6.41 15.5L5 14.09L8.59 10.5L5 6.90997L6.41 5.49997L10 9.08997L13.59 5.49997L15 6.90997L11.41 10.5L15 14.09Z"
                      fill="#D7DCE6"/>
                </svg>
              </button>
              <tooltip v-if="!checkPermission(['manage_contacts'])" :target-ref="$refs[`disabledDeactivateClient_${item.id}`]">
                <span>To be able to deactivate users from a system - you need to have the 'Manage contact's' permission in system</span>
              </tooltip>
            </div>
          </div>
        </div>
        <div class="loader-bg" v-if="loadFlag">
          <div class="loader-p"></div>
        </div>
        <div v-if="!users.length">
          <p class="no-found">No custodians found</p>
        </div>
      </div>
      <pagination v-if="users.length && count !== 1"
                  v-model="currentPage"
                  :total-pages="count"
                  :link-gen="linkGen"
      ></pagination>
      <DeactivateItemPopUp
          :arrayOfCheckedUsers="checkSome"
          ref="contactsPopup"
          @deactivateArrayOfUsers="confirmDeactivateUserList"
      />
      <CommonDialog
          ref="commonDialogDeleteUser"
      >
        <svg width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M47.0015 0.776367C37.8596 0.776367 28.9231 3.48725 21.3218 8.56622C13.7206 13.6452 7.79623 20.8641 4.29777 29.3101C0.799324 37.7561 -0.11603 47.0499 1.66746 56.0161C3.45096 64.9823 7.8532 73.2183 14.3175 79.6826C20.7818 86.1469 29.0178 90.5492 37.984 92.3327C46.9503 94.1162 56.244 93.2008 64.69 89.7024C73.136 86.2039 80.355 80.2795 85.4339 72.6783C90.5129 65.0771 93.2238 56.1405 93.2238 46.9986C93.2238 34.7397 88.3539 22.9829 79.6856 14.3145C71.0172 5.6462 59.2604 0.776367 47.0015 0.776367V0.776367ZM77.1904 31.4853L39.2304 69.4164L16.8127 46.9986C16.0465 46.2324 15.616 45.1932 15.616 44.1097C15.616 43.0262 16.0465 41.987 16.8127 41.2208C17.5788 40.4546 18.618 40.0242 19.7015 40.0242C20.7851 40.0242 21.8242 40.4546 22.5904 41.2208L39.2882 57.9186L71.4704 25.7653C71.8498 25.3859 72.3002 25.0849 72.7959 24.8796C73.2915 24.6743 73.8228 24.5686 74.3593 24.5686C74.8958 24.5686 75.4271 24.6743 75.9228 24.8796C76.4184 25.0849 76.8688 25.3859 77.2482 25.7653C77.6276 26.1446 77.9285 26.595 78.1338 27.0907C78.3392 27.5864 78.4448 28.1176 78.4448 28.6541C78.4448 29.1907 78.3392 29.7219 78.1338 30.2176C77.9285 30.7133 77.6276 31.1637 77.2482 31.543L77.1904 31.4853Z"
              fill="#082655"/>
        </svg>
        <h2 class="dialog-message-title">Success!</h2>
        <p class="dialog-message-description" style="display:block;">Selected contacts were deactivated</p>
      </CommonDialog>
      <InfoDialog
          ref="infoDialog"
          :title="infoDialogData.dialogTitle"
          :text="infoDialogData.dialogText"
          :confirmBtnText="infoDialogData.confirmBtnText"
          :cancelBtnText="infoDialogData.cancelBtnText"
          @onConfirm="deactivateUser"/>
      <InfoDialog
          ref="infoDialogDeactivateUserList"
          :title="infoDialogData.dialogTitle"
          :text="infoDialogData.dialogText"
          :confirmBtnText="infoDialogData.confirmBtnText"
          :cancelBtnText="infoDialogData.cancelBtnText"
          @onConfirm="deactivateArrayOfUsers"/>
    </div>
  </div>
</template>

<script>
import checkAll from "../../mixins/checkAll";
import linkGenerator from "../../constants/linkGen";
import createDeactivateDialog from "../../constants/infoDialogData";
import InfoDialog from "../popups/InfoDialog";
import checkPermission from "../../utils/permission";
import CustomCheckbox from "../inputs/CustomCheckbox";
import TableHeader from "../table/TableHeader";
import DeactivateItemPopUp from "../popups/DeactivateItemPopUp";
import CommonDialog from "../popups/CommonDialog";
import AlertErrorMsg from "../profile/AlertErrorMsg";
import ArrowDown from "../icons/ArrowDown";
import EmailIcon from "../icons/EmailIcon";
import CaseIcon from "../icons/CaseIcon";
import Tooltip from "@/components/common/Tooltip.vue";
import Pagination from "@/components/common/Pagination.vue";
import phoneDeviceIcon from "@/assets/img/phone-device-icon.svg"

export default {
  name: "ContactsClients",
  components: {
    Pagination,
    Tooltip,
    CaseIcon,
    EmailIcon,
    ArrowDown,
    InfoDialog,
    DeactivateItemPopUp,
    TableHeader,
    CustomCheckbox,
    CommonDialog,
    AlertErrorMsg,
  },
  props: {
    title: {
      type: String
    },
    contactsView: {
      type: String
    },
    searchText: {
      type: String
    },
  },
  data() {
    return {
      phoneDeviceIcon,
      infoDialogData: {},
      currentPage: 1,
      size: 8,
      count: 0,
      pageSize: 2,
      users: [],
      sortBy: 'emailUser',
      order: 'asc',
      errorMessage: '',
      loadFlag: false,
    }
  },
  mixins: [checkAll],
  watch: {
    searchText() {
      this.currentPage = 1;
      this.getClients();
    },
    currentPage(newVal, oldVal) {
      if (newVal !== oldVal && +this.$router.currentRoute.query.page !== newVal) {
        this.$router.push(this.linkGen(newVal));
      }
    },
    '$route.query.page': {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.currentPage = +newVal;
          this.getClients();
        } else {
          this.currentPage = 1;
        }
      }
    },
  },
  methods: {
    checkPermission,
    unselectAll() {
      this.users.forEach((item) => {
        item.isActive = false;
      })
    },
    changeCheckbox(item) {
      this.$emit('changeCheckbox', item, this.title)
    },
    openDeactivatePopup() {
      this.$refs.contactsPopup.openPopup()
    },
    openDeactivatePopupUser() {
      this.$refs.commonDialogDeleteUser.openPopups();
    },
    linkGen(pageNum) {
      return linkGenerator(pageNum, '/contacts/custodians')
    },
    sortedBy(filterName) {
      this.order = filterName === this.sortBy && this.order === 'desc' ? 'asc' : 'desc'
      this.sortBy = filterName
      this.getClients()
    },
    fixedEncodeURIComponent (str) {
      return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
        return '%' + c.charCodeAt(0).toString(16);
      });
    },
    getClients() {
      this.$load(async () => {
        this.loadFlag = true
        await this.$api.contacts.getContactClients({
          page: this.currentPage,
          size: this.size,
          sortBy: this.sortBy,
          order: this.order,
          contactName: this.fixedEncodeURIComponent(this.searchText),
        }).then((res) => {
          this.users = res.data.body.content.map(i => {
            i.isActive = false
            return i
          })
          this.count = res.data.body.totalPages
          this.pageSize = res.data.body.size
        }, (error) => {
          if (error.response) {
            this.errorMessage = error.response.data.errors.message[0]
          } else {
            this.errorMessage = error.data.errors.message[0]
          }
          this.$refs.alert.showAlert()
        })
        setTimeout(() => {
          this.loadFlag = false
        }, 1000)
      })
    },
    gotoProfile(id) {
      let route = this.$router.resolve({name: 'Client Profile', params: { slug: id}})
      window.open(route.href, '_blank');
    },
    confirmDeactivateUser(user) {
      this.infoDialogData = createDeactivateDialog('custodian');
      this.$refs.infoDialog.openPopup(user);
    },
    confirmDeactivateUserList(userlist) {
      this.infoDialogData = createDeactivateDialog('contacts');
      this.$refs.infoDialogDeactivateUserList.openPopup(userlist);
    },
    deactivateUser (item) {
      this.openDeactivatePopupUser()
      this.$load(async () => {
        this.loadFlag = true
        await this.$api.contacts.deactivateUser(item.id).then(() => {
          this.$refs.infoDialog.closePopup();
          this.openDeactivatePopupUser()
          this.getClients()
        }, (error) => {
          if (error.response) {
            this.errorMessage = error.response.data.errors.message[0]
          } else {
            this.errorMessage = error.data.errors.message[0]
          }
          this.$refs.alert.showAlert()
        })
      })
    },
    deactivateArrayOfUsers(arrayOfCheckedUsers) {
      const result = arrayOfCheckedUsers.map(user => user.id)
      result.toString()
      this.$load(async () => {
        this.loadFlag = true
        await this.$api.contacts.deactivateUser(result).then(() => {
          this.$refs.infoDialogDeactivateUserList.closePopup();
          this.openDeactivatePopupUser()
          this.getClients()
        }, (error) => {
          if (error.response) {
            this.errorMessage = error.response.data.errors.message[0]
          } else {
            this.errorMessage = error.data.errors.message[0]
          }
          this.$refs.alert.showAlert()
        })
      })
    }
  },
  mounted() {
    this.currentPage = +this.$route.query.page || 1;
    this.getClients();
  }
}
</script>

<style lang="scss" scoped>

.wrapper {
  display: block;
}

.table-header {
  max-height: 54px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 0 15px 22px;
}

.table-header.active {
  justify-content: space-between;
  padding-right: 13px;
  background-color: var(--accent-color);
}

.table-header-box svg path {
  fill: var(--input-placeholder-value-color);
  transition: all 0.3s;
}

.table-header-box:hover svg:first-child path {
  fill: var(--accent-color);
}

.list .check {
  display: flex;
  align-items: center;
  width: 5%;
}

.list .name {
  min-width: 250px;
}

.list .mail {
  width: 30%;
}

.mail span {
  cursor: pointer;
}

.table-body .mail span {
  color: var(--light-gray);
  font-size: 12px;
  font-weight: 400;
  transition: all .3s;
}

.table-body .mail span:hover {
  color: var(--accent-color);
}

.list .roles, .list .case {
  width: 15%;
}

.user .list .permission {
  min-width: 340px;
  width: 25%;
}

.clients .list .name {
  //width: 25%;
}

.case-span {
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
  color: var(--secondary-text-color);
}

.list .phone {
  width: 15%;
}

.list .device {
  width: 20%;
}

.list .case {
  width: 20%;
}

.list .holder-btn {
  display: flex;
  justify-content: flex-end;
  margin: 0 14px 0 auto;
}

.list .card {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.list .table-row {
  display: flex;
  align-items: center;
  padding: 22px 0 22px 22px;
  transition: all .5s;
}

.list .table-row:hover {
  box-shadow: 0 4px 20px rgba(77, 106, 147, 0.15);
}

.list .holder-info {
  display: flex;
  align-items: center;
}

.list .img {
  width: 30px;
  margin-right: 10px;
}

.info-name {
  font-weight: 500;
  font-size: 14px;
  color: var(--secondary-text-color);
}

.list .info {
  display: flex;
  flex-direction: column;
}

.info-mail {
  max-width: 100%;
  font-weight: 400;
  font-size: 12px;
  color: var(--light-gray);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.list .btn-span {
  margin-right: 12px;
  font-weight: 500;
  font-size: 14px;
  text-transform: none;
  color: #373C44;
}

.telephone {
  font-weight: 400;
  font-size: 10px;
  color: var(--secondary-text-color);
}

.holder-device {
  margin-right: 10px;
}

.role-data {
  display: inline-block;
  min-width: 95px;
  padding: 8px 8px;
  text-align: center;
  border-radius: 20px;
  margin-right: 37px;
}

.admin {
  background-color: #F1F9E6;
  color: var(--admin-color);
}

.lawyer {
  background-color: #FEF0EE;
  color: #EE6352;
}

.associates {
  background-color: #F5F3FC;
  color: #9B7EDE;
}

// grid
.table-header.grid {
  margin: 0 0 15px;
}

.table-header.grid .check {
  width: 70px;
}

.table-header.grid .name {
  width: 30%;
}

.table-header.grid .roles {
  width: 15%;
}

.table-header.grid .phone {
  display: none;
}

.table-header.grid .holder-btn {
  display: flex;
  justify-content: space-between;
  width: 52%;
}

.grid .device .holder-info {
  display: flex;
  flex-direction: row;
}

.grid .phone, .grid .mail {
  margin-bottom: 10px;
}

.table-header.grid .name,
.table-header.grid .roles,
.table-header.grid .holder-btn,
.table-header.grid .phone,
.table-header.grid .mail,
.table-header.grid .case,
.table-header.grid .permission,
.table-header.grid .device
{
  display: none;
}

.grid {
  display: flex;
  //flex-wrap: wrap;
  margin: 0 -10px;
  margin-bottom: 20px;
}

.grid .table-header {
  margin-bottom: 20px;
}

.grid .table-row {
  width: 20%;
  padding: 0 10px;
  margin-bottom: 20px;
}

.grid .card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 18px 10px;
  box-shadow: 0 0 15px rgba(5, 51, 48, 0.06);
  border-radius: 5px;
  transition: all 0.5s;
  cursor: pointer;
  position: relative;
}

.grid .card:hover {
  box-shadow: 0 4px 20px rgba(77, 106, 147, 0.28);
}

.grid.table-body .check {
  align-self: start;
  margin-bottom: 16px;
}

.grid .img {
  width: 45px;
  margin: 0 auto;
  margin-bottom: 7px;
}

.grid .info {
  display: flex;
  flex-direction: column;
}

.grid .holder-info {
  margin-bottom: 15px;
}

.grid .info span {
  margin-bottom: 10px;
}

.grid .info-mail {
  //display: none;
  max-width: 210px;
}

.grid .span-cases-wrap {
  text-align: center;
}

.table-body.grid {
  flex-wrap: wrap;
  min-height: auto;
}

.table-body.grid .roles {
  margin: 0 auto;
  margin-bottom: 14px;
}

.table-body.grid .role-data {
  margin: 0;
  margin-bottom: 10px;
}

.table-body.grid .holder-btn {
  position: absolute;
  top: 18px;
  right: 10px;
  display: flex;
}

.table-body.grid .holder-btn span {
  display: none;
}

.table-body.grid .holder-btn .btn {
  min-width: auto;
  padding: 0;
  border: none;
  background: var(--white-color);
  height: auto;
  width: auto;
}

.table-body.grid .holder-btn .btn svg path {
  fill: var(--input-placeholder-value-color);
  transition: all 0.3s;
}

.table-body.grid .holder-btn .gray-btn svg path {
  fill: var(--accent-color);
}

.table-body.grid .holder-btn .btn:hover svg path {
  fill: var(--bright-accent-color);
}

.btn-deactivate {
  width: 129px;
  height: 35px;
  min-width: inherit;
  font-size: 12px;
  font-weight: 500;
}

.btn-deactivate:disabled {
  pointer-events: none !important;
}

.table-header .btn {
  transition: none;
}

.active .btn:hover {
  background-color: white;
}

.label-checkbox span {
  color: white;
}

.checkbox-wrap .checkbox-unselect {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  text-transform: initial;
  cursor: pointer;
}

.grid .btn-span {
  margin-right: 12px;
  font-weight: 500;
  font-size: 14px;
  text-transform: none;
  color: var(--secondary-text-color);
}

.checkbox-wrap span {
  top: 50%;
  transform: translateY(-50%);
}

.label-checkbox span {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  text-transform: initial;
}

[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}
[data-title]:after {
  content: attr(data-title);
  background: #FFFFFF;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.07);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #696F79;
  position: absolute;
  bottom: -1.6em;
  padding: 7px 14px;
  left: 100%;
  white-space: nowrap;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
}
[data-title] {
  position: relative;
}

.empty-contacts {
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog-message-description {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #696F79;
  display: block;
  width: 100%;
  margin-top: 20px;
}

</style>
