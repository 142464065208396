<template>
  <div>
    <div class="comment-info">
      <div class="comment-user">
        <div class="img-item">
          <img v-if="comment.avatar" :src="comment.avatar" alt="avatar">
          <img
            v-else
            src="https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/girl_female_woman_avatar-512.png"
            alt="">
        </div>
        <span>{{ comment.email }}</span>
      </div>
      <span class="date">{{ parseDate(comment.createdAt) + ", " + parseDateTime(comment.createdAt) }}</span>
    </div>
    <div class="message-text">
      <span v-if="!editingComment" v-html="commentToShow(comment.text)"></span>
      <div v-else-if="editingComment" class="edit-wrapper">
        <textarea
          ref="editCommentTextarea"
          type="text"
          v-model="newComment"
          @keydown.enter.exact.prevent="confirmCommentChange"
          @keydown.shift.enter.exact.prevent
          @keyup.esc="cancelMessageChange"
          class="edit-message-textarea"
        ></textarea>
        <div class="btn-wrapper">
          <button
            id="save_comment_change_button"
            class="btn btn-revert"
            @click="cancelMessageChange"
          >
            Cancel
          </button>
          <button
            id="save_comment_change_button"
            class="btn"
            @click="confirmCommentChange"
          >
            Save
          </button>
        </div>
      </div>
      <ContextMenu :item="comment" :options="commentOptions" @onSelected="onCommentMenuSelection"/>
      <InfoDialog
        ref="deleteDialog"
        :title="infoDialogData.dialogTitle"
        :text="infoDialogData.dialogText"
        :confirmBtnText="infoDialogData.confirmBtnText"
        :cancelBtnText="infoDialogData.cancelBtnText"
        @onConfirm="removeComment"/>
    </div>
  </div>
</template>
<script>
import ContextMenu from "@/components/popups/ContextMenu.vue";
import InfoDialog from "@/components/popups/InfoDialog.vue";
import editIcon from "@/assets/img/case-messages/edit.svg";
import deleteIcon from "@/assets/img/case-messages/delete.svg";
import { createDeleteDialog } from "@/constants/infoDialogData";
import { mapActions } from "vuex";
import parseDate from "@/mixins/parseDate";
import parseDateTime from "@/mixins/parseTime";

const commentOptions = [
  {
    name: 'Edit',
    img: editIcon,
  },
  {
    name: 'Delete',
    img: deleteIcon
  },
];

export default {
  name: "CommentMessage",
  components: {
    ContextMenu,
    InfoDialog,
  },
  emits: ['deleted'],
  props: {
    comment: {
      required: true
    },
    commentSearch: {
      type: String,
    }
  },
  data() {
    return {
      commentOptions,
      newComment: '',
      infoDialogData: {},
      editingComment: false,
    }
  },
  mixins: [parseDate, parseDateTime],
  methods: {
    ...mapActions('caseMessages', {
      setEditingComment: "setEditingComment",
      changeComment: "changeComment",
      deleteComment: "deleteComment",
    }),
    onCommentMenuSelection(optionName, comment) {
      this.setEditingComment(comment);
      switch (optionName) {
        case 'Edit':
          this.editComment(comment);
          break;
        case 'Delete':
          this.showDeletePopup(comment);
          break;
        default: this.errorMessage = 'No such option';
      }
    },
    editComment(comment) {
      this.editingComment = true;
      this.newComment = comment.text;
    },
    showDeletePopup(comment) {
      this.infoDialogData = createDeleteDialog('comment');
      this.$refs.deleteDialog.openPopup(comment);
    },
    removeComment() {
      this.deleteComment();
      this.$emit('deleted');
    },
    confirmCommentChange() {
      this.changeComment(this.newComment);
      this.cancelMessageChange();
    },
    cancelMessageChange() {
      this.editingComment = false;
      this.newComment = '';
    },
    commentToShow(commentText) {
      if (!this.commentSearch) {
        return commentText;
      }

      const escapedMessage = this.escapeHtml(commentText);
      const escapedSearchText = this.escapeRegExp(this.commentSearch);
      const regex = new RegExp(`(${escapedSearchText})`, 'gi');

      return escapedMessage.replace(
        regex,
        '<span style="background-color: yellow;">$1</span>'
      );
    },
    escapeHtml(text) {
      return text.replace(/[&<>"']/g, (match) => {
        const escapeMap = {
          '&': '&amp;',
          '<': '&lt;',
          '>': '&gt;',
          '"': '&quot;',
          "'": '&#039;',
        };
        return escapeMap[match];
      });
    },
    escapeRegExp(text) {
      return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    },
  }
}
</script>

<style scoped lang="scss">

.comment-info {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .comment-user {
    display: flex;
    align-items: center;
    gap: 10px;

    .img-item {
      width: 100%;
      max-width: 32px;
      max-height: 32px;
      min-width: 32px;
      min-height: 32px;
      overflow: hidden;
      border: 1px solid var(--white-color);
      border-radius: 50%;
    }

    .img-item img {
      width: 100%;
      height: 100%;
    }
  }

  .date {
    width: 80px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: right;
    color: var(--light-gray);
  }
}

.message-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 15px;

  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 19.2px;
    text-align: left;
    color: var(--secondary-text-color);
  }
}

.edit-wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 722px;
  width: 100%;
  align-items: flex-end;

  .edit-message-textarea {
    display: block;
    border: .5px solid var(--case-btn-border-color);
    border-radius: 16px !important;
    height: 51px;
    padding: 16px 24px !important;
  }

  .btn-wrapper {
    display: flex;
    gap: 15px;
    align-items: center;
    .btn {
      width: 121px;
      height: 35px;
      border-radius: 25px;
    }
  }
}

</style>