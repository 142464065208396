<template>
  <header class="header">
    <div class="container-large header-wrapper">
      <div class="logo-wrap">
        <router-link
            id="goto_home_page"
            :to="{ name: 'Home' }" tag="a" class="" exact>
          <img :src="logo" alt="logo" />
        </router-link>
      </div>
      <button @click="$router.push({name: 'LoginInAccount'}).catch(()=>{})" id="home_header_button" class="header-btn btn">Log in</button>
    </div>
  </header>
</template>

<script>
import LocalStorageService from "../../services/LocalStorageService";
import logo from "@/assets/img/logo.svg"

const localStorageService = LocalStorageService.getService();

export default {
  name: "Header",
  data() {
    return {
      logo,
    }
  },
  mounted() {
    if(localStorageService.getAccessTokenLogin() !== null) {
      this.$store.commit('authentication/loginSuccess', null)
    }
  }
}
</script>

<style scoped>

  header {
    padding: 20px 0px;
    /*position: fixed;*/
    width: 100%;
    z-index: 20;
    background: var(--white-color);
  }

  .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .logo {
    max-width: 65px;
  }

</style>