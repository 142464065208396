<template>
  <section
    class="calls-section"
    :class="[
      isAdvancedFilter || isAdvancedFilterApplied ? 'advanced-filter' : 'simple-filter',
      isAdvancedFilter ? 'open' : '',
    ]"
  >
    <div class="top-bar">
      <div class="settings-wrapper">
        <div v-if="!isAdvancedFilter" class="tab-wrapper">
          <router-link
            :to="{ name: 'Case messages mobile list' }"
            tag="button"
            id="messages_mobile_tab"
            exact-path
            class="tab-btn"
          >
            <span class="tab-btn-text">Mobile chats</span>
          </router-link>
          <router-link
            :to="{ name: 'Case messages email list' }"
            tag="button"
            id="messages_email_tab"
            exact-path
            class="tab-btn"
          >
            <span class="tab-btn-text">Email</span>
          </router-link>
          <router-link
            :to="{ name: 'Case messages media list' }"
            tag="button"
            id="messages_media_tab"
            exact-path
            class="tab-btn"
          >
            <span class="tab-btn-text">Media</span>
          </router-link>
          <router-link
            :to="{ name: 'Case messages call logs list' }"
            tag="button"
            id="messages_logs_tab"
            exact-path
            class="tab-btn"
          >
            <span class="tab-btn-text">Call Logs</span>
          </router-link>
          <router-link
            :to="{ name: 'Case messages contacts list' }"
            tag="button"
            id="messages_contacts_tab"
            exact-path
            class="tab-btn"
          >
            <span class="tab-btn-text">Contacts</span>
          </router-link>
          <router-link
            :to="{ name: 'Case messages apps list' }"
            tag="button"
            id="messages_apps_tab"
            exact-path
            class="tab-btn"
          >
            <span class="tab-btn-text">Apps</span>
          </router-link>
        </div>
        <template v-if="isAdvancedFilterApplied || isAdvancedFilter">
          <div v-if="isAdvancedFilter" class="filter-types">
            <button
              :class="{selected: !filterTypeSaved}"
              class="filter-type"
              @click="filterTypeSaved = false"
            >
              Default
            </button>
            <button
              :class="{selected: filterTypeSaved}"
              class="filter-type"
              @click="filterTypeSaved = true"
            >
              Saved
            </button>
          </div>
        </template>
        <div class="advanced-change-button-wrapper">
          <button v-if="stringifiedRules" class="advanced-change-button add-to-favorites"
                  @click="openSaveFilterPatternPopup">
            <svg width="16" height="23" viewBox="0 0 16 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.9221 0.09375V22.3134C15.9219 22.5357 15.7975 22.7394 15.5996 22.8409C15.4017 22.9424 15.1636 22.9248 14.9829 22.795L7.99416 17.7856L1.00567 22.795C0.824907 22.9248 0.586905 22.9424 0.388995 22.8409C0.191085 22.7394 0.066638 22.5357 0.0664062 22.3134V0.09375H15.9221Z"
                fill="#082655"/>
            </svg>
            <span>Add To Favorites</span>
          </button>
          <button
            v-if="isAdvancedFilterApplied || isAdvancedFilter"
            :disabled="!isAdvancedFilterApplied"
            class="reset-filters"
            @click="resetFilters"
          >
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.9997 2.33203C7.54801 2.33203 2.33301 7.54703 2.33301 13.9987C2.33301 20.4504 7.54801 25.6654 13.9997 25.6654C20.4513 25.6654 25.6663 20.4504 25.6663 13.9987C25.6663 7.54703 20.4513 2.33203 13.9997 2.33203ZM19.833 18.187L18.188 19.832L13.9997 15.6437L9.81134 19.832L8.16634 18.187L12.3547 13.9987L8.16634 9.81036L9.81134 8.16537L13.9997 12.3537L18.188 8.16537L19.833 9.81036L15.6447 13.9987L19.833 18.187Z" fill="#FF9537"/>
            </svg>
            Reset Filters
          </button>
          <button @click="changeFilterView" class="advanced-change-button">
            <svg :class="{'advanced-mode': isAdvancedFilter}" width="24" height="25" viewBox="0 0 24 25" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13.7236 19.6382L10.7236 21.1382C10.3912 21.3044 10 21.0627 10 20.691V13.7071C10 13.5745 9.94732 13.4473 9.85355 13.3536L4.14645 7.64645C4.05268 7.55268 4 7.4255 4 7.29289V5C4 4.72386 4.22386 4.5 4.5 4.5H19.5C19.7761 4.5 20 4.72386 20 5V7.29289C20 7.4255 19.9473 7.55268 19.8536 7.64645L14.1464 13.3536C14.0527 13.4473 14 13.5745 14 13.7071V19.191C14 19.3804 13.893 19.5535 13.7236 19.6382Z"
                stroke="#082655"/>
            </svg>

            <span>Advanced</span>

            <svg :class="{'advanced-mode-arrow': isAdvancedFilter}" width="16" height="17" viewBox="0 0 16 17"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.99059 12.498C7.79067 12.4984 7.59692 12.4287 7.44298 12.3009L2.3091 8.01743C2.13437 7.87202 2.02448 7.66306 2.00362 7.43652C1.98276 7.20999 2.05263 6.98444 2.19787 6.80948C2.34311 6.63453 2.55181 6.52451 2.77806 6.50363C3.00432 6.48274 3.22959 6.5527 3.40433 6.69811L7.99059 10.5361L12.5768 6.83519C12.6644 6.76402 12.7651 6.71088 12.8732 6.67881C12.9813 6.64675 13.0946 6.63638 13.2068 6.64833C13.3189 6.66027 13.4275 6.69428 13.5265 6.7484C13.6254 6.80252 13.7127 6.87568 13.7833 6.96369C13.8617 7.05178 13.921 7.15512 13.9576 7.26724C13.9942 7.37936 14.0073 7.49784 13.9961 7.61526C13.9848 7.73268 13.9495 7.84652 13.8923 7.94963C13.835 8.05273 13.7572 8.1429 13.6635 8.21447L8.52964 12.3523C8.37128 12.4599 8.18148 12.5111 7.99059 12.498Z"
                fill="#8692A6"/>
            </svg>
          </button>
        </div>
      </div>
      <div v-if="isLoad" v-show="isAdvancedFilter" class="advanced-filter-wrap">
        <SavedFilters
          v-if="filterTypeSaved"
          @attachFilter="attachFilter"
          @backToDefault="filterTypeSaved = false"
        />
        <div v-else class="control-section">
          <div class="col-lg-12 querybuilder-control">
            <ejs-querybuilder
              ref="querybuilder"
              :dataSource="dataSource"
              :maxGroupCount="100"
              :rule="importRules"
              @change="ruleChanged"
            >
              <e-columns>
                <e-column field='callerName' label='Search for a Caller name' type='string' :template='defaultTemplate'
                          :operators="[{ value: 'is', key: 'is' }, { value: 'is_not', key: 'is not' }, { value: 'in', key: 'in' }, { value: 'not_in', key: 'not in' }]"/>
                <e-column field='phoneNumber' label='Search for a Phone number' :template='defaultTemplate' type='string'
                          :operators="[{ value: 'is', key: 'is' }, { value: 'is_not', key: 'is not' }, { value: 'in', key: 'in' }, { value: 'not_in', key: 'not in' }]"/>
                <e-column field='date' label='Date' type='date' :template='dateTemplate'
                          :operators="[{ value: 'range', key: 'range' }]" format='dd/MM/yyyy'/>
                <e-column field='callStatus' label='Call status' type='string' :template='defaultTemplate'
                          :operators="[{ value: 'in', key: 'in' }, { value: 'not_in', key: 'not in' }]"/>
              </e-columns>
            </ejs-querybuilder>
            <div class="advanced-btn-wrapper">
              <button class="btn btn-apply" type="button" @click="addRule">+ Add Rule</button>
              <button class="btn btn-apply" type="button" @click="addGroup">+ Add Group</button>
              <button
                :disabled="emptyRule"
                class="btn btn-apply"
                type="button"
                @click="filter(false)"
              >Apply
              </button>
              <span>{{ resultCount }} matches</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isAdvancedFilterApplied && !isAdvancedFilter" class="advanced-filter-wrap">
        <div class="filter-tags-close">
          <button class="advanced-change-button" @click="isOpenedFilterList = !isOpenedFilterList">
            <span>{{ isOpenedFilterList ? 'Close' : 'Show' }} Applied Filters</span>
            <svg
              :class="{'advanced-mode-arrow': isOpenedFilterList}"
              class="filter-tags-arrow"
              fill="none"
              height="17"
              viewBox="0 0 16 17"
              width="16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.99059 12.498C7.79067 12.4984 7.59692 12.4287 7.44298 12.3009L2.3091 8.01743C2.13437 7.87202 2.02448 7.66306 2.00362 7.43652C1.98276 7.20999 2.05263 6.98444 2.19787 6.80948C2.34311 6.63453 2.55181 6.52451 2.77806 6.50363C3.00432 6.48274 3.22959 6.5527 3.40433 6.69811L7.99059 10.5361L12.5768 6.83519C12.6644 6.76402 12.7651 6.71088 12.8732 6.67881C12.9813 6.64675 13.0946 6.63638 13.2068 6.64833C13.3189 6.66027 13.4275 6.69428 13.5265 6.7484C13.6254 6.80252 13.7127 6.87568 13.7833 6.96369C13.8617 7.05178 13.921 7.15512 13.9576 7.26724C13.9942 7.37936 14.0073 7.49784 13.9961 7.61526C13.9848 7.73268 13.9495 7.84652 13.8923 7.94963C13.835 8.05273 13.7572 8.1429 13.6635 8.21447L8.52964 12.3523C8.37128 12.4599 8.18148 12.5111 7.99059 12.498Z"
                fill="#8692A6"
              />
            </svg>
          </button>
        </div>

        <ul v-show="isOpenedFilterList" class="control-tags">
          <li
            v-for="tag of filteredTags"
            :key="tag.id"
            :class="{ 'removed': tag.removed }"
            class="filter-tag"
          >
            <button type="button" @click="removeFilter(tag)">
              <svg fill="none" height="28" viewBox="0 0 28 28" width="28" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M14 2.3335C7.54833 2.3335 2.33333 7.5485 2.33333 14.0002C2.33333 20.4518 7.54833 25.6668 14 25.6668C20.4517 25.6668 25.6667 20.4518 25.6667 14.0002C25.6667 7.5485 20.4517 2.3335 14 2.3335ZM19.8333 18.1885L18.1883 19.8335L14 15.6452L9.81166 19.8335L8.16666 18.1885L12.355 14.0002L8.16666 9.81183L9.81166 8.16683L14 12.3552L18.1883 8.16683L19.8333 9.81183L15.645 14.0002L19.8333 18.1885Z"
                  data-v-1f88d880=""
                  fill="#D7DCE5"
                />
              </svg>
            </button>

            <strong>{{ tag.label | filteringLabel }}</strong>:&nbsp;
            <span>{{ tag.initialValue || tag.value }}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="filter-content">
      <div v-if="!isAdvancedFilterApplied" class="nav-chat" :style="{ 'padding-top' : isAdvancedFilter ? '30px' : '120px' }">
        <div class="dashboard-search">
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.5" clip-path="url(#clip0_9084_575)">
              <path
                d="M23.0362 21.4823C23.6053 22.0461 23.6053 22.9642 23.0362 23.5334C22.7516 23.8179 22.3811 23.9575 22.0107 23.9575C21.6402 23.9575 21.2697 23.8179 20.9852 23.5334L16.0993 18.6474C16.4859 18.3629 16.851 18.0461 17.1999 17.6971C17.5436 17.3535 17.855 16.9884 18.1395 16.6072L18.2469 16.6877L23.0362 21.4823Z"
                fill="#8692A6"/>
              <path
                d="M17.2056 3.89861C20.674 7.36707 20.9854 12.7953 18.1452 16.6127C17.866 16.9939 17.5546 17.359 17.2056 17.7026C16.8566 18.0516 16.4915 18.3684 16.1049 18.653C12.2874 21.4825 6.86463 21.1657 3.40691 17.7026C-0.405169 13.8906 -0.405169 7.71069 3.40691 3.89861C7.21362 0.0865306 13.3935 0.0865306 17.2056 3.89861ZM15.6861 16.1832C18.6552 13.2087 18.6552 8.39257 15.6861 5.41807C12.7116 2.44895 7.8955 2.44895 4.92101 5.41807C1.94651 8.3872 1.95188 13.2087 4.92101 16.1832C7.89013 19.1523 12.7116 19.1523 15.6861 16.1832Z"
                fill="#8692A6"/>
              <path
                d="M15.686 5.41831C18.6551 8.38744 18.6551 13.2089 15.686 16.1834C12.7115 19.1525 7.89537 19.1525 4.92088 16.1834C1.95175 13.2089 1.95175 8.39281 4.92088 5.41831C7.89 2.44382 12.7115 2.44919 15.686 5.41831Z"
                fill="white"/>
              <path
                d="M18.8544 16.5376C20.1269 14.6477 20.7336 12.3765 20.5779 10.0839C20.4061 7.60873 19.343 5.27315 17.5873 3.51745C15.6437 1.57383 13.0558 0.5 10.3068 0.5C7.55778 0.5 4.96986 1.57383 3.02087 3.51745C1.07724 5.46644 0.00341797 8.04899 0.00341797 10.798C0.00341797 13.547 1.07724 16.1349 3.02087 18.0785C4.77657 19.8342 7.10677 20.8973 9.57657 21.0691C9.81818 21.0852 10.0598 21.096 10.296 21.096C12.3417 21.096 14.3444 20.4893 16.0356 19.351L20.5994 23.9148C20.9752 24.2906 21.4692 24.5 22.0061 24.5C22.5376 24.5 23.037 24.2906 23.4128 23.9201C23.7887 23.5443 23.9981 23.045 23.9981 22.5134C23.9981 21.9819 23.7887 21.4825 23.4128 21.1121L18.8544 16.5376ZM15.7833 18.2181C14.0222 19.5228 11.8477 20.1564 9.65174 20.0007C7.43429 19.845 5.35107 18.8946 3.77791 17.3215C2.03295 15.5819 1.07724 13.2624 1.07724 10.798C1.07724 8.33356 2.03832 6.01946 3.77791 4.2745C5.51751 2.52953 7.83698 1.57383 10.3014 1.57383C12.7658 1.57383 15.0799 2.5349 16.8249 4.2745C18.3981 5.84765 19.3484 7.93624 19.5041 10.1591C19.6544 12.355 19.0209 14.5349 17.7108 16.2906C17.4477 16.645 17.1524 16.994 16.8249 17.3161C16.492 17.6544 16.143 17.955 15.7833 18.2181ZM22.6611 23.1577C22.4893 23.3295 22.2585 23.4262 22.0115 23.4262C21.7645 23.4262 21.539 23.3295 21.3672 23.1577L16.9108 18.7013C17.1417 18.5081 17.3672 18.304 17.5873 18.0839C17.8021 17.8691 18.0115 17.6383 18.2048 17.4074L22.6611 21.8638C22.8329 22.0356 22.9296 22.2611 22.9296 22.5081C22.9296 22.7497 22.8329 22.9805 22.6611 23.1577Z"
                fill="#8692A6"/>
            </g>
            <defs>
              <clipPath id="clip0_9084_575">
                <rect width="24" height="24" fill="white" transform="translate(0 0.5)"/>
              </clipPath>
            </defs>
          </svg>
          <input
            id="case-apps-search"
            class="search"
            type="text"
            placeholder="Search"
            v-model="contactSearchModel"
          >
        </div>
        <vue-custom-scrollbar
          :settings="settings"
          class="scroll-area"
        >
          <ul class="profile-nav">
            <li
              v-for="(item, index) in users"
              :key="index"
              :id="`apps_contact_item_${item.publicId}`"
            >
              <a class="holder-contacts" :class="{'active': item.publicId === currentClientId }">
                <div class="contacts-name" @click="changeContact(item)">
                  <div class="img-item">
                    <img
                      v-if="item.avatar"
                      :src="item.avatar"
                      alt="avatar"
                    >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="12" cy="12" r="11.5" fill="white" stroke="#082655"/>
                      <path d="M12.0001 4.11523C9.5477 4.11523 7.54297 6.11996 7.54297 8.57238C7.54297 11.0248 9.5477 13.0295 12.0001 13.0295C14.4525 13.0295 16.4573 11.0248 16.4573 8.57238C16.4573 6.11996 14.4525 4.11523 12.0001 4.11523Z" fill="#082655"/>
                      <path d="M20.5349 16.6731C20.4046 16.3451 20.231 16.0389 20.0355 15.7546C19.0367 14.2675 17.4951 13.2834 15.758 13.0428C15.5408 13.0209 15.302 13.0646 15.1283 13.1959C14.2163 13.8738 13.1306 14.2237 12.0015 14.2237C10.8723 14.2237 9.78668 13.8738 8.8747 13.1959C8.70098 13.0646 8.46213 12.9991 8.24501 13.0428C6.50791 13.2834 4.94455 14.2675 3.96744 15.7546C3.77202 16.0389 3.59829 16.367 3.46804 16.6731C3.40291 16.8043 3.42461 16.9574 3.48973 17.0886C3.66346 17.3948 3.88058 17.701 4.076 17.9634C4.37998 18.3789 4.70569 18.7507 5.07484 19.1006C5.37882 19.4068 5.72623 19.6911 6.07368 19.9754C7.78904 21.2657 9.85185 21.9436 11.9798 21.9436C14.1077 21.9436 16.1705 21.2656 17.8859 19.9754C18.2333 19.713 18.5807 19.4068 18.8847 19.1006C19.2321 18.7507 19.5796 18.3789 19.8836 17.9634C20.1007 17.6791 20.2962 17.3948 20.4698 17.0886C20.5784 16.9574 20.6001 16.8043 20.5349 16.6731Z" fill="#082655"/>
                    </svg>
                  </div>
                  <span class="span-name">{{ item.email }}</span>
                </div>
                <div
                  class="message-time"
                  v-text="parseDate(Date.parse(item.date)) === '-' ? '' : parseDate(Date.parse(item.date))"
                />
              </a>
              <div v-if="item.publicId === currentClientId && item.deviceList" class="device-list-wrapper">
                <p v-for="(device, index) in item.deviceList" :key="index">
                  {{ device }}
                </p>
              </div>
            </li>
          </ul>
        </vue-custom-scrollbar>
        <div v-if="!isAdvancedFilterApplied && !users.length" class="empty-calls-wrap">
          <span>No results</span>
        </div>
      </div>
      <div v-if="!isAdvancedFilterApplied && callLogsList.length" class="calls-list">
        <call-logs-list :callLogsList="callLogsList" />
        <pagination v-if="totalPages > 1"
                    v-model="currentPage"
                    :total-pages="totalPages"
                    :link-gen="linkGen"
                    ref="mainPagination"
        ></pagination>
      </div>
      <div v-else-if="isAdvancedFilterApplied" class="calls-list-advanced">
        <call-logs-list-advanced :callLogsList="advancedCallLogsList" />
        <pagination v-if="totalPagesAdvanced > 1"
                    v-model="advancedCurrentPage"
                    :total-pages="totalPagesAdvanced"
                    :link-gen="linkGen"
                    ref="advancedPagination"
        ></pagination>
      </div>
      <div v-else class="empty-logs-list">
        <img :src="noCallsImg" alt=""/>
        <span>No Call Logs</span>
      </div>
    </div>
    <SaveFilterPattern
      ref="saveFilterPattern"
      @saveFilterPattern="saveFilterPattern"
    />
  </section>
</template>

<script>
import vueCustomScrollbar from 'vue-custom-scrollbar';
import settings from "../../../mixins/scrollSettings";
import debounce from '@/mixins/debounce';
import parseDate from "@/mixins/parseDate";
import CallLogsList from "@/components/сases/case-messages/calls/CallLogsList.vue";
import CallLogsListAdvanced from "@/components/сases/case-messages/calls/CallLogsListAdvanced.vue";
import SavedFilters from "@/components/сases/case-messages/SavedFilters.vue";
import SaveFilterPattern from "@/components/popups/SaveFilterPattern.vue";
import { createElement, getComponent } from "@syncfusion/ej2-base";
import { AutoComplete, MultiSelect } from "@syncfusion/ej2-dropdowns";
import { Query } from "@syncfusion/ej2-data";
import { DateRangePicker } from "@syncfusion/ej2-calendars";
import moment from "moment/moment";
import { ColumnDirective, ColumnsDirective, QueryBuilderComponent } from "@syncfusion/ej2-vue-querybuilder";
import capitalizeEveryWord from "@/mixins/capitalizeEveryWord";
import deepEqual from "@/mixins/deepEqual";
import Pagination from "@/components/common/Pagination.vue";
import linkGenerator from "@/constants/linkGen";
import noCallsImg from "@/assets/img/no-calls-img.png"

let inOperators = ['is', 'is_not', 'contain', 'not_contain'];
const types = ['value', 'deleteRule', 'deleteGroup', 'condition'];

export default {
  name: 'CaseMessagesCallLogs',
  components: {
    Pagination,
    SaveFilterPattern,
    SavedFilters,
    CallLogsList,
    CallLogsListAdvanced,
    vueCustomScrollbar,
    'ejs-querybuilder': QueryBuilderComponent,
    'e-column': ColumnDirective,
    'e-columns': ColumnsDirective
  },
  emits: ['show-error'],
  mixins: [settings, debounce, parseDate, capitalizeEveryWord, deepEqual],
  data() {
    return {
      noCallsImg,
      pageSize: 10,
      totalPages: null,
      totalPagesAdvanced: null,
      currentPage: +this.$route.query.page || 1,
      filtersAmountApplied: 0,
      isAdvancedFilterApplied: false,
      isLoad: true,
      dataRange: '',
      caseId: this.$route.params.slug,
      filterTypeSaved: false,
      isAdvancedFilter: false,
      dateFrom: '',
      dateTo: '',
      users: [],
      callLogsFilters: [],
      isOpenedFilterList: true,
      filteredTags: [],
      advancedCurrentPage: +this.$route.query.page || 1,
      CallLogsAdvancedSize: 10,
      dataSource: [],
      filterData: null,
      emptyRule: true,
      queryFilterParams: null,
      defaultQueryFilterParams: [],
      defaultImportRules: {'condition': "and",'rules': [{}, { field: 'callerName', operator: 'is', key: 'is', type: 'string', label: 'Search for a Caller name' }]},
      importRules: {'condition': "and",'rules': [{}, { field: 'callerName', operator: 'is', key: 'is', type: 'string', label: 'Search for a Caller name' }]},
      stringifiedRules: '',
      disabledFiltersId: [],
      resultCount: 0,
      initialData: [],
      currentClientId: '',
      callLogsList: [],
      advancedCallLogsList: [],
      contactSearchModel: '',
      dateTemplate: {
        create: () => {
          return createElement('input', { attrs: { 'type': 'text' } });
        },
        destroy: (args) => {
          let daterangepicker = getComponent(document.getElementById(args.elementId), 'daterangepicker');
          if(daterangepicker)
            daterangepicker.destroy();
        },
        write: (args) => {
          let daterangepicker = new DateRangePicker({
            placeholder: 'Select date range',
            disabled: true,
            change: (e) => {
              this.$refs.querybuilder.$el.ej2_instances[0].notifyChange(e.value, e.element);
            },
          });

          if (args.values) {
            daterangepicker.startDate = new Date(this.formatDate(args.values[0]));
            daterangepicker.endDate = new Date(this.formatDate(args.values[1]));
          }

          daterangepicker.appendTo('#' + args.elements.id);
        }
      },
      defaultTemplate: {
        create: () => {
          return createElement('input', { attrs: { 'type': 'text' } });
        },
        destroy: (args) => {
          let multiselect = getComponent(document.getElementById(args.elementId), 'multiselect');
          if(multiselect)
            multiselect.destroy();
          let autoComplete = getComponent(document.getElementById(args.elementId), 'autocomplete');
          if(autoComplete)
            autoComplete.destroy();
        },
        write: (args) => {
          let uniqueValues = new Set();

          this.initialData.forEach(item => {
            if (item.fieldName === args.field) {
              item.values.forEach(el => {
                if (el) {
                  uniqueValues.add(el);
                }
              });
            }
          });

          const ds = [...uniqueValues].map(el => ({ id: el, value: this.capitalizeEveryWord(el) }));

          if(inOperators.indexOf(args.operator) > -1) {
            let autoCompleteObj  = new AutoComplete({
              dataSource: ds,
              // value: args.values,
              fields: { text: 'value', value: 'id' },
              placeholder: `Find a ${this.stringModify(args.field)}`,
              highlight: true,
              itemTemplate:"<span class='filtering-name'>${id}</span>",
              change: (e) => {
                const { field } = this.$refs.querybuilder.$el.ej2_instances[0].selectedColumn.field;
                let name = '';

                if (e.itemData) {
                  name = e.itemData.id;
                }

                if (field === 'phoneNumber') {
                  name = name.replace(/[()]/g, '');
                }

                this.$refs.querybuilder.$el.ej2_instances[0].notifyChange(name, e.element);
              }
            });
            autoCompleteObj.appendTo('#' + args.elements.id);
          }else {
            let multiSelectObj = new MultiSelect({
              dataSource: ds,
              value: args.values,
              fields: { text: 'value', value: 'value' },
              mode: 'CheckBox',
              placeholder: `Select ${this.stringModify(args.field)}`,
              change: (e) => {
                this.$refs.querybuilder.$el.ej2_instances[0].notifyChange(e.value, e.element);
              },
              filtering: function (e) {
                let query = new Query();
                //frame the query based on search string with filter type.
                query = (e.text != "") ? query.where("value", "contains", e.text, true) : query;
                //pass the filter data source, filter query to updateData method.
                e.updateData(ds, query);
              }
            });
            multiSelectObj.appendTo('#' + args.elements.id);
            if (args.values) {
              multiSelectObj.change(multiSelectObj);
            }
          }
        }
      },
    }
  },
  watch: {
    currentClientId(newVal) {
      if (newVal !== this.$route.query.publicId) {
        this.$router.replace({query: {publicId: newVal}});
      }
    },
    contactSearchModel(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.debounce(() => this.getCaseClients(), 500);
      }
    },
    dataRange(value) {
      if(value) {
        this.dateFrom = isNaN(Date.parse(value[0])) == false ? Date.parse(value[0]) + 10800000 : ''
        this.dateTo = isNaN(Date.parse(value[1])) == false ? Date.parse(value[1]) + 97200000  : ''
      } else {
        this.dataRange = ''
        this.dateFrom = ''
        this.dateTo = ''
      }
    },
    '$route.query.page': {
      deep: true,
      handler(newVal) {
        if (newVal) {
          if (!this.isAdvancedFilterApplied) {
            this.currentPage = +newVal;
            this.getClientCalls();
          } else {
            this.advancedCurrentPage = +newVal;
            this.getAdvancedCallLogs()
          }
        } else {
          if (!this.isAdvancedFilterApplied) {
            this.currentPage = 1;
          } else {
            this.advancedCurrentPage = 1;
          }
        }
      }
    }
  },
  filters: {
    filteringLabel(label) {
      let labelsList = label.split(" ")
      labelsList = labelsList.map(phrase => {
        if (phrase === 'a' || phrase === "for" || phrase === 'Search' || phrase === 'the') {
          return
        }
        return phrase;
      });

      return labelsList.join(" ")
    }
  },
  async mounted() {
    await this.getCaseClients();
    await new Promise(resolve => setTimeout(resolve, 1000));
    await this.getFilterRules();
  },
  methods: {
    linkGen(pageNum) {
      return linkGenerator(pageNum, `/cases/${this.caseId}/messages/logs/?publicId=${this.currentClientId}`);
    },
    formatDate(date) {
      const initial = date.split(/\//);
      return [initial[1], initial[0], initial[2]].join('/')
    },
    getFilterRules() {
      this.isLoad = false;
      this.$load(async() => {
        await this.$api.calls.getFilterRules(this.caseId, this.currentClientId).then((res) => {
          this.initialData = res.data.body;
          this.isLoad = true;
        })
      })
    },
    stringModify(str) {
      return str.split('').map((letter, idx) => {
        return letter.toUpperCase() === letter
          ? `${idx !== 0 ? ' ' : ''}${letter.toLowerCase()}`
          : letter;
      }).join('');
    },
    openSaveFilterPatternPopup() {
      this.$refs.saveFilterPattern.openPopups();
    },
    async saveFilterPattern(filterName) {
      await this.$load(async() => {
        try {
          await this.$api.cases.saveFilterPattern(this.caseId, {
            name: filterName,
            value: this.stringifiedRules,
          });
          this.stringifiedRules = '';
        } catch (error) {
          this.$emit('show-error', error.response.data.errors.message.toString());
        }
      })
    },
    // left list
    getCaseClients() {
      this.$load(async () => {
        await this.$api.cases.getCasesClients(this.caseId,{ keySearch: this.contactSearchModel })
          .then(async (res) => {
            this.users = res.data.body.content;
            this.currentClientId = this.users[0].publicId;
            await this.getClientCalls();
        }, (error) => {
            if (error.response) {
              this.$emit('show-error', error.response.data.errors.message[0]);
            } else {
              this.$emit('show-error', error.data.errors.message[0]);
            }
          });
      })
    },
    changeContact(item) {
      if (item.publicId && item.publicId !== this.currentClientId) {
        this.currentClientId = item.publicId;
        this.callLogsList = [];
        this.getClientCalls();
      }
    },
    getClientCalls() {
      this.$load(async () => {
        await this.$api.calls.getClientCalls(this.caseId, this.currentClientId, {
          page: this.currentPage,
          size: this.pageSize,
        }).then((res) => {
          this.callLogsList = res.data.body.content;
          this.totalPages = res.data.body.totalPages;
          this.$refs.mainPagination.switchToPage(+this.currentPage);
        })
      })
    },
    resetFilters() {
      localStorage.removeItem('rules');
      this.stringifiedRules = '';
      this.isAdvancedFilter = true;
      this.isAdvancedFilterApplied = false;
      this.filtersAmountApplied = 0;
      this.queryFilterParams = null;
      this.filteredTags = [];
      this.isOpenedFilterList = true;
      this.resultCount = 0;
      this.reset();
      this.$refs.querybuilder.ej2Instances.setRules(this.defaultImportRules)
    },
    reset() {
      this.$refs.querybuilder.ej2Instances.reset()
      this.$refs.querybuilder.ej2Instances.levelColl = []
    },
    changeFilterView() {
      this.isAdvancedFilter = !this.isAdvancedFilter;
    },
    async attachFilter(filterId) {
      await this.$load(async () => {
        const filter = await this.$api.cases.getFilterPattern(this.caseId, filterId);
        this.filterTypeSaved = false;
        this.setParsedRules(JSON.parse(filter.data.body.value));
      });
    },
    setParsedRules(parsedRules) {
      this.importRules = {};
      this.isAdvancedFilterApplied = true;

      setTimeout(() => {
        const { rules } = parsedRules.rules;
        const lastRuleRules = rules[rules.length - 1].rules;

        this.setSavedRules(!lastRuleRules || lastRuleRules && lastRuleRules.length ? rules : rules.reverse());
        this.queryFilterParams = this.getQueryParams(true);

        this.filter(true);
        this.filter();
      }, 1000);
    },
    setSavedRules(rules) {
      rules.forEach(rule => {
        if (rule.rules && rule.rules.length) {
          this.addGroup(false, {'condition': rule.condition || 'and', 'rules': [{}]});
          this.setSavedRules(rule.rules);
        } else if (rule.value) {
          this.addRule(rule);
        }
      });
    },
    ruleChanged(event) {
      const rules = [];

      this.$refs.querybuilder.ej2Instances.rule.properties.rules.forEach(rule => {
        if (rule.rules) {
          if (!rule.rules.length) {
            return;
          }

          rules.push(this.checkIsNestedRulesNotEmpty(rule.rules));
          return;
        }

        rules.push(rule.value && !!rule.value.length);
      });

      this.emptyRule = rules.some(rule => rule !== undefined && !rule);

      if (!this.emptyRule || (!this.emptyRule && event.type === 'condition')) {
        this.debounce(this.changedQueryBuilder, 1000, event)
      }
    },
    changedQueryBuilder(e) {
      if (e.type === 'insertRule') {
        return;
      }

      this.queryFilterParams = this.getQueryParams();

      if (this.isValidQueryFilterParams) {
        this.resultCount = 0
        return;
      }

      if (e.type === 'deleteGroup') {
        const groupID = e.groupID.split('group').join('');

        this.queryFilterParams.splice(groupID, 1);
      }

      if (types.some(type => type === e.type)) {
        this.filter(true);
      }
    },
    getQueryParams(setId = false) {
      this.callLogsFilters = this.$refs.querybuilder.ej2Instances.getValidRules(this.$refs.querybuilder.ej2Instances.rule);
      return this.flatten(this.callLogsFilters, setId);
    },
    flatten(conditionsResult, setId) {
      let conditionsResultArray = this.flattenRules(conditionsResult)

      conditionsResultArray.forEach((item) => {
        if (item.condition === null) {
          item.condition = 'and'
        }
        if(item.rules.length) {
          item.rules.forEach((rule) => {
            if (setId) {
              rule.id = Math.random().toString(36).substr(2, 9);
            }
            if(rule.field !== 'date') {
              if (Array.isArray(rule.value)) {
                rule.value = rule.value.join(';')
              }
            }
          })
        }
      })

      return conditionsResultArray
    },
    flattenRules(rootRule) {
      if (!this.isCondition(rootRule) || !this.hasRuleset(rootRule)) return;

      const flat = (currentCondition) => {
        if (!this.hasRuleset(currentCondition)) return;

        // Filtering current ruleset to find inner conditions with non-empty rulesets;
        const nestedConditions = currentCondition.rules.filter(
          (item) => this.isCondition(item) && this.hasRuleset(item)
        );

        return [
          {
            ...currentCondition,
            // We exclude inner conditions from the ruleset and leave only the descriptions;
            rules: currentCondition.rules.filter((item) => !this.isCondition(item)),
          },
          ...nestedConditions.reduce(
            // We recursively call this function with all of the nested conditions;
            (acc, condition) => [...acc, ...flat(condition)],
            []
          ),
        ];
      };

      return flat(rootRule);
    },
    hasRuleset(condition) {
      return !!condition.rules && !!condition.rules.length
    },
    isCondition(item) {
      return item.condition !== undefined
    },
    getAdvancedResultAmount() {
      if (this.queryFilterParams.length) {
        this.$load(() => {
          this.$api.calls.getClientCallsAdvanced(this.caseId, this.currentClientId, this.queryFilterParams, 1, this.CallLogsAdvancedSize, true)
            .then(res => {
              this.resultCount = res.data.body.totalElements;
            })
        });
      }
    },
    addRule(rule) {
      let count = Object.keys(this.$refs.querybuilder.ej2Instances.levelColl)[Object.keys(this.$refs.querybuilder.ej2Instances.levelColl).length - 1].slice(-1)
      this.$refs.querybuilder.ej2Instances.addRules([rule || { field: 'callerName' }], `group${count}`);
    },
    addGroup(_, group) {
      let count = Object.keys(this.$refs.querybuilder.ej2Instances.levelColl)[Object.keys(this.$refs.querybuilder.ej2Instances.levelColl).length - 1].slice(-1)
      this.$refs.querybuilder.ej2Instances.addGroups([group || {'condition': "and",'rules': [{}, { field: 'callerName' }]}], `group${count}`);

      document.querySelectorAll('.e-group-container').forEach(container => {
        const { classList } = container.previousSibling;

        if (classList && !classList.contains('last-rule-container')) {
          container.previousSibling.classList.add('last-rule-container');
        }
      })
    },
    filter(getResultCount) {
      if (!getResultCount) {
        this.stringifiedRules = JSON.stringify({
          clientId: this.currentClientId,
          rules: this.$refs.querybuilder.ej2Instances.rule.properties
        });

        localStorage.setItem('rules', this.stringifiedRules);
        this.queryFilterParams = this.getQueryParams(true);
      }

      this.filtersAmountApplied = this.queryFilterParams.reduce((a, b) => a + b.rules.length, 0)
      this.queryFilterParams = this.queryFilterParams.filter(param => param.rules.length);

      this.queryFilterParams.forEach(item => {
        if(item.rules.length) {
          item.rules.forEach((el, i) => {
            if(!el.value) {
              item.rules.splice(i, 1);
              return;
            }
            if(el.field === 'date') {

              if(el.value.length) {
                let dateObj = {
                  field: "date",
                  label: "Date",
                  operator: "gth",
                  type: "date",
                  value: ""
                }
                let dateFromObj = Object.assign({}, dateObj)
                let dateToObj = Object.assign({}, dateObj)
                let dateFrom = el.value[0].split('/')
                let dateTo = el.value[1].split('/')
                let dateFromTime = `${dateFrom[2]}-${dateFrom[1]}-${dateFrom[0]}`
                let dateToTime = `${dateTo[2]}-${dateTo[1]}-${dateTo[0]}`
                dateFrom = moment(dateFromTime).valueOf()
                dateTo = moment(dateToTime).endOf('day').valueOf()
                dateFromObj.value = dateFrom
                dateFromObj.operator = 'gth'
                dateToObj.value = dateTo
                dateToObj.operator = 'lth'
                dateFromObj.initialValue = dateFromTime
                dateToObj.initialValue = dateToTime
                item.rules.push(dateFromObj)
                item.rules.push(dateToObj)
                item.rules.splice(i, 1);
              }
            }
          })
        }
      })

      this.filterData = JSON.stringify(this.callLogsFilters, null, 4)
      this.advancedCurrentPage = 1;

      if (getResultCount) {
        this.getAdvancedResultAmount();
      } else {
        this.filteredTags = [];
        this.isAdvancedFilterApplied = true;
        this.isAdvancedFilter = false;
        this.defaultQueryFilterParams = this.queryFilterParams;
        this.filterTags(this.queryFilterParams);
        this.debounce(() => this.getAdvancedCallLogs(), 0);
      }
    },
    filterTags(rules) {
      rules.forEach(rule => {
        rule.rules ? this.filterTags(rule.rules) : this.filteredTags.push(rule);
      })
    },
    removeFilter(disabledTag) {
      let areThereRules = false;

      if (disabledTag.removed) {
        const disabledFilterId = this.disabledFiltersId.findIndex(el => el === disabledTag.id);

        this.disabledFiltersId.splice(disabledFilterId, 1);
      } else {
        this.disabledFiltersId.push(disabledTag.id);
      }

      disabledTag.removed = !disabledTag.removed;

      this.queryFilterParams = this.defaultQueryFilterParams
        .map(parameter => {
          const rules = parameter.rules.filter(rule => {
            for (let i = 0; i < this.disabledFiltersId.length; i++) {
              if (rule.id === this.disabledFiltersId[i]) {
                return false;
              }
            }

            return true;
          });

          if (rules.length) {
            areThereRules = true;
          }

          return {
            ...parameter,
            rules,
          };
        })
        .filter(queryParam => !!queryParam.rules.length);

      if (areThereRules) {
        this.advancedCurrentPage = 1;
        this.debounce(() => {
          this.getAdvancedCallLogs();
        }, 1000);
      } else {
        this.resetFilters();
        this.currentPage = 1;
        this.getClientCalls();
      }
    },
    getAdvancedCallLogs() {
      if (this.queryFilterParams) {
        this.$load(() => {
          this.$api.calls.getClientCallsAdvanced(
            this.caseId,
            this.currentClientId,
            this.queryFilterParams,
            this.advancedCurrentPage,
            this.CallLogsAdvancedSize,
            false
          )
            .then((res) => {
            this.advancedCallLogsList = res.data.body.content;
            this.totalPagesAdvanced = res.data.body.totalPages;
            this.debounce(() => this.$refs.advancedPagination.switchToPage(+this.advancedCurrentPage), 300);
          }, (error) => {
            if (error.response) {
              this.$emit('show-error', error.response.data.errors.message[0]);
            } else {
              this.$emit('show-error', error.data.errors.message[0]);
            }
          })
        })
      }
    },
  },
};
</script>

<style scoped>
.calls-section {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 27px 55px 27px 35px;
}

.filter-content {
  display: flex;
  width: 100%;
}

.nav-chat {
  padding-top: 120px;
  width: 381px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #D7DCE6;
}

.tab-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  min-width: 310px;
  width: auto;
  min-height: 35px;
  margin-right: 20px;
  border-radius: 79px;
  border: 1px solid var(--case-btn-border-color);
}

.tab-btn {
  margin: -1px 0 -1px 0;
  width: 50%;
  max-height: 35px;
  padding: 10px 30px;
  border: 1px solid transparent;
  outline: none;
  background-color: transparent;
  border-radius: 79px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  transition: 0.3s all;
}

.tab-btn.active {
  border-color: var(--accent-color);
}

.tab-btn-text {
  display: block;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  color: var(--light-gray);
  transition: 0.3s all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tab-btn.active .tab-btn-text,
.tab-btn:hover .tab-btn-text {
  color: var(--accent-color);
}

.profile-nav {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin-top: 0;
  height: calc(700px + 130px)
}

.profile-nav li {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all .2s;
}

.profile-nav li:not(:last-child) {
  margin-bottom: 0;
}

.profile-nav li a {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  text-decoration: none;
  padding: 10px 10px 10px 20px;
  cursor: pointer;
}

.profile-nav li a:hover {
  color: var(--secondary-black-color);
  background-color: rgba(28, 202, 205, 0.05);
}

.profile-nav li a.active {
  color: var(--secondary-black-color);
  background-color: rgba(28, 202, 205, 0.05);
}

.contacts-name {
  width: calc(100% - 85px);
  display: flex;
  align-items: center;
}

.img-item {
  width: 24px;
  height: 24px;
  overflow: hidden;
  border: 1px solid var(--white-color);
  border-radius: 50%;
  margin-right: 8px;
}

.img-item img,
.img-item svg {
  width: 100%;
  height: 100%;
}

.img-item img {
  object-fit: cover;
}

.holder-contacts {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.span-name {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  width: calc(100% - 42px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--secondary-black-color);
}

.search {
  border-radius: 79px;
  border: 1px solid var(--input-placeholder-value-color);
  padding: 0 0 0 44px;
  height: 35px;
  width: 100%;
  outline: none;
}

.dashboard-search {
  position: relative;
  width: auto;
  margin: 0 10px 20px 20px;
}

.search::placeholder {
  color: var(--light-gray);
  font-size: 14px;
}

.dashboard-search svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
}

.loader-bg {
  height: 100%;
  transform: none;
  position: static;
}

.message-time {
  text-align: right;
  font-weight: 500;
  font-size: 11px;
  line-height: 19px;
  color: var(--secondary-text-color);
  width: 75px;
  margin-left: 10px;
}

.device-list-wrapper {
  margin: 5px 0 0 22px;
  padding: 0 0 0 32px;
}

.device-list-wrapper p {
  font-size: 12px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
  color: var(--secondary-text-color);
}

.advanced-filter .nav-chat {
  margin-top: 0;
}

.advanced-filter .calls-list {
  margin-top: 30px;
}

.simple-filter .top-bar {
  padding: 30px 24px;
}

.calls-list {
  width: calc(100% - 283px);
  margin-top: 120px;
}

.calls-list-advanced {
  width: 100%;
  min-height: 940px;
  overflow: auto;
  margin-top: 20px;
}

.top-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  padding: 28px 22px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  background: #FFFFFF;
  box-shadow: 0 4px 10px rgba(77, 106, 147, 0.15);
}

.advanced-filter .top-bar {
  position: static;
  width: 100%;
}

.open.advanced-filter .top-bar {
  padding-bottom: 35px;
}

.advanced-change-button-wrapper {
  display: flex;
}

.settings-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.calls-section.open .settings-wrapper {
  padding-bottom: 28px;
  border-bottom: 0.5px solid var(--case-filters-border-color);
}

.filter-types {
  border-radius: 79px;
  outline: 1px solid var(--case-btn-border-color);
  width: fit-content;
  min-height: 35px;
}

.filter-type {
  height: 35px;
  position: relative;
  z-index: 2;
  transition: outline 0.3s, color 0.3s;
  border: none;
  border-radius: 79px;
  outline: 1px solid transparent;
  width: 144px;
  padding: 5px 16px;
  background-color: transparent;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  color: var(--light-gray);
}

.filter-type.selected {
  outline: 1px solid var(--accent-color);
  color: var(--accent-color);
}

.filter-type:hover {
  color: var(--accent-color);
}

.reset-filters {
  background-color: transparent;
  display: flex;
  align-items: center;
  border: none;
  margin-right: 23px;
  padding: 0;
  font-weight: 500;
  font-size: 14px;
  color: var(--secondary-black-color);
}

.reset-filters:disabled path {
  fill: var(--case-btn-border-color);
}

.reset-filters:disabled {
  cursor: not-allowed;
}

.reset-filters svg {
  margin-right: 10px;
}

.reset-filters:not(:disabled):hover {
  text-decoration: underline;
}

.add-to-favorites {
  margin-right: 23px;
}

.advanced-change-button {
  height: 30px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  padding: 0;
  background-color: unset;
}

.advanced-change-button span {
  margin: 0 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #373C44;
}

.advanced-change-button:hover span {
  text-decoration: underline;
}

.advanced-mode {
  fill: var(--accent-color);
}

.advanced-mode-arrow {
  transform: rotate(180deg);
}

/* end top bar */

/* for view group */

.empty-calls-wrap {
  text-align: center;
  margin-top: 45px;
}

label {
  display: block;
  margin-bottom: 5px;
}

.advanced-filter-wrap {
  width: 100%;
  margin-top: 20px;
  position: relative;
}

.control-tags {
  margin-bottom: 0;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.filter-tag {
  display: flex;
  align-items: center;
  width: max-content;
  padding: 6px 12px;
  border-radius: 20px;
  background-color: var(--accent-color);
  font-size: 13px;
  color: var(--white-color);
}

.filter-tag button {
  display: flex;
  margin-right: 8px;
  border: navajowhite;
  padding: 0;
  background-color: unset;
}

.filter-tag.removed path {
  fill: #ff9537;
}

.filter-tag svg {
  width: 18px;
  height: 18px;
}

.filter-tag path {
  transition: fill 0.3s;
}

.filter-tag strong {
  text-transform: capitalize;
}

.filter-tags-close {
  display: flex;
  align-items: center;
}

.filter-tags-arrow {
  transition: all 0.3s;
}

.filtering-name b {
  color: red;
}

.empty-logs-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 250px auto;
  max-width: 150px;
  width: 100%;
  gap: 40px;
}

.empty-logs-list span {
  font-size: 16px;
  font-weight: 500;
  line-height: 25.6px;
  text-align: center;
  color: var(--light-gray);
}
</style>