<template>
  <tbody>
    <tr v-for="(manager, index) in managerList"
      :key="index"
  >
    <td>
      <div class="user">
        <div class="user-wrapper">
          <div class="user-photo">
            <img
                v-if="manager.avatar"
                :src="manager.avatar"
                onerror="this.src='https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/girl_female_woman_avatar-512.png';"
                alt=""
            >
            <img
                v-else
                src="https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/girl_female_woman_avatar-512.png"
                alt=""
            >
          </div>
          <div class="user-about">
            <h3
                @click="$router.push({ name: 'User Profile', params: { slug: manager.publicId} })"
                class="user-name"> {{
                (manager.firstName.toLowerCase()[0].toUpperCase() +
                    manager.firstName.toLowerCase().slice(1)) + ' ' + (manager.lastName.toLowerCase()[0].toUpperCase() +
                    manager.lastName.toLowerCase().slice(1))
              }}
            </h3>
          </div>
        </div>
      </div>
    </td>
    <td>
      <div class="user-rank">
                <span v-for="(role, index) in manager.roles"
                      :key="index">
                  {{ getRoleNameFE(role) }} &nbsp;
                </span>
        <!--                    <span class="rank-text" :class="manager.toLowerCase()">userRank </span>-->
      </div>
    </td>
    <td>
      <div class="user-case">
        {{ manager.caseCount }} cases
        <!--              <span class="user-case-text" v-if="manager.cases.length > 1">{{manager.cases.length}} cases</span>-->
        <!--              <span v-else class="user-case-text">{{item.cases[0]}}</span>-->
      </div>
    </td>
    <td>
      <div class="assign">
        <p v-if="manager.currentCaseManager"
           class="disabled-text">
          Current Case Manager
        </p>
        <button
            v-else
            :id="`assign_new_case_menager_${index}`"
            @click="assignNewManager(manager)"
            class="assign-btn">
          <span class="assign-btn-text">Assign Case Manager</span>
        </button>
      </div>
    </td>
  </tr>
  </tbody>
</template>

<script>
import '@/assets/css/table.css';
import getRoleName from "@/mixins/getRoleName";

export default {
  name: "CaseAssignManagerItem",
  props: {
    managerList: {
      type: Array,
    },
    caseManager: {
      type: Object
    }
  },
  mixins: [getRoleName],
  methods: {
    assignNewManager(item) {
      this.$emit('assign-manager', item)
    }
  },
}
</script>

<style scoped>

.assign-manager-item {
  padding: 14px 41px 14px 26px;
  margin: 12px 0 0 0;
  display: flex;
  justify-content: flex-start;
  transition: .5s all;
}

.assign-manager-item.current-case-manager {
  opacity: 0.5;
}

.assign-manager-item.current-case-manager .assign-btn {
  border-color: transparent;
}

.assign-manager-item:hover {
  box-shadow: 0px 4px 20px rgba(77, 106, 147, 0.15);
  border-radius: 5px;
  background-color: var(--white-color);
}

.user-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
}

.user-about {
  padding: 0 10px 0 10px;
  width: 100%;
  max-width: calc(100% - 30px);
}

.user-about h3 {
  cursor: pointer;
}

.user-name {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: var(--gray-color);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.user-mail {
  display: block;
  text-decoration: none;
  color: var(--light-gray);
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.user-photo {
  min-width: 30px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: initial;
  border-radius: 50%;
  overflow: hidden;
  /*margin: 0 10px 0 0;*/
}

.user-photo img {
  object-fit: cover;
}

.rank-text {
  width: 100%;
  max-width: 103px;
  text-align: center;
  padding: 11px 20px;
  display: block;
  border-radius: 20px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.user-case-text {
  padding: 0 5px 0 0;
  display: block;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  color: var(--secondary-black-color);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.assign {
  display: flex;
  justify-content: flex-end;
}

.assign-btn {
  width: 100%;
  max-width: 186px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: .5px solid var(--case-btn-border-color);
  border-radius: 79px;
  outline: none;
  background-color: var(--white-color);
  padding: 9px 15px;
  transition: .5s all;
}

.assign-btn-text {
  display: block;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: var(--secondary-text-color);
  transition: .5s all;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.assign-btn:hover {
  border-color: var(--accent-color);
}

.assign-btn:hover .assign-btn-text {
  color: var(--accent-color);
}

.admin {
  background-color: var(--admin-background);
  color: var(--admin-color);
}

.lawyer {
  background-color: var(--lawyer-background);
  color: var(--lawyer-color);
}

.disabled-text {
  color: var(--light-gray);
}

</style>