<template>
  <div class="case-managment-bar">
    <div class="case-user-info">
      <div class="case-user">
        <div class="user-photo">
          <img
              v-if="caseManager.avatar"
              :src="caseManager.avatar"
              onerror="this.src='https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/girl_female_woman_avatar-512.png'"
              alt="avatar"
          >
          <img
              v-else
              src="https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/girl_female_woman_avatar-512.png"
              alt="avatar"
          >
        </div>
        <span
            :id="`goto_user_profile_${caseManager.publicId}`"
            @click="$router.push({ name: 'User Profile', params: { slug: caseManager.publicId} })"
            class="user-name"
        >
          {{ fullName }}
        </span>
        <div
            class="span-roles-wrap">
          <span
              v-for="role in caseManager.caseManagerRoles"
              :key="role"
              :class="'user-rank-' + role.toLowerCase()"
          >
            {{ getRoleNameFE(role) }}
          </span>
        </div>
        <div class="user-cases-count">
          <div class="cases-count-icon">
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M5.33333 3.16667V3.7H1.6C1.17565 3.7 0.768687 3.86857 0.468629 4.16863C0.168571 4.46869 0 4.87565 0 5.3L0 14.9C0 15.3243 0.168571 15.7313 0.468629 16.0314C0.768687 16.3314 1.17565 16.5 1.6 16.5H14.4C14.8243 16.5 15.2313 16.3314 15.5314 16.0314C15.8314 15.7313 16 15.3243 16 14.9V5.3C16 4.87565 15.8314 4.46869 15.5314 4.16863C15.2313 3.86857 14.8243 3.7 14.4 3.7H10.6667V3.16667C10.6667 2.45942 10.3857 1.78115 9.88562 1.28105C9.38552 0.780952 8.70724 0.5 8 0.5C7.29276 0.5 6.61448 0.780952 6.11438 1.28105C5.61428 1.78115 5.33333 2.45942 5.33333 3.16667ZM8 1.56667C7.57565 1.56667 7.16869 1.73524 6.86863 2.0353C6.56857 2.33535 6.4 2.74232 6.4 3.16667V3.7H9.6V3.16667C9.6 2.74232 9.43143 2.33535 9.13137 2.0353C8.83131 1.73524 8.42435 1.56667 8 1.56667ZM0.704 8.35813C2.69083 10.1926 5.2958 11.2113 8 11.2113C10.7042 11.2113 13.3092 10.1926 15.296 8.35813L14.5707 7.5752C12.7811 9.22672 10.4352 10.1438 8 10.1438C5.56481 10.1438 3.21892 9.22672 1.42933 7.5752L0.704 8.35813Z"
                    fill="#FF9537"/>
            </svg>
          </div>
          <span class="cases-count">{{ caseManager.caseManagerCases }} Cases</span>
        </div>
        <div class="user-phone">
          <div class="user-phone-icon">
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5ZM5.125 3.67773C5.28983 3.66801 5.43881 3.76653 5.55176 3.9414L6.64551 6.01561C6.76067 6.2614 6.69523 6.52457 6.52344 6.70019L6.02247 7.20116C5.99155 7.24353 5.9712 7.29125 5.97071 7.34373C6.16283 8.0874 6.7456 8.77337 7.25979 9.24511C7.77395 9.71684 8.32657 10.3555 9.04395 10.5068C9.13261 10.5316 9.24124 10.5404 9.30469 10.4815L9.88672 9.88868C10.0876 9.7364 10.3783 9.6626 10.5928 9.78712H10.6025L12.5761 10.9522C12.8659 11.1337 12.8959 11.4847 12.6885 11.6983L11.3291 13.0469C11.1284 13.2527 10.8617 13.322 10.6026 13.3223C9.45659 13.2879 8.37379 12.7255 7.4844 12.1475C6.02453 11.0854 4.68543 9.76819 3.84473 8.17676C3.52231 7.50943 3.14355 6.65796 3.17969 5.91311C3.18292 5.63289 3.25873 5.35837 3.45607 5.17776L4.81544 3.81839C4.92133 3.72828 5.02609 3.68357 5.125 3.67773Z" fill="#9B7EDE"/>
            </svg>

          </div>
          <a :href="`tel:${caseManager.caseManagerPhone}`" class="user-phone-number">
            {{ caseManager.caseManagerPhone }}
          </a>
        </div>
      </div>
      <div class="case-btns-wrap">
        <div id="disabled-new-case-manager" ref="disabledNewCaseManager">
          <router-link
              :disabled="!checkPermission(['change_manager'])"
              :to="{ name: 'Assign new Case Manager for'}"
              tag="button"
              :id="`goto_assign_manager_in_${$route.params.slug}_case`"
              class="btn manage-btn"
          >
            <span class="manage-btn-text">New Case Manager</span>
            <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M16.6125 11.8647C16.3649 11.8647 16.1643 12.0664 16.1643 12.3153V16.316C16.1634 17.0623 15.5619 17.6669 14.8194 17.6678H2.24136C1.49891 17.6669 0.897421 17.0623 0.896546 16.316V4.57412C0.897421 3.82801 1.49891 3.22324 2.24136 3.22236H6.22154C6.46914 3.22236 6.66981 3.02065 6.66981 2.77177C6.66981 2.52289 6.46914 2.32118 6.22154 2.32118H2.24136C1.00406 2.32259 0.00140085 3.33043 0 4.57412V16.316C0.00140085 17.5597 1.00406 18.5675 2.24136 18.5689H14.8194C16.0568 18.5675 17.0594 17.5597 17.0608 16.316V12.3153C17.0608 12.0664 16.8601 11.8647 16.6125 11.8647Z"
                  fill="#082655"/>
              <path
                  d="M16.8837 1.162C16.0952 0.371355 14.819 0.371355 14.0307 1.162L6.03302 9.20094C5.97838 9.25604 5.93863 9.32433 5.91797 9.39913L4.86646 13.2152C4.8232 13.3717 4.86716 13.5393 4.98133 13.6542C5.09567 13.769 5.26237 13.8131 5.41804 13.7698L9.21453 12.7127C9.28912 12.6919 9.35724 12.652 9.41205 12.5969L9.43744 12.5712L17.4093 4.55813C18.1971 3.76643 18.1971 2.48278 17.4093 1.69109L16.8837 1.162ZM6.58793 10.3445L8.27403 12.0395L5.94161 12.689L6.58793 10.3445ZM9.12067 12.253V12.2526L9.41292 12.5464L9.12067 12.253ZM9.12067 11.6158L7.00959 9.49383L13.5551 2.91454L15.666 5.03617L9.12067 11.6158ZM16.7755 3.92097L16.2999 4.39901L14.1891 2.2772L14.6647 1.79916C15.1025 1.36001 15.8115 1.36001 16.2494 1.79916L16.7755 2.32842C17.2132 2.7681 17.2132 3.48129 16.7755 3.92097Z"
                  fill="#082655"/>
            </svg>
          </router-link>
        </div>
        <tooltip v-if="!checkPermission(['change_manager'])" :target-ref="$refs.disabledNewCaseManager">
          <span>To access the assignment of a new case manager - you need to have the 'Change manager' permission  on that case</span>
        </tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import checkPermission from "../../../utils/permission";
import Tooltip from "@/components/common/Tooltip.vue";
import getRoleName from "@/mixins/getRoleName";

export default {
  name: "CaseManagmentBar",
  components: {Tooltip},
  props: {
    caseManager: {
      type: Object,
    },
  },
  mixins: [getRoleName],
  computed: {
    fullName() {
      const { firstName, lastName } = this.caseManager;

      if (firstName && lastName) {
        return  (firstName[0].toUpperCase() + firstName.slice(1).toLowerCase()) + ' ' + (lastName[0].toUpperCase() + lastName.slice(1).toLowerCase());
      }

      return '';
    }
  },
  methods: {
    checkPermission,
    imgDef(e) {
      e.target.src = '../../../assets/img/ava-default.svg'
    },
  }
}
</script>

<style scoped>

.span-roles-wrap {
  display: inline-block;
  min-width: 95px;
  padding: 8px 8px;
  margin-right: 20px;
  text-align: center;
  border-radius: 20px;
  min-width: 135px;
  border: 1px solid #D7DCE6;
}

.case-managment-bar {
  padding: 63px 0;
  border-bottom: 1px solid var(--input-placeholder-value-color);
}

.case-user-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.case-user {
  width: calc(100% - 270px);
  max-width: 757px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.case-managment-bar .case-user {
  max-width: initial;
}

.case-btns-wrap {
  width: auto;
  max-width: 250px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.user-photo {
  min-width: 30px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: initial;
  border-radius: 50%;
  overflow: hidden;
}

.user-photo img {
  object-fit: cover;
}

.user-photo svg {
  max-width: 100%;
  max-height: 100%;
}

.user-name {
  width: 100%;
  cursor: pointer;
  max-width: 27%;
  display: block;
  padding: 0 0 0 10px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  color: var(--secondary-text-color);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.user-rank {
  margin-right: 20px;
  padding: 11px 16px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 20px;
  width: 100%;
  max-width: 100px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.user-cases-count {
  margin-right: 20px;
  width: 100%;
  max-width: 118px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--cases-count);
  padding: 10px 20px 9px 20px;
  border-radius: 20px;
}

.cases-count-icon {
  width: 16px;
  height: 16px;
}

.cases-count-icon svg {
  max-width: 100%;
  max-height: 100%;
}

.cases-count {
  font-size: 12px;
  font-weight: 500;
  color: var(--secondary-text-color);
  max-width: 75%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.user-phone {
  padding: 9px 20px;
  width: 100%;
  max-width: 172px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #F5F3FC;
  border-radius: 20px;
}

.user-phone-icon {
  width: 16px;
  height: 16px;
}

.user-phone-icon svg {
  max-width: 100%;
  max-height: 100%;
}

.user-phone-number {
  display: block;
  text-decoration: none;
  font-size: 12px;
  font-weight: 500;
  color: var(--secondary-text-color);
  max-width: calc(100% - 16px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.manage-btn {
  border-color: var(--case-btn-border-color);
  background-color: var(--white-color);
  color: var(--secondary-text-color);
  padding: 7px 32px;
  width: 100%;
  max-width: 222px;
  justify-content: space-between;
}

.manage-btn:hover {
  border-color: var(--accent-color);
  color: var(--accent-color);
}

.manage-btn svg {
  width: 18px;
  height: 18px;
  margin-left: 5px;
}

.manage-btn svg path {
  fill: var(--accent-color);
}

.manage-btn-text {
  display: block;
  font-size: 14px;
  font-weight: 500;
  max-width: calc(100% - 18px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.deactivate-btn {
  min-width: unset;
  margin: 0 0 0 20px;
  border-color: var(--case-btn-border-color);
  background-color: var(--white-color);
  color: var(--secondary-text-color);
  width: 100%;
  padding: 4px 17px 5px 17px;
  max-width: 140px;
  justify-content: space-between;
}

.deactivate-btn:hover {
  border-color: var(--accent-color);
  color: var(--accent-color);
}

.deactivate-btn svg {
  width: 24px;
  height: 24px;
}

.deactivate-btn svg path {
  fill: var(--accent-color);
}
</style>
