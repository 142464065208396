import {userService} from '../services';
// import LocalStorageService from "../services/LocalStorageService";
import router from '../router'

// LocalstorageService
// const localStorageService = LocalStorageService.getService();

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
    ? {status: {loggedIn: true}}
    : {status: {loggedIn: false}, user: null, error: null};

export const authentication = {
    namespaced: true,
    state: initialState,
    actions: {
        login({dispatch, commit}, token) {
            // localStorage.clear()
            commit('loginRequest', token);
            userService.login(token)
                .then(
                    user => {
                        commit('loginSuccess', user);
                        router.push({name: 'Profile'});
                    },
                    error => {
                        commit('loginFailure', error.response.data.errors[0].message);
                        dispatch('alert/error', error.response.data.errors[0].message, {root: true});
                        // console.dir(error.response.data.errors[0].message)
                    }
                );
        },
        logout({commit}) {
            userService.logout();
            commit('logout');
            router.push({name: 'Login'});
        }
    },
    mutations: {
        loginRequest(state, user) {
            state.status = {loggedIn: true};
            state.user = user;
        },
        loginSuccess(state, user) {
            state.status = {loggedIn: true};
            state.user = user;
        },
        loginFailure(state) {
            state.status = {loggedIn: false};
            state.user = null;
        },
        logout(state) {
            state.status = {loggedIn: false};
            state.user = null;
        }
    }
}
