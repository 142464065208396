<template>
  <div>
    <date-picker
        v-model="time1"
        valueType="format"
        type="month"
        :placeholder="placeholderString"
        :format="format"
        :editable="editable"
        :class="customClass"
        :multiple="multiple"
        :range-separator="rangeSeparator"
    >
      <template #icon-calendar v-if="customClass === 'case-actions-filter'">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M20.1453 10.9137C23.3453 12.8528 24.3654 17.0156 22.4238 20.2115C21.2063 22.2157 19.0373 23.448 16.6903 23.4688C14.5906 23.473 12.6115 22.4896 11.3484 20.8145C10.422 19.644 9.92492 18.1921 9.93978 16.7002C9.95443 12.987 12.9724 9.98461 16.6903 9.98461C17.9052 9.97435 19.0998 10.2955 20.1453 10.9137Z"
              fill="#082655"/>
          <path
              d="M20.1444 7.72852V10.9138C19.0989 10.2957 17.9043 9.97441 16.6895 9.98472C12.9716 9.98467 9.9536 12.9871 9.93895 16.7003C9.9241 18.1922 10.4212 19.6441 11.3475 20.8146H2.12537C1.24472 20.8146 0.530762 20.1015 0.530762 19.222V7.72857H20.1444V7.72852Z"
              fill="white"/>
          <path
              d="M20.1444 3.87973V7.72856H0.530762V3.87973C0.530762 3.00017 1.24472 2.28711 2.12537 2.28711H4.30468V3.93283H6.82949V2.28711H13.8192V3.93283H16.344V2.28711H18.5499C19.4305 2.28711 20.1444 3.00012 20.1444 3.87973Z"
              fill="#082655"/>
          <path d="M16.3446 2.28708V3.9328H13.8198V2.28708V0.455566H16.3446V2.28708Z" fill="#082655"/>
          <path d="M6.82994 2.28708V3.9328H4.30518V2.28708V0.455566H6.82994V2.28708Z" fill="#082655"/>
          <path
              d="M11.3217 21.3723H2.12615C0.951924 21.3723 0 20.4216 0 19.2488V3.85348C0 2.68072 0.951924 1.72998 2.12615 1.72998H4.30545V2.79173H2.12615C1.53901 2.79173 1.06307 3.26707 1.06307 3.85348V19.2488C1.06307 19.8352 1.53901 20.3105 2.12615 20.3105H11.3217V21.3723Z"
              fill="#082655"/>
          <path
              d="M20.756 10.675V3.85327C20.756 2.68534 19.7195 1.86248 18.5501 1.86248H16.7695V0.429186C16.8013 0.226418 16.6625 0.036301 16.4595 0.00449635C16.4213 -0.00149878 16.3824 -0.00149878 16.3443 0.00449635H13.8194C13.5271 0.00449635 13.3145 0.137202 13.3145 0.429186V1.86253H7.4676V0.429186C7.4676 0.137202 7.12209 0.00449635 6.82974 0.00449635H4.30498C4.01263 0.00449635 3.74689 0.137202 3.74689 0.429186V3.93293C3.75915 4.24021 3.99829 4.49038 4.30498 4.5169H6.82979C7.15322 4.49577 7.41846 4.25291 7.46765 3.93293V2.92428H13.3146V3.93293C13.299 4.23127 13.5217 4.48886 13.8195 4.5169H16.3444C16.6367 4.5169 16.7696 4.22492 16.7696 3.93293V2.92428H18.5502C19.1349 2.92428 19.693 3.26935 19.693 3.85332V7.17127H0.557617V8.23302H19.6929V10.0911C16.052 8.41973 11.7439 10.0128 10.0705 13.6492C9.6264 14.6142 9.40014 15.6649 9.40772 16.727C9.43666 20.7317 12.6801 23.971 16.6898 23.9999C20.7115 24.0152 23.9842 20.7714 23.9995 16.7547C24.0087 14.3128 22.7905 12.0293 20.756 10.675ZM6.40452 3.45515H4.80991V1.06624H6.40452V3.45515ZM15.7064 3.45515H14.3776V1.06624H15.7064V3.45515ZM16.6898 22.9382C14.7519 22.9342 12.9257 22.032 11.7464 20.4962C9.66217 17.7697 10.1856 13.8718 12.9155 11.7901C15.6455 9.70843 19.5482 10.2312 21.6325 12.9577C23.7167 15.6843 23.1933 19.5821 20.4634 21.6638C19.3794 22.4904 18.0535 22.9381 16.6898 22.9382Z"
              fill="#082655"/>
          <path
              d="M19.1882 17.7886H16.3445C16.051 17.7886 15.813 17.5509 15.813 17.2577V13.5416C15.813 13.2484 16.051 13.0107 16.3445 13.0107C16.6381 13.0107 16.8761 13.2484 16.8761 13.5416V16.7269H19.1882C19.4667 16.7118 19.7047 16.9251 19.7198 17.2032C19.7207 17.2214 19.7207 17.2395 19.7198 17.2577C19.7198 17.5509 19.4818 17.7886 19.1882 17.7886Z"
              fill="white"/>
        </svg>
      </template>
    </date-picker>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  name: "CustomDatepicker",
  props: {
    format: {
      type: String,
      required: false,
      default: 'format'
    },
    editable: {
      type: Boolean,
      required: false,
      default: true
    },
    customClass: {
      type: String,
      required: false,
      default: ''
    },
    placeholderString: {
      type: String,
      required: false,
      default: 'Select date'
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    rangeSeparator: {
      type: String,
      required: false,
    }
  },
  components: {DatePicker},
  data() {
    return {
      time1: null,
    };
  },
};
</script>

<style lang="scss">
//.mx-calendar-content .cell:hover {
//  color: #73879c;
//  background-color: transparent;
//}
//
//.mx-calendar-content .cell div {
//  background-color: #fff;
//  color: var(--secondary-black-color);
//  font-weight: 500;
//  font-size: 11px;
//  transition: all 0.3s;
//}
//
//.mx-calendar-content .cell:hover div {
//  background-color: var(--accent-color);
//  border-radius: 18px;
//  color: var(--white-color);
//}
//
//.mx-input {
//  box-shadow: none;
//}
//
//.mx-datepicker-popup, .mx-input {
//  border-radius: 18px;
//}
//
//.mx-btn-text {
//  font-size: 9px;
//  font-weight: 700;
//  color: var(--secondary-black-color);
//}
//
//.mx-input:hover, .mx-input:focus {
//  border-color: var(--input-placeholder-value-color);
//}
//
//.mx-datepicker {
//  width: 100%;
//}
//
//.mx-table td {
//  text-align: center;
//}
//
//.mx-calendar {
//  width: 176px;
//}
//
//.mx-calendar-content .cell.active {
//  background-color: transparent;
//}
//
//.mx-btn:hover {
//  border-color: var(--accent-color);
//  color: var(--accent-color);
//}
//
////custom classes
//
////cases actions
//.mx-datepicker.case-actions-filter .mx-input-wrapper {
//  max-width: 222px;
//}
//
//.mx-datepicker.case-actions-filter .mx-input {
//  cursor: pointer;
//  transition: .5s all;
//  border: 1px solid var(--case-btn-border-color);
//  box-shadow: none;
//  font-size: 14px;
//  font-weight: 500;
//  color: var(--light-gray);
//  padding: 4px 40px 4px 20px;
//}
//
//.mx-datepicker.case-actions-filter .mx-input::placeholder {
//  font-size: 14px;
//  font-weight: 500;
//  color: var(--light-gray);
//}
//
//.mx-datepicker.case-actions-filter .mx-icon-calendar {
//  font-size: 24px;
//  right: 16px;
//  cursor: pointer;
//}
//
////cases filter
//
//.mx-datepicker.cases-filter .mx-input {
//  cursor: pointer;
//  transition: .5s all;
//  border: 0.5px solid var(--case-btn-border-color);
//  box-shadow: none;
//  font-size: 14px;
//  color: var(--secondary-black-color);
//  font-weight: 500;
//  min-height: 48px;
//  border-radius: 25px;
//  padding: 15px 30px;
//}
//
//.mx-datepicker.cases-filter .mx-input::placeholder {
//  color: var(--light-gray);
//}
//
//.mx-datepicker.cases-filter .mx-icon-calendar, .mx-icon-clear {
//  right: 12px;
//}
</style>