<template>
  <div
    v-if="dialog"
    :class="dialog ? 'active': ''"
    class="overlay"
  >
    <div v-click-outside="closePopup" class="popup">
      <div class="popup-close" @click="closePopup">
        <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.9997 0.333496C5.54801 0.333496 0.333008 5.5485 0.333008 12.0002C0.333008 18.4518 5.54801 23.6668 11.9997 23.6668C18.4513 23.6668 23.6663 18.4518 23.6663 12.0002C23.6663 5.5485 18.4513 0.333496 11.9997 0.333496ZM17.833 16.1885L16.188 17.8335L11.9997 13.6452L7.81134 17.8335L6.16634 16.1885L10.3547 12.0002L6.16634 7.81183L7.81134 6.16683L11.9997 10.3552L16.188 6.16683L17.833 7.81183L13.6447 12.0002L17.833 16.1885Z"
            fill="#D7DCE6"/>
        </svg>
      </div>
      <div class="scroll-area">
        <div v-if="infoType === 'mobile'" class="popup-content">
          <p v-if="metaInfo.phoneNumber1">
            <span>Phone number 1</span>
            <span>{{ metaInfo.phoneNumber1 }}</span>
          </p>
          <p v-if="metaInfo.phoneNumber2">
            <span>Phone number 2</span>
            <span>{{ metaInfo.phoneNumber2 }}</span>
          </p>
          <p>
            <span>Email</span>
            <span class="email">{{ metaInfo.email }}</span>
          </p>
          <p>
            <span>Date</span>
            <span>{{ getDate(metaInfo.date) }}</span>
          </p>
          <p>
            <span>Data Type</span>
            <span>{{ metaInfo.dataType }}</span>
          </p>
          <p>
            <span>OS Source</span>
            <span>{{ metaInfo.osSource }}</span>
          </p>
          <p>
            <span>OS Version</span>
            <span>{{ metaInfo.osVersion }}</span>
          </p>
          <p>
            <span>Messenger</span>
            <span>{{ metaInfo.messenger }}</span>
          </p>
        </div>
        <div v-if="infoType === 'email'" class="popup-content">
          <p>
            <span>Case name</span>
            <span>{{ metaInfo.caseName }}</span>
          </p>
          <p>
            <span>Folder</span>
            <span>{{ metaInfo.folder }}</span>
          </p>
          <p>
            <span>Attachments count</span>
            <span>{{ metaInfo.attachmentsCount }}</span>
          </p>
          <p>
            <span>Date</span>
            <span>{{ getDate(metaInfo.date) }}</span>
          </p>
          <p>
            <span>Imported from</span>
            <span class="email">{{ metaInfo.importedFrom }}</span>
          </p>
          <p>
            <span>Sender</span>
            <span>{{ metaInfo.sender }}</span>
          </p>
          <p>
            <span>Subject</span>
            <span>{{ metaInfo.subject }}</span>
          </p>
          <p v-if="metaInfo.recipientsTo && metaInfo.recipientsTo.length">
            <span>Recipients to:</span>
            <span
              v-for="(item, index) in metaInfo.recipientsTo"
              :key="metaInfo.caseId + index"
              class="email"
            >
            {{ item }}
            <span v-if="index !== metaInfo.recipientsTo.length - 1">,</span>
          </span>
          </p>
          <p v-if="metaInfo.recipientsBcc && metaInfo.recipientsBcc.length">
            <span>Recipients bcc:</span>
            <span
              v-for="(item, index) in metaInfo.recipientsBcc"
              :key="metaInfo.caseId + index"
              class="email"
            >
            {{ item }}
            <span v-if="index !== metaInfo.recipientsBcc.length - 1">,</span>
          </span>
          </p>
          <p v-if="metaInfo.recipientsCc && metaInfo.recipientsCc.length">
            <span>Recipients cc:</span>
            <span
              v-for="(item, index) in metaInfo.recipientsCc"
              :key="metaInfo.caseId + index"
              class="email"
            >
            {{ item }}
            <span v-if="index !== metaInfo.recipientsCc.length - 1">,</span>
          </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import settings from "@/mixins/scrollSettings";
export default {
  name: 'MetaInfoPopup',
  props: {
    metaInfo: {
      type: Object,
      required: false,
    },
    infoType: {
      type: String,
      required: false,
      default: () => 'mobile'
    },
  },
  components: {
    // vueCustomScrollbar
  },
  mixins: [settings],
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    getDate(date) {
      const utc = moment.utc(date);

      return utc.format('DD/MM/YYYY') + ', ' + utc.format("HH:mm")
    },
    openPopup() {
      this.dialog = true;
    },
    closePopup() {
      this.dialog = false;
    },
  },
};
</script>
<style scoped>
.popup {
  position: relative;
  border-radius: 30px;
  width: 100%;
  max-width: 356px;
  padding: 52px 24px 34px;
  background-color: var(--white-color);
}

.popup-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 70px;
  height: 70px;
  padding: 23px;
  cursor: pointer;
}

.popup-close svg path {
  transition: .5s all;
}

.popup-close:hover svg path {
  fill: var(--accent-color);
}

.scroll-area {
  max-height: 400px;
  padding-right: 20px;
  margin-top: 15px;
  margin-bottom: 15px;
  overflow-y: auto;
}

.scroll-area::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

.scroll-area::-webkit-scrollbar-thumb {
  border-radius: 30px;
  max-height: 70px;
  background-color: var(--accent-color);
}

.scroll-area::-webkit-scrollbar-track {
  background-color: transparent;
}

.popup-content {
  margin-top: 30px;
}

.popup-content p {
  display: flex;
  justify-content: space-between;
}

.popup-content p:not(:last-child) {
  margin-bottom: 26px;
}

.popup-content p > span {
  font-weight: 500;
  line-height: 17px;
}

.popup-content p > span:not(.email) {
  text-transform: capitalize;
}

.popup-content p > span:first-child {
  font-size: 12px;
  color: var(--secondary-text-color);
}

.popup-content p > span:last-child {
  width: calc(100% - 110px);
  text-align: right;
  word-break: break-word;
  font-size: 14px;
  color: var(--light-gray);
}
</style>
