<template>
  <multiselect
      v-model="value"
      :options="options"
      :multiple="multiple"
      label="caseName"
      track-by="caseName"
      :preselect-first=false
      :placeholder="placeholder ? placeholder : 'Choose case'"
      :max-height="258"
      :close-on-select="!multiple"
      v-on:input="$emit('input', value)"
      ref="select"
      :searchable="false"
      :clear-on-select="false"
      :class="customClass"
      :maxHeight="maxHeight"
      :id="id"
      :disabled="disabledFlag"
      @close="closeMultiSelect"
  >
    <template #beforeList>
      <div class="holder-clear">
        <button
            :id="`clear_filter_role_${item}`"
            @click="selectAll(value)">Clear filter
        </button>
      </div>
    </template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  name: "CustomMultiSelect",
  components: {
    Multiselect,
  },
  props: {
    placeholder: {
      type: String,
      required: false,
    },
    isOpen: {
      type: Boolean
    },
    item: {
      type: Object
    },
    options: {
      type: Array
    },
    maxHeight: {
      type: Number,
    },
    customClass: {
      type: String,
      required: false,
      default: ''
    },
    multiple: {
      type: Boolean,
    },
    id: {
      type: String
    },
    disabledFlag: {
      type: Boolean
    }
  },

  data() {
    return {
      value: null,
    }
  },

  watch: {
    isOpen() {
      if (this.isOpen) {
        this.$refs.select.$el.focus();
      } else {
        this.$refs.select.$el.blur();
      }
    }
  },

  methods: {
    selectAll() {
      this.value = null
      this.$refs.select.deactivate()
      this.$emit('clearAll', null)
    },
    closeMultiSelect() {
      this.$emit('closeMultiSelect', null)
    },
    addIds() {
      let matches  = document.querySelectorAll("li.multiselect__element")
      matches.forEach((i, index) => i.id = `${this.id}_${index}`)
    }
  },

  created: function () {
    this.$parent.$on('clear', this.selectAll);
  },

  mounted() {
    this.addIds()
  },
}
</script>

<style lang="scss">

.multiselect__option--highlight {
  background: #F8FCFC;
}

.multiselect__option--highlight:after {
  content: '';
  position: relative;
  width: 24px;
  height: 24px;
  background-color: #fff;
  border: 1px solid #c0c0c0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s ease;
  border: 1px solid var(--accent-color);
  border-radius: 1px;
}

//.multiselect__option--highlight::before {
//    content: '';
//    position: absolute;
//    top: 12px;
//    left: 16px;
//    display: flex;
//    background: var(--accent-color);
//    width: 18px;
//    height: 18px;
//    border-radius: 1px;
//    z-index: 2;
//    cursor: pointer;
//    transition: all .3s ease;
//}

.multiselect__option--selected {
  background: #F8FCFC;
  font-weight: 400;
}

.multiselect--disabled {
  background-color: var(--white-color);
}

.multiselect__select {
  border-radius: 0 20px 20px 0;
}

.multiselect__select:before {
  content: '';
}

.multiselect__option--selected.multiselect__option--highlight:after {
  content: '';
  position: absolute;
  top: 8px;
  left: 12px;
  width: 24px;
  height: 24px;
  background-color: #fff;
  border: 1px solid #c0c0c0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s ease;
  border: 1px solid var(--accent-color);
  border-radius: 1px;
}

.multiselect__option--selected.multiselect__option--highlight {
  background: #F8FCFC;
  color: #333;
}

.multiselect__option--selected:after {
  content: '';
  position: relative;
  width: 24px;
  height: 24px;
  background-color: #fff;
  border: 1px solid #c0c0c0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s ease;
  border: 1px solid var(--accent-color);
  border-radius: 1px;

}

.multiselect__option--selected::before {
  content: '';
  display: flex;
  position: absolute;
  top: 12px;
  left: 16px;
  background: var(--accent-color);
  width: 18px;
  height: 18px;
  border-radius: 1px;
  z-index: 2;
  cursor: pointer;
  transition: all .3s ease;
}

.multiselect__option:after {
  content: '';
  top: 8px;
  left: 12px;
  width: 24px;
  height: 24px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s ease;
  border: 1px solid var(--background-button-disabled-color);
  border-radius: 1px;
  position: absolute;
  padding: 0;
  margin-right: 10px;
  font-size: 13px;
}

.multiselect__option {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 10px 10px 46px;
  border-bottom: 1px solid var(--background-button-disabled-color);
}

.multiselect__option {
  width: 100%;
}

.multiselect__tag {
  background-color: var(--white-color);
  color: var(--secondary-text-color);
  padding: 4px 5px 4px 10px;
  margin-right: 0;
  min-width: 86px;
}

.multiselect__tag-icon:focus, .multiselect__tag-icon:hover {
  color: var(--secondary-text-color);
  background-color: transparent;
}

.multiselect__tags {
  border-radius: 20px;
  padding: 8px 40px 0 12px;
}

.multiselect__element .multiselect__option--highlight {
  color: var(--secondary-black-color);
  //   border-bottom: 1px solid #C2C9D1;
}

.multiselect__tags-wrap {
  display: flex;
  overflow: hidden;
}

.multiselect__tag-icon {
  display: none;
}

.multiselect__content-wrapper {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  max-height: 258px;
}

.multiselect__input {
  display: none;
}

.multiselect__input::placeholder {
  color: var(--light-gray);
  font-weight: 500;
  font-size: 12px;
}

.multiselect__placeholder {
  padding-top: 3px;
  color: var(--light-gray);
}

.holder-clear {
  display: flex;
  justify-content: flex-end;
}

.holder-clear button {
  background-color: var(--white-color);
  border: none;
  padding: 10px 17px;
  text-decoration: underline;
  text-transform: uppercase;
  color: var(--secondary-text-color);
  font-weight: 600;
  font-size: 10px;
}

//custom classes

.multiselect.cases-filter .multiselect__tags {
  padding: 16px 30px;
  border: 1px solid var(--case-btn-border-color);
}

.multiselect.cases-filter .multiselect__select {
  top: 5px;
}

.multiselect.cases-filter .multiselect__tag {
  margin: 0;
  padding: 0 10px 0 0;
  font-size: 14px;
  font-weight: 500;
  color: var(--secondary-black-color)
}

.multiselect.case-manager-filter .multiselect__select {
  top: 5px;
}

.multiselect.case-manager-filter .multiselect__tags {
  padding: 16px 30px;
  border: 1px solid var(--case-btn-border-color);
}

.multiselect.case-manager-filter .multiselect__tag {
  margin: 0;
  padding: 0 10px 0 0;
  font-size: 14px;
  font-weight: 500;
  color: var(--secondary-black-color)
}

// role-multi style
.role-multi .multiselect__content-wrapper {
  bottom: auto !important;
  top: 100% !important;
  z-index: 15;
  border: 1px solid #e8e8e8;
}

.role-multi {
  position: absolute;
  max-width: 200px;
}

.role-multi .multiselect__select, .role-multi .multiselect__tags {
  display: none;
}

.select-box:first-child .role-multi {
  left: 0;
  top: 0;
}

.role-optional .multiselect__tag {
  min-width: 65px;
}

</style>