<template>
  <div class="holder-table">
    <AlertErrorMsg
        :title="errorMessage"
        class="page-alert-error"
        ref="alert"
    />
    <table cellpadding="0" cellspacing="0" border="0" class="raz">
      <TableHeader v-if="actionFilesList.length">
        <tr>
          <th class="w-300">
            <div
                class="table-header-box"
                @click="sortedBy('fileName')"
                :class="{
                  'sort-active': this.sortBy === 'fileName',
                  'sorted-desc': this.order === 'desc',
                }"
            >
              <svg width="13" height="17" viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.78125 9.5625C2.64035 9.5625 2.50523 9.50653 2.4056 9.4069C2.30597 9.30727 2.25 9.17215 2.25 9.03125C2.25 8.89035 2.30597 8.75523 2.4056 8.6556C2.50523 8.55597 2.64035 8.5 2.78125 8.5H10.2188C10.3596 8.5 10.4948 8.55597 10.5944 8.6556C10.694 8.75523 10.75 8.89035 10.75 9.03125C10.75 9.17215 10.694 9.30727 10.5944 9.4069C10.4948 9.50653 10.3596 9.5625 10.2188 9.5625H2.78125Z" fill="#D7DCE6"/>
                <path d="M2.25 11.1562C2.25 11.0154 2.30597 10.8802 2.4056 10.7806C2.50523 10.681 2.64035 10.625 2.78125 10.625H10.2188C10.3596 10.625 10.4948 10.681 10.5944 10.7806C10.694 10.8802 10.75 11.0154 10.75 11.1562C10.75 11.2971 10.694 11.4323 10.5944 11.5319C10.4948 11.6315 10.3596 11.6875 10.2188 11.6875H2.78125C2.64035 11.6875 2.50523 11.6315 2.4056 11.5319C2.30597 11.4323 2.25 11.2971 2.25 11.1562Z" fill="#D7DCE6"/>
                <path d="M2.78125 13.8125C2.64035 13.8125 2.50523 13.7565 2.4056 13.6569C2.30597 13.5573 2.25 13.4221 2.25 13.2812C2.25 13.1404 2.30597 13.0052 2.4056 12.9056C2.50523 12.806 2.64035 12.75 2.78125 12.75H7.03125C7.17215 12.75 7.30727 12.806 7.4069 12.9056C7.50653 13.0052 7.5625 13.1404 7.5625 13.2812C7.5625 13.4221 7.50653 13.5573 7.4069 13.6569C7.30727 13.7565 7.17215 13.8125 7.03125 13.8125H2.78125Z" fill="#D7DCE6"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.87381 0H2.25C1.68641 0 1.14591 0.223883 0.747398 0.622398C0.348883 1.02091 0.125 1.56141 0.125 2.125V14.875C0.125 15.4386 0.348883 15.9791 0.747398 16.3776C1.14591 16.7761 1.68641 17 2.25 17H10.75C11.3136 17 11.8541 16.7761 12.2526 16.3776C12.6511 15.9791 12.875 15.4386 12.875 14.875V5.00119C12.8749 4.71942 12.763 4.44921 12.5637 4.25L8.625 0.311313C8.42579 0.112041 8.15558 6.01772e-05 7.87381 0ZM8.09375 1.59375V3.71875C8.09375 4.00054 8.20569 4.27079 8.40495 4.47005C8.60421 4.66931 8.87446 4.78125 9.15625 4.78125H11.2812L8.09375 1.59375ZM2.4056 9.4069C2.50523 9.50653 2.64035 9.5625 2.78125 9.5625H10.2188C10.3596 9.5625 10.4948 9.50653 10.5944 9.4069C10.694 9.30727 10.75 9.17215 10.75 9.03125C10.75 8.89035 10.694 8.75523 10.5944 8.6556C10.4948 8.55597 10.3596 8.5 10.2188 8.5H2.78125C2.64035 8.5 2.50523 8.55597 2.4056 8.6556C2.30597 8.75523 2.25 8.89035 2.25 9.03125C2.25 9.17215 2.30597 9.30727 2.4056 9.4069ZM2.4056 10.7806C2.30597 10.8802 2.25 11.0154 2.25 11.1562C2.25 11.2971 2.30597 11.4323 2.4056 11.5319C2.50523 11.6315 2.64035 11.6875 2.78125 11.6875H10.2188C10.3596 11.6875 10.4948 11.6315 10.5944 11.5319C10.694 11.4323 10.75 11.2971 10.75 11.1562C10.75 11.0154 10.694 10.8802 10.5944 10.7806C10.4948 10.681 10.3596 10.625 10.2188 10.625H2.78125C2.64035 10.625 2.50523 10.681 2.4056 10.7806ZM2.4056 13.6569C2.50523 13.7565 2.64035 13.8125 2.78125 13.8125H7.03125C7.17215 13.8125 7.30727 13.7565 7.4069 13.6569C7.50653 13.5573 7.5625 13.4221 7.5625 13.2812C7.5625 13.1404 7.50653 13.0052 7.4069 12.9056C7.30727 12.806 7.17215 12.75 7.03125 12.75H2.78125C2.64035 12.75 2.50523 12.806 2.4056 12.9056C2.30597 13.0052 2.25 13.1404 2.25 13.2812C2.25 13.4221 2.30597 13.5573 2.4056 13.6569Z" fill="#D7DCE6"/>
              </svg>
              <span class="header-table-text">File Name</span>
              <arrowDown class="arrow" />
            </div>
          </th>
          <th>
            <div
                class="table-header-box table-header-box-doesnt-hover"
            >
              <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.34961 0.861328C1.24504 0.861328 0.349609 1.75676 0.349609 2.86133V16C0.349609 17.1046 1.24504 18 2.34961 18H17.6522C18.7568 18 19.6522 17.1046 19.6522 16V2.86133C19.6522 1.75676 18.7568 0.861328 17.6522 0.861328H2.34961ZM11.1918 10.9515C10.4486 10.8618 9.63781 10.817 8.75946 10.817C8.71442 10.817 8.66937 10.8226 8.62433 10.8338C8.57928 10.845 8.53424 10.8618 8.4892 10.8842C8.44415 10.9067 8.40474 10.9347 8.37096 10.9683C8.26961 11.0803 8.21893 11.2091 8.21893 11.3548V13.8418L2.5941 9.2038L8.21893 4.61619V7.03603C8.21893 7.18167 8.27242 7.3077 8.3794 7.41413C8.48638 7.52056 8.61307 7.57377 8.75946 7.57377C11.8675 7.57377 14.1591 8.4308 15.6343 10.1449C16.6027 11.2539 17.1939 12.6207 17.4079 14.2451C17.1939 13.9427 16.9687 13.6682 16.7322 13.4217C16.4957 13.1753 16.2029 12.9148 15.8538 12.6403C15.5048 12.3658 15.1078 12.125 14.663 11.9177C14.2182 11.7105 13.7143 11.52 13.1512 11.3464C12.5882 11.1727 11.935 11.0411 11.1918 10.9515Z" fill="#D7DCE6"/>
              </svg>
              <span class="header-table-text">Action</span>
            </div>
          </th>
          <th>
            <div
                class="table-header-box table-header-box-doesnt-hover "
            >
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.4737 3.2H6.73684C6.5135 3.2 6.29931 3.28429 6.14138 3.43431C5.98346 3.58434 5.89474 3.78783 5.89474 4V14.4H0.842105C0.618765 14.4 0.404572 14.3157 0.246647 14.1657C0.0887215 14.0157 0 13.8122 0 13.6V0.8C0 0.587827 0.0887215 0.384344 0.246647 0.234315C0.404572 0.0842854 0.618765 0 0.842105 0H12.6316C12.8549 0 13.0691 0.0842854 13.227 0.234315C13.385 0.384344 13.4737 0.587827 13.4737 0.8V3.2ZM8.42105 4.8H15.1579C15.3812 4.8 15.5954 4.88429 15.7534 5.03431C15.9113 5.18434 16 5.38783 16 5.6V15.2C16 15.4122 15.9113 15.6157 15.7534 15.7657C15.5954 15.9157 15.3812 16 15.1579 16H8.42105C8.19771 16 7.98352 15.9157 7.82559 15.7657C7.66767 15.6157 7.57895 15.4122 7.57895 15.2V5.6C7.57895 5.38783 7.66767 5.18434 7.82559 5.03431C7.98352 4.88429 8.19771 4.8 8.42105 4.8Z" fill="#D7DCE6"/>
              </svg>
              <span class="header-table-text">Device </span>
            </div>
          </th>
          <th class="w-300">
            <div
                class="table-header-box table-header-box-doesnt-hover"
            >
              <UserIcon />
              <span class="header-table-text">Actor</span>
            </div>
          </th>
          <th>
            <div
                class="table-header-box table-header-box-doesnt-hover"
            >
              <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.48024 0H0.520239C0.245378 0 0.0224609 0.222917 0.0224609 0.497778V15.5022C0.0224609 15.7771 0.245378 16 0.520239 16H9.48024C9.7551 16 9.97802 15.7771 9.97802 15.5022V0.497778C9.97802 0.222917 9.7551 0 9.48024 0ZM3.16552 0.462222H6.83496C7.18774 0.462222 7.47496 0.724583 7.47496 1.04889C7.47496 1.37319 7.18774 1.63556 6.83496 1.63556H3.16552C2.81274 1.63556 2.52552 1.37319 2.52552 1.04889C2.52552 0.724583 2.81274 0.462222 3.16552 0.462222ZM1.58691 2.80889C1.58691 2.6125 1.74607 2.45333 1.94246 2.45333H8.02246C8.21885 2.45333 8.37802 2.6125 8.37802 2.80889V9.49333C8.37802 9.68972 8.21885 9.84889 8.02246 9.84889H1.94246C1.74607 9.84889 1.58691 9.68972 1.58691 9.49333V2.80889ZM1.90691 11.52C1.78913 11.52 1.69357 11.4244 1.69357 11.3067C1.69357 11.1889 1.78913 11.0933 1.90691 11.0933H8.05802C8.1758 11.0933 8.27135 11.1889 8.27135 11.3067C8.27135 11.4244 8.1758 11.52 8.05802 11.52H1.90691ZM7.73802 12.7644C7.73802 12.8822 7.64246 12.9778 7.52468 12.9778H2.44024C2.32246 12.9778 2.22691 12.8822 2.22691 12.7644C2.22691 12.6467 2.32246 12.5511 2.44024 12.5511H7.52468C7.64246 12.5511 7.73802 12.6467 7.73802 12.7644ZM8.59135 14.4H1.37357C1.25579 14.4 1.16024 14.3044 1.16024 14.1867C1.16024 14.0689 1.25579 13.9733 1.37357 13.9733H8.59135C8.70913 13.9733 8.80468 14.0689 8.80468 14.1867C8.80468 14.3044 8.70913 14.4 8.59135 14.4Z" fill="#D7DCE6"/>
              </svg>
              <span class="header-table-text">Actor’s Role</span>
            </div>
          </th>
          <th>
            <div
                @click="sortedBy('actionDate')"
                class="table-header-box"
                :class="{
                  'sort-active': this.sortBy === 'actionDate',
                  'sorted-desc': this.order === 'desc',
                }"
            >
              <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.0625 0.5C3.17853 0.5 3.28981 0.549386 3.37186 0.637294C3.45391 0.725201 3.5 0.84443 3.5 0.96875V1.4375H10.5V0.96875C10.5 0.84443 10.5461 0.725201 10.6281 0.637294C10.7102 0.549386 10.8215 0.5 10.9375 0.5C11.0535 0.5 11.1648 0.549386 11.2469 0.637294C11.3289 0.725201 11.375 0.84443 11.375 0.96875V1.4375H12.25C12.7141 1.4375 13.1592 1.63504 13.4874 1.98667C13.8156 2.33831 14 2.81522 14 3.3125V13.625C14 14.1223 13.8156 14.5992 13.4874 14.9508C13.1592 15.3025 12.7141 15.5 12.25 15.5H1.75C1.28587 15.5 0.840752 15.3025 0.512563 14.9508C0.184374 14.5992 0 14.1223 0 13.625V5.1875H14V4.25H0V3.3125C0 2.81522 0.184374 2.33831 0.512563 1.98667C0.840752 1.63504 1.28587 1.4375 1.75 1.4375H2.625V0.96875C2.625 0.84443 2.67109 0.725201 2.75314 0.637294C2.83519 0.549386 2.94647 0.5 3.0625 0.5V0.5Z" fill="#D7DCE6"/>
              </svg>
              <span class="header-table-text">Date</span>
              <arrowDown class="arrow" />
            </div>
          </th>
        </tr>
      </TableHeader>
      <Loader v-if="loadFlag" :load-flag="loadFlag" />
      <template v-else>
        <CaseActionsFilesItem
            :loadFlag="loadFlag"
            :actionFilesList="actionFilesList"
            v-if="actionFilesList.length"
        />
        <p class="no-found" v-else>No files found</p>
      </template>
    </table>
    <pagination v-if="actionFilesList.length && count !== 1"
                v-model="page"
                :total-pages="count"
                :link-gen="linkGen"
    ></pagination>
  </div>
</template>

<script>
import linkGenerator from "../../../constants/linkGen";
import AlertErrorMsg from "../../profile/AlertErrorMsg";
import TableHeader from "../../table/TableHeader";
import CaseActionsFilesItem from "./CaseActionsFilesItem";
import Loader from "../../loader/Loader";
import ArrowDown from "../../icons/ArrowDown";
import UserIcon from "../../icons/UserIcon";
import Pagination from "@/components/common/Pagination.vue";

export default {
  name: "CaseActionsFiles",
  components: {
    Pagination,
    Loader,
    UserIcon,
    ArrowDown,
    TableHeader,
    AlertErrorMsg,
    CaseActionsFilesItem
  },
  props: {
    searchText: {
      type: String
    },
    dataRange: {},
  },
  data() {
    return {
      loadFlag: false,
      errorMessage: '',
      caseId: this.$route.params.slug,
      keySearch: '',
      dateFrom: '',
      dateTo: '',
      sortBy: 'actionDate',
      order: 'desc',
      page: 1,
      size: 10,
      count: 1,
      pageSize: 2,
      actionFilesList: []
    }
  },
  watch: {
    searchText() {
      this.getActionFiles()
    },
    dataRange(value) {
      this.dateFrom = !isNaN(Date.parse(value[0])) ? Date.parse(value[0]) + 10800000 : ''
      this.dateTo = !isNaN(Date.parse(value[1])) ? Date.parse(value[1]) + 97200000  : ''
      this.getActionFiles()
    },
    '$route.query.page': {
      deep: true,
      handler(newVal) {
        if(newVal) {
          this.page = newVal
          this.getActionFiles()
        } else {
          this.page = 1
        }
      }
    }
  },
  methods: {
    sortedBy(filterName) {
      this.order = filterName === this.sortBy && this.order === 'desc' ? 'asc' : 'desc'
      this.sortBy = filterName
      this.getActionFiles()
    },
    fixedEncodeURIComponent(str) {
      return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
        return '%' + c.charCodeAt(0).toString(16);
      });
    },
    linkGen(pageNum) {
      return linkGenerator(pageNum, `/cases/${this.caseId}/case-actions/files`)
    },
    getActionFiles() {
      this.$load(async () => {
        this.loadFlag = true
        await this.$api.cases.getCaseActionFiles({
          caseId: this.caseId,
          sortBy: this.sortBy,
          keySearch: this.fixedEncodeURIComponent(this.searchText),
          order: this.order,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          page: this.page,
          size: this.size,
        }).then((res) => {
          this.actionFilesList = res.data.body.content
          this.count = res.data.body.totalPages
          this.pageSize = res.data.body.size
        }, (error) => {
          if (error.response) {
            this.errorMessage = error.response.data.errors.message[0]
          } else {
            this.errorMessage = error.data.errors.message[0]
          }
          this.$refs.alert.showAlert()
        })
        setTimeout(() => {
          this.loadFlag = false
        }, 1000)
      })
    },
  },
  mounted() {
    this.page = this.$route.query.page || 1
    this.getActionFiles()
  }
}
</script>

<style scoped>

</style>
