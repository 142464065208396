<template>
  <div class="wrapper">
    <Sidebar/>
    <div class="content-wrapper">
      <AlertErrorMsg
          :title="errorMessage"
          class="page-alert-error"
          ref="alert"
      />
      <HeaderRegistered :returnPath="returnPath" :subTitle="subTitle" />
      <main class="main">
        <section class="case-assign-manager">
          <div class="manager-wrapper">
            <div class="manage-filter-bar">
              <CustomSearch
                  id="search_assign_menagers"
                  :modelValue="searchText"
                  @update:modelValue="searchText = $event"
                  classComponent="assign-case-manager"/>
            </div>
              <table cellpadding="0" cellspacing="0" border="0">
                <TableHeader>
                  <tr>
                  <th>
                    <div
                        :id="`sort_case_menager_case_${caseId}_by_name`"
                        @click="sortedBy('userName')"
                        class="filter-option-wrapper"
                        :class="{
                          'sort-active': this.sortBy === 'userName',
                          'sorted-desc': this.order === 'desc',
                        }"
                    >
                      <div class="filter-image">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                              d="M8 7.5C10.2094 7.5 12 5.82129 12 3.75C12 1.67871 10.2094 0 8 0C5.79063 0 4 1.67871 4 3.75C4 5.82129 5.79063 7.5 8 7.5ZM10.8 8.4375H10.2781C9.58437 8.73633 8.8125 8.90625 8 8.90625C7.1875 8.90625 6.41875 8.73633 5.72188 8.4375H5.2C2.88125 8.4375 1 10.2012 1 12.375V13.5938C1 14.3701 1.67188 15 2.5 15H13.5C14.3281 15 15 14.3701 15 13.5938V12.375C15 10.2012 13.1187 8.4375 10.8 8.4375Z"
                              fill="#D7DCE6"/>
                        </svg>
                      </div>
                      <div class="filter-title">
                        <span class="filter-title-text">User Name</span>
                      </div>
                      <ArrowDown class="arrow" />
                    </div>
                  </th>
                  <th>
                    <div
                        :id="`sort_case_menager_case_${caseId}_by_role`"
                        @click="sortedBy('roles')"
                        class="filter-option-wrapper"
                        :class="{
                          'sort-active': this.sortBy === 'roles',
                          'sorted-desc': this.order === 'desc',
                        }"
                    >
                      <div class="filter-image">
                        <RoleIcon />
                      </div>
                      <div class="filter-title">
                        <span class="filter-title-text">role</span>
                      </div>
                      <ArrowDown class="arrow" />
                    </div>
                  </th>
                  <th>
                    <div
                        :id="`sort_case_menager_case_${caseId}_by_case`"
                        @click="sortedBy('cases')"
                        class="filter-option-wrapper"
                        :class="{
                          'sort-active': this.sortBy === 'cases',
                          'sorted-desc': this.order === 'desc',
                        }"
                    >
                      <div class="filter-image">
                        <CaseIcon />
                      </div>
                      <div class="filter-title">
                        <span class="filter-title-text">cases</span>
                      </div>
                      <ArrowDown class="arrow" />
                    </div>
                  </th>
                  <th></th>
                </tr>
                </TableHeader>
                <Loader v-if="loadFlag" :load-flag="loadFlag" />
                <template v-else>
                  <CaseAssignManagerItem
                      :managerList="managerList"
                      :caseManager="caseManager"
                      @assign-manager="assignNewManager"
                  />
                </template>
              </table>
            <CommonDialog ref="success" custom-class="assign-success">
              <AssignSuccessBlock
                  :caseManager="caseManager"
                  :caseId="caseId"
                  :caseTitle="caseTitle"
              />
            </CommonDialog>
          </div>
        </section>
      </main>
    </div>
  </div>
</template>

<script>
import CustomSearch from "../../inputs/CustomSearch";
import HeaderRegistered from "../../layout/HeaderRegistered";
import Sidebar from "../../Sidebar";
import TableHeader from "../../table/TableHeader";
import AlertErrorMsg from "../../profile/AlertErrorMsg";
import CaseAssignManagerItem from "../case-assign-manager/CaseAssignManagerItem";
import CommonDialog from "../../popups/CommonDialog";
import AssignSuccessBlock from "../case-assign-manager/AssignSuccessBlock";
import Loader from "../../loader/Loader";
import CaseIcon from "../../icons/CaseIcon";
import RoleIcon from "../../icons/RoleIcon";
import ArrowDown from '@/components/icons/ArrowDown';

export default {
  name: "AssignCaseManager",
  components: {
    Loader,
    RoleIcon,
    CaseIcon,
    AlertErrorMsg,
    HeaderRegistered,
    Sidebar,
    CustomSearch,
    TableHeader,
    CaseAssignManagerItem,
    CommonDialog,
    AssignSuccessBlock,
    ArrowDown,
  },
  data() {
    return {
      loadFlag: false,
      searchText: "",
      caseTitle: '',
      returnPath: true,
      caseId: this.$route.params.slug,
      keySearch: '',
      roles: '',
      sortBy: 'userName',
      order: 'asc',
      page: 1,
      size: 10,
      count: 0,
      pageSize: 2,
      managerList: [],
      caseManager: {},
      errorMessage: '',
      filteredCases: null,
      currentManagerName: null,
      currentManagerPhoto: null,
      currentManagerCase: null,
    }
  },
  computed: {
    subTitle() {
      return ` ${this.caseTitle}`
    }
  },
  watch: {
    caseManager(){
      this.getManagers()
    },
    searchText() {
      this.getManagers()
    },
  },
  methods: {
    sortedBy(filterName) {
      this.order = filterName === this.sortBy && this.order === 'desc' ? 'asc' : 'desc'
      this.sortBy = filterName
      this.getManagers()
    },
    fixedEncodeURIComponent (str) {
      return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
        return '%' + c.charCodeAt(0).toString(16);
      });
    },
    getManagers() {
      this.$load(async () => {
        this.loadFlag = true
        await this.$api.cases.getCasesManagers({
          caseId: this.caseId,
          sortBy: this.sortBy,
          order: this.order,
          roles: this.roles,
          keySearch: this.fixedEncodeURIComponent(this.searchText),
          page: 1,
          size: 20
        }).then((res) => {
          this.managerList = res.data.body.content
        }, (error) => {
          if (error.response) {
            this.errorMessage = error.response.data.errors.message[0]
          } else {
            this.errorMessage = error.data.errors.message[0]
          }
        })
        setTimeout(() => {
          this.loadFlag = false
        }, 1000)
      })
    },
    assignNewManager(manager) {
      let userId = {
        userId: manager.publicId
      }
      let caseId = this.caseId
      this.$load(async () => {
        await this.$api.cases.setCasesManager(caseId, userId).then(() => {
          this.caseManager = manager
          this.openDialogFormSuccess()
        }, (error) => {
          if (error.response) {
            this.errorMessage = error.response.data.errors.message[0]
          } else {
            this.errorMessage = error.data.errors.message[0]
          }
          this.$refs.alert.showAlert()
        })
      })
    },
    makeCaseManager(id) {
      this.filteredCases.forEach(item => item.caseManager = false)
      let manager = this.filteredCases.find(item => item.id === id);
      manager.caseManager = !manager.caseManager;
      this.currentManagerName = manager.name
      this.currentManagerPhoto = manager.photo
      this.currentManagerCase = manager.caseTitle
      this.$refs.success.openPopups();
    },
    openDialogFormSuccess() {
      this.$refs.success.openPopups();
    },
  },
  mounted() {
    this.caseTitle = localStorage.getItem('title')
    this.page = this.$route.query.page || 1
    // this.filteredCases = this.filterCases(this.cases, 'Employee')
    this.getManagers()
  },
}
</script>

<style scoped>
.manage-filter-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 42px;
}

.case-assign-manager {
  padding: 27px 55px 27px 35px;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: flex-start;
}

.manager-wrapper {
  width: 100%;
}

/* table */
.assign-case-table {
  padding: 42px 0 0 0;
}

.filter-option-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.filter-image {
  width: 16px;
  height: 16px;
  margin: 0 6px 0 0;
}

.filter-title {
  margin: 0 6px 0 0;
}

.filter-title-text {
  display: block;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--secondary-black-color);
  font-size: 10px;
  line-height: 10px;
  font-weight: 400;
  max-width: 60px;
}

.filter-image svg path {
  transition: .5s all;
}

.filter-option-wrapper:hover .filter-image svg path {
  fill: var(--accent-color);
}

.filter-option-wrapper:hover .filter-title-text {
  text-decoration: underline;
}
</style>
