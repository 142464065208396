<template>
  <charts :options="options"></charts>
</template>

<script>

export default {
  name: "PieCart",
  props: {
    desktopReach: Number,
    mobileReach: Number,
  },
  data() {
    return {
        options: {
          chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            height: 180,
          },
          legend: {
            itemDistance: 10,
            align: 'right',
            useHTML: true,
            lineHeight: 30,
            layout: 'vertical',
            itemStyle: {"cursor": "pointer", "fontSize": "14px", "fontWeight": "400"}
          },
          height: 200,
          credits: {
            enabled: false
          },
          title: null,
          tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
          },

          plotOptions: {
            pie: {
              allowPointSelect: true,
              colors: ['#DEFDFF', '#082655'],
              cursor: 'pointer',
              dataLabels: {
                enabled: true,
                format: '{point.percentage:.1f} %',
                distance: -30,
                filter: {
                  property: 'percentage',
                  operator: '>',
                  value: 4
                }
              }
            }
          },

          series: [{
            showInLegend: true,
            // name: '',
            keys: ['name', 'y', 'selected', 'sliced', 'dataLabels.style'],
            data: [
              ['Desktop',  this.desktopReach, true, true, {fontSize: '10px', transform: 'translate(223px, 17px)', textOutline: 'none', color: '#082655'}],
              ['Mobile views', this.mobileReach, false, false, {fontSize: '10px', transform: 'translate(173px, 77px)', textOutline: 'none', color: '#fff'}],
            ],
          }]
        },
        pieColors: [],
      }
  },
}
</script>

<style scoped lang="scss">

</style>