<template>
  <div class="wrapper">
    <div v-if="users && users.length === 0 && !searchText" class="empty-contacts">
      <img src="@/assets/img/empty-contacts.svg" alt="">
      <button
        v-if="$route.path !== `/contacts/custodians`"
        :disabled="!checkPermission(['invite_users'])"
        id="goto_invite_new"
        class="btn invite-user-btn"
        @click="$router.push({path: `/invite-new-contact/invite-team`}).catch(()=>{})"
      >
        Invite new
      </button>
    </div>
    <div v-if="users && users.length === 0 && searchText" class="not-found">
      <div>No contact found, you can
        <span @click="$router.push({path: `/invite-new-contact/invite-team`}).catch(()=>{})">invite new contact</span>
      </div>
    </div>
    <div class="holder-table" v-if="users.length > 0">
      <AlertErrorMsg
          :title="errorMessage"
          class="page-alert-error"
          ref="alert"
      />
      <TableHeader
          :class="checkAll || checkSome.length >= 2 ? 'active ' + contactsView : contactsView"
      >
        <div class="check">
          <div class="custom-checkbox-group">
            <div class="checkbox-wrap">
              <input
                  id="checkAll_users_in_contacts"
                  class="input-checkbox"
                  type="checkbox"
                  v-model="checkAll">
              <label for="checkAll_users_in_contacts" class="label-checkbox">
              </label>
              <span class="checkbox-unselect" @click="unselectAll">{{ checkAll || checkSome.length >= 2 ? 'Unselect All' : '' }}</span>
            </div>
          </div>
        </div>
        <div v-show="!checkAll && checkSome.length <= 1"
            class="name">
          <div class="table-header-box"
               id="sort_users_in_contacts_by_name"
               @click="sortedBy('userName')"
               :class="{
                 'sort-active': this.sortBy === 'userName',
                 'sorted-desc': this.order === 'desc',
               }"
          >
            <UserIcon />
            <span class="header-table-text">User Name</span>
            <arrowDown class="arrow" />
          </div>
        </div>

        <div v-if="!checkAll && checkSome.length <= 1"
            class="mail">
          <div
              class="table-header-box"
              @click="sortedBy('emailUser')"
              :class="{
                'sort-active': this.sortBy === 'emailUser',
                'sorted-desc': this.order === 'desc',
              }"
          >
            <EmailIcon />
            <span class="header-table-text">Mail</span>
            <arrowDown class="arrow" />
          </div>
        </div>

        <div v-if="!checkAll && checkSome.length <= 1"
            class="roles">
          <div
              class="table-header-box"
              @click="sortedBy('role')"
              :class="{
                'sort-active': this.sortBy === 'role',
                'sorted-desc': this.order === 'desc',
              }"
          >
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.40896 7.51465C9.04299 7.81854 8.45799 8.04257 7.99993 8.04257C7.54187 8.04257 6.95674 7.81895 6.59076 7.51507C5.97632 7.88104 5.61965 8.53326 5.57007 9.42215H10.4298C10.3802 8.53326 10.0235 7.88076 9.40896 7.51465Z" fill="#DDE3E3"/>
              <path d="M8.00004 6.85858C8.43545 6.86053 8.82906 6.59969 8.99712 6.19803C9.16504 5.79622 9.0742 5.33289 8.76698 5.02428C8.45976 4.71567 7.9967 4.62275 7.5942 4.789C7.19184 4.95511 6.9292 5.34761 6.9292 5.78303C6.92851 6.37553 7.40753 6.85678 8.00004 6.85858Z" fill="#DDE3E3"/>
              <path d="M6.52739 7.06346C6.61087 7.02152 6.71198 7.03874 6.77684 7.10596C7.04239 7.38235 7.60267 7.61568 8.00017 7.61568C8.39767 7.61568 8.95767 7.38193 9.22323 7.10554C9.28823 7.03832 9.38934 7.02138 9.47267 7.0636C10.3027 7.47957 10.8 8.32013 10.8571 9.42235H10.9511V2.88013H5.01337V9.42235H5.14295C5.20017 8.32013 5.69739 7.47943 6.52739 7.06346ZM8.00003 4.28096C8.60809 4.27915 9.15726 4.64388 9.39128 5.20513C9.62531 5.76624 9.49795 6.41318 9.06864 6.84374C8.63948 7.27443 7.99295 7.40374 7.43101 7.17152C6.86906 6.93929 6.50253 6.39124 6.50253 5.78318C6.50212 4.95527 7.17198 4.28318 8.00003 4.28096Z" fill="#DDE3E3"/>
              <path d="M6.16548 1.20892H9.83493C9.95048 1.20892 10.0483 1.13642 10.0483 1.04892C10.0483 0.961416 9.95048 0.888916 9.83493 0.888916H6.16548C6.04993 0.888916 5.95215 0.961416 5.95215 1.04892C5.95215 1.13642 6.04993 1.20892 6.16548 1.20892Z" fill="#DDE3E3"/>
              <path d="M12.4802 0H3.52018C3.24532 0 3.0224 0.222917 3.0224 0.497778V15.5022C3.0224 15.7771 3.24532 16 3.52018 16H12.4802C12.755 16 12.978 15.7771 12.978 15.5022V0.497778C12.978 0.222917 12.755 0 12.4802 0ZM6.16546 0.462222H9.8349C10.1877 0.462222 10.4749 0.724583 10.4749 1.04889C10.4749 1.37319 10.1877 1.63556 9.8349 1.63556H6.16546C5.81268 1.63556 5.52546 1.37319 5.52546 1.04889C5.52546 0.724583 5.81268 0.462222 6.16546 0.462222ZM4.58684 2.80889C4.58684 2.6125 4.74601 2.45333 4.9424 2.45333H11.0224C11.2188 2.45333 11.378 2.6125 11.378 2.80889V9.49333C11.378 9.68972 11.2188 9.84889 11.0224 9.84889H4.9424C4.74601 9.84889 4.58684 9.68972 4.58684 9.49333V2.80889ZM4.90684 11.52C4.78907 11.52 4.69351 11.4244 4.69351 11.3067C4.69351 11.1889 4.78907 11.0933 4.90684 11.0933H11.058C11.1757 11.0933 11.2713 11.1889 11.2713 11.3067C11.2713 11.4244 11.1757 11.52 11.058 11.52H4.90684ZM10.738 12.7644C10.738 12.8822 10.6424 12.9778 10.5246 12.9778H5.44018C5.3224 12.9778 5.22684 12.8822 5.22684 12.7644C5.22684 12.6467 5.3224 12.5511 5.44018 12.5511H10.5246C10.6424 12.5511 10.738 12.6467 10.738 12.7644ZM11.5913 14.4H4.37351C4.25573 14.4 4.16018 14.3044 4.16018 14.1867C4.16018 14.0689 4.25573 13.9733 4.37351 13.9733H11.5913C11.7091 13.9733 11.8046 14.0689 11.8046 14.1867C11.8046 14.3044 11.7091 14.4 11.5913 14.4Z" fill="#D7DCE6"/>
            </svg>
            <span class="header-table-text">Role</span>
            <arrowDown class="arrow" />
          </div>
        </div>

        <div v-if="!checkAll && checkSome.length <= 1"
             class="case">
          <div class="table-header-box"
               id="sort_users_in_contacts_by_case_count"
               @click="sortedBy('caseCount')"
               :class="{
                 'sort-active': this.sortBy === 'caseCount',
                 'sorted-desc': this.order === 'desc',
               }"
          >
            <CaseIcon />
            <span class="header-table-text">Case</span>
            <arrowDown class="arrow" />
          </div>
        </div>
        <div class="holder-btn permission"></div>
        <div v-if="checkAll || checkSome.length >= 2"
             class="holder-deactivate-btn"
             id="disabled-deactivate-all"
             ref="disabledDeactivateAll"
        >
          <button
              id="deactivate_users_in_contacts"
              :disabled="!checkPermission(['manage_contacts'])"
              class="btn btn-deactivate"
              @click.stop="openDeactivatePopup">
            <span class="btn-span">Deactivate all</span>
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M10 0.499969C4.47 0.499969 0 4.96997 0 10.5C0 16.03 4.47 20.5 10 20.5C15.53 20.5 20 16.03 20 10.5C20 4.96997 15.53 0.499969 10 0.499969ZM15 14.09L13.59 15.5L10 11.91L6.41 15.5L5 14.09L8.59 10.5L5 6.90997L6.41 5.49997L10 9.08997L13.59 5.49997L15 6.90997L11.41 10.5L15 14.09Z"
                  fill="#D7DCE6"/>
            </svg>
          </button>
          <tooltip v-if="!checkPermission(['manage_contacts'])" :target-ref="$refs.disabledDeactivateAll">
            <span>To be able to deactivate users from a system - you need to have the 'Manage contact's' permission in system</span>
          </tooltip>
        </div>

      </TableHeader>
      <div class="table-body"
           :class='contactsView'>
        <div
            class="table-row"
            v-for="(item, i) in users"
            :key="i"
        >
          <div class="card">
            <div class="check">
              <CustomCheckbox
                  @checked="changeCheckbox"
                  :item="item"
                  :id="item.id"
                  :labelText="item.name"
                  :input-element="item"
              />
            </div>

            <div class="name" >
              <div class="holder-info">
                <div class="img">
                  <img
                      v-if="item.avatar"
                      :src="item.avatar"
                      onerror="this.src='https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/girl_female_woman_avatar-512.png';"
                      alt=""
                  >
                  <img
                      v-else
                      src="https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/girl_female_woman_avatar-512.png"
                      alt=""
                  >
                </div>
                <div
                    :id="`goto_user_profile_${item.id}`"
                    @click="gotoProfile(item.id)"
                    class="info">
                  <span class="info-name" v-text="(item.firstName.toLowerCase()[0].toUpperCase() + item.firstName.toLowerCase().slice(1)) + ' ' + (item.lastName.toLowerCase()[0].toUpperCase() + item.lastName.toLowerCase().slice(1))"></span>
                </div>
              </div>
            </div>

            <div class="mail">
              <div class="holder-info">
                <span class="info-name">{{ item.email }}</span>
              </div>
            </div>

            <div class="roles">
              <span class="span-cases" v-if="!item.roles.length">-</span>
              <span class="span-cases" v-else-if="item.roles.length > 2">
                <div class="span-cases-wrap">
            <span
                v-text="getRoleNameFE(item.roles[0])"
                class="span-cases"
                :class="`span-cases-` + item.roles[0].toLowerCase()"
            ></span>
                <span>, </span>
                <span
                    v-text="getRoleNameFE(item.roles[1])"
                    class="span-cases"
                    :class="`span-cases-` + item.roles[1].toLowerCase()"
                ></span>
                  <span>, </span>
                <span
                    v-text="getRoleNameFE(item.roles[2])"
                    class="span-cases"
                    :class="`span-cases-` + item.roles[2].toLowerCase()"
                ></span>
              </div>
              </span>
              <span v-else-if="item.roles.length === 1"
                    v-text="getRoleNameFE(item.roles[0])"
                    class="span-cases"
                    :class="`span-cases-` + item.roles[0].toLowerCase()"
              ></span>
              <div
                  v-else
                  class="span-cases-wrap"
              >
            <span
                v-text="getRoleNameFE(item.roles[0])"
                class="span-cases"
                :class="`span-cases-` + item.roles[0].toLowerCase()"
            ></span>
                <span>, </span>
                <span
                    v-text="getRoleNameFE(item.roles[1])"
                    class="span-cases"
                    :class="`span-cases-` + item.roles[1].toLowerCase()"
                ></span>
              </div>
            </div>

            <div class="case">
              <div class="holder-info cases">
                <span class="case-span" v-if="item.caseCount >= 1">{{item.caseCount + " " + (item.caseCount === 1 ? "case" : "cases")}}</span>
                <div v-else class="holder-btn">
                  <button
                    :disabled="!checkPermission(['assign_user_to_case'])"
                    :id="`assign_case_user_${item.publicId}`"
                    @click.stop="openPopupAssignCase(item)"
                    class="btn gray-btn" title="Edit permissions">
                    <span class="btn-span">Assign Case</span>
                  </button>
                </div>
              </div>
            </div>

            <div class="holder-btn permission">
              <span :id="`disabled-edit-permissions_${item.id}`" :ref="`disabledEditPermissions_${item.id}`">
                <button
                    :disabled="currentUser === item.id || !checkPermission(['manage_individual_rights'])"
                    :id="`edit_permissions_user_${item.id}`"
                    @click="$router.push({path: `/permissions/individual/${item.id}`})"
                    class="btn gray-btn edit-permission"
                    data-title="Edit permissions"
                >
                  <span class="btn-span">Edit permissions</span>
                  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_9084_4890)">
                    <path d="M17.592 2.00249C18.2049 2.61502 18.2049 3.60839 17.592 4.22092L16.7994 5.01345L14.0547 2.26866L14.8472 1.47612C15.4601 0.863427 16.4534 0.863427 17.0661 1.47612L17.592 2.00249Z" fill="#082655"/>
                    <path d="M6.87521 9.44824L9.62 12.193L9.59444 12.2186L5.79813 13.2703L6.84964 9.47381L6.87521 9.44824Z" fill="#082655"/>
                    <path d="M17.1125 11.8643C16.8649 11.8643 16.6643 12.066 16.6643 12.3149V16.3156C16.6634 17.0619 16.0619 17.6665 15.3194 17.6674H2.74136C1.99891 17.6665 1.39742 17.0619 1.39655 16.3156V4.57374C1.39742 3.82763 1.99891 3.22286 2.74136 3.22198H6.72154C6.96914 3.22198 7.16981 3.02027 7.16981 2.77139C7.16981 2.52251 6.96914 2.3208 6.72154 2.3208H2.74136C1.50406 2.32221 0.501401 3.33005 0.5 4.57374V16.3156C0.501401 17.5593 1.50406 18.5671 2.74136 18.5686H15.3194C16.5568 18.5671 17.5594 17.5593 17.5608 16.3156V12.3149C17.5608 12.066 17.3601 11.8643 17.1125 11.8643Z" fill="#082655"/>
                    <path d="M17.3835 1.16183C16.595 0.371187 15.3188 0.371187 14.5304 1.16183L6.5328 9.20078C6.47817 9.25587 6.43842 9.32416 6.41776 9.39897L5.36624 13.2151C5.32299 13.3715 5.36694 13.5391 5.48111 13.654C5.59546 13.7688 5.76216 13.813 5.91783 13.7697L9.71431 12.7125C9.78891 12.6918 9.85703 12.6518 9.91183 12.5967L9.93723 12.571L17.9091 4.55796C18.6969 3.76626 18.6969 2.48261 17.9091 1.69092L17.3835 1.16183ZM7.08772 10.3443L8.77382 12.0393L6.4414 12.6888L7.08772 10.3443ZM9.62046 12.2528V12.2525L9.91271 12.5462L9.62046 12.2528ZM9.62046 11.6156L7.50937 9.49366L14.0549 2.91437L16.1658 5.03601L9.62046 11.6156ZM17.2752 3.9208L16.7997 4.39885L14.6889 2.27703L15.1645 1.79899C15.6023 1.35984 16.3113 1.35984 16.7492 1.79899L17.2752 2.32825C17.713 2.76793 17.713 3.48113 17.2752 3.9208Z" fill="#082655"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_9084_4890">
                    <rect width="18" height="18" fill="white" transform="translate(0.5 0.5)"/>
                    </clipPath>
                    </defs>
                  </svg>
                </button>
              </span>
              <tooltip v-if="!checkPermission(['manage_contacts'])" :target-ref="$refs[`disabledEditPermissions_${item.id}`]">
                <span>To be able to edit user permissions - you need to have the 'Manage individual rights' permission on that case</span>
              </tooltip>
            </div>
<!--            <div v-if="item.isUserActive" class="holder-btn permission">-->
            <div class="holder-btn permission">
              <div :id="`disabled-manage-contacts_${item.id}`" :ref="`disabledManageContacts_${item.id}`">
                <button
                    :disabled="currentUser === item.id || !checkPermission(['manage_contacts'])"
                    :id="`deactivate_user_${item.id}`"
                    class="btn btn-deactivate"
                    data-title="Deactivate"
                    @click.stop="confirmDeactivateUser(item)"
                >
                  <span class="btn-span">Deactivate</span>
                  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 0.499969C4.47 0.499969 0 4.96997 0 10.5C0 16.03 4.47 20.5 10 20.5C15.53 20.5 20 16.03 20 10.5C20 4.96997 15.53 0.499969 10 0.499969ZM15 14.09L13.59 15.5L10 11.91L6.41 15.5L5 14.09L8.59 10.5L5 6.90997L6.41 5.49997L10 9.08997L13.59 5.49997L15 6.90997L11.41 10.5L15 14.09Z" fill="#D7DCE6"/>
                  </svg>
              </button>
              </div>
              <tooltip v-if="!checkPermission(['manage_contacts'])" :target-ref="$refs[`disabledManageContacts_${item.id}`]">
                <span>To be able to deactivate users from a system - you need to have the 'Manage contact's' permission in system</span>
              </tooltip>
            </div>
<!--            <div v-if="!item.isUserActive" class="holder-btn permission">-->
<!--              <div :id="`disabled-manage-contacts_${item.id}`" :ref="`disabledManageContacts_${item.id}`">-->
<!--                <button-->
<!--                  :disabled="currentUser === item.id || !checkPermission(['manage_contacts'])"-->
<!--                  :id="`activate_user_${item.id}`"-->
<!--                  class="btn btn-deactivate"-->
<!--                  data-title="Activate"-->
<!--                  @click.stop="confirmActivateUser(item)"-->
<!--                >-->
<!--                  <span class="btn-span">Activate</span>-->
<!--                  <svg class="activate-icon" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                    <path d="M10 0.499969C4.47 0.499969 0 4.96997 0 10.5C0 16.03 4.47 20.5 10 20.5C15.53 20.5 20 16.03 20 10.5C20 4.96997 15.53 0.499969 10 0.499969ZM15 14.09L13.59 15.5L10 11.91L6.41 15.5L5 14.09L8.59 10.5L5 6.90997L6.41 5.49997L10 9.08997L13.59 5.49997L15 6.90997L11.41 10.5L15 14.09Z" fill="#D7DCE6"/>-->
<!--                  </svg>-->
<!--                </button>-->
<!--              </div>-->
<!--              <tooltip v-if="!checkPermission(['manage_contacts'])" :target-ref="$refs[`disabledManageContacts_${item.id}`]">-->
<!--                <span>To be able to deactivate users from a system - you need to have the 'Manage contact's' permission in system</span>-->
<!--              </tooltip>-->
<!--            </div>-->
          </div>
        </div>
        <div class="loader-bg" v-if="loadFlag">
          <div class="loader-p"></div>
        </div>
        <div v-if="!users && !searchText.length">
          <p class="no-found">No contact found, you can invite &nbsp;
            <router-link
                tag="a"
                class=""
                :class="!checkPermission(['invite_users']) ? 'disabled' : ''"
                :to="{ path: '/invite-new-contact/invite-team' }"
                exact-path
                id="link_invite_user"
            >
              new contact
            </router-link>
          </p>
        </div>
      </div>
      <pagination v-if="users.length && count !== 1"
                  v-model="currentPage"
                  :total-pages="count"
                  :link-gen="linkGen"
      ></pagination>
      <DeactivateItemPopUp
          :arrayOfCheckedUsers="checkSome"
          :subtitle="'contact'"
          ref="usersPopup"
          @deactivateArrayOfUsers="confirmDeactivateUserList"
      />
      <CommonDialog
          ref="commonDialogDeleteUser"
      >
        <svg width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M47.0015 0.776367C37.8596 0.776367 28.9231 3.48725 21.3218 8.56622C13.7206 13.6452 7.79623 20.8641 4.29777 29.3101C0.799324 37.7561 -0.11603 47.0499 1.66746 56.0161C3.45096 64.9823 7.8532 73.2183 14.3175 79.6826C20.7818 86.1469 29.0178 90.5492 37.984 92.3327C46.9503 94.1162 56.244 93.2008 64.69 89.7024C73.136 86.2039 80.355 80.2795 85.4339 72.6783C90.5129 65.0771 93.2238 56.1405 93.2238 46.9986C93.2238 34.7397 88.3539 22.9829 79.6856 14.3145C71.0172 5.6462 59.2604 0.776367 47.0015 0.776367V0.776367ZM77.1904 31.4853L39.2304 69.4164L16.8127 46.9986C16.0465 46.2324 15.616 45.1932 15.616 44.1097C15.616 43.0262 16.0465 41.987 16.8127 41.2208C17.5788 40.4546 18.618 40.0242 19.7015 40.0242C20.7851 40.0242 21.8242 40.4546 22.5904 41.2208L39.2882 57.9186L71.4704 25.7653C71.8498 25.3859 72.3002 25.0849 72.7959 24.8796C73.2915 24.6743 73.8228 24.5686 74.3593 24.5686C74.8958 24.5686 75.4271 24.6743 75.9228 24.8796C76.4184 25.0849 76.8688 25.3859 77.2482 25.7653C77.6276 26.1446 77.9285 26.595 78.1338 27.0907C78.3392 27.5864 78.4448 28.1176 78.4448 28.6541C78.4448 29.1907 78.3392 29.7219 78.1338 30.2176C77.9285 30.7133 77.6276 31.1637 77.2482 31.543L77.1904 31.4853Z"
              fill="#082655"/>
        </svg>
        <h2 class="dialog-message-title">Success!</h2>
        <p class="dialog-message-description" style="display:block;">Selected contacts were deactivated</p>
      </CommonDialog>
      <CommonDialog
        ref="commonDialogActivateUser"
      >
        <svg width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M47.0015 0.776367C37.8596 0.776367 28.9231 3.48725 21.3218 8.56622C13.7206 13.6452 7.79623 20.8641 4.29777 29.3101C0.799324 37.7561 -0.11603 47.0499 1.66746 56.0161C3.45096 64.9823 7.8532 73.2183 14.3175 79.6826C20.7818 86.1469 29.0178 90.5492 37.984 92.3327C46.9503 94.1162 56.244 93.2008 64.69 89.7024C73.136 86.2039 80.355 80.2795 85.4339 72.6783C90.5129 65.0771 93.2238 56.1405 93.2238 46.9986C93.2238 34.7397 88.3539 22.9829 79.6856 14.3145C71.0172 5.6462 59.2604 0.776367 47.0015 0.776367V0.776367ZM77.1904 31.4853L39.2304 69.4164L16.8127 46.9986C16.0465 46.2324 15.616 45.1932 15.616 44.1097C15.616 43.0262 16.0465 41.987 16.8127 41.2208C17.5788 40.4546 18.618 40.0242 19.7015 40.0242C20.7851 40.0242 21.8242 40.4546 22.5904 41.2208L39.2882 57.9186L71.4704 25.7653C71.8498 25.3859 72.3002 25.0849 72.7959 24.8796C73.2915 24.6743 73.8228 24.5686 74.3593 24.5686C74.8958 24.5686 75.4271 24.6743 75.9228 24.8796C76.4184 25.0849 76.8688 25.3859 77.2482 25.7653C77.6276 26.1446 77.9285 26.595 78.1338 27.0907C78.3392 27.5864 78.4448 28.1176 78.4448 28.6541C78.4448 29.1907 78.3392 29.7219 78.1338 30.2176C77.9285 30.7133 77.6276 31.1637 77.2482 31.543L77.1904 31.4853Z"
            fill="#082655"/>
        </svg>
        <h2 class="dialog-message-title">Success!</h2>
        <p class="dialog-message-description" style="display:block;">Selected contacts were activated</p>
      </CommonDialog>
      <InfoDialog
          ref="deactivateUserDialog"
          :title="infoDialogData.dialogTitle"
          :text="infoDialogData.dialogText"
          :confirmBtnText="infoDialogData.confirmBtnText"
          :cancelBtnText="infoDialogData.cancelBtnText"
          @onConfirm="deactivateUser"/>
      <InfoDialog
        ref="activateUserDialog"
        :title="infoDialogData.dialogTitle"
        :text="infoDialogData.dialogText"
        :confirmBtnText="infoDialogData.confirmBtnText"
        :cancelBtnText="infoDialogData.cancelBtnText"
        @onConfirm="activateUser"/>
      <InfoDialog
          ref="infoDialogDeactivateUserList"
          :title="infoDialogData.dialogTitle"
          :text="infoDialogData.dialogText"
          :confirmBtnText="infoDialogData.confirmBtnText"
          :cancelBtnText="infoDialogData.cancelBtnText"
          @onConfirm="deactivateArrayOfUsers"/>
      <AssignOnlyCasePopup ref="assignCasePopup" @submit="assignUser" :error="errorMessage" />
    </div>
  </div>
</template>

<script>
import linkGenerator from "../../constants/linkGen";
import InfoDialog from "../popups/InfoDialog";
import checkPermission from "../../utils/permission";
import CustomCheckbox from "../inputs/CustomCheckbox";
import TableHeader from "../table/TableHeader";
import DeactivateItemPopUp from "../popups/DeactivateItemPopUp";
import CommonDialog from "../popups/CommonDialog";
import AlertErrorMsg from "../profile/AlertErrorMsg";
import createDeactivateDialog, { createActivateDialog } from "../../constants/infoDialogData";
import checkAll from "../../mixins/checkAll";
import ArrowDown from "../icons/ArrowDown";
import UserIcon from "../icons/UserIcon";
import EmailIcon from "../icons/EmailIcon";
import CaseIcon from "../icons/CaseIcon";
import Tooltip from "@/components/common/Tooltip.vue";
import Pagination from "@/components/common/Pagination.vue";
import AssignOnlyCasePopup from "@/components/popups/AssignOnlyCasePopup.vue";
import getRoleName from "@/mixins/getRoleName";

export default {
  name: "ContactsEmployees",
  components: {
    Pagination,
    Tooltip,
    CaseIcon,
    EmailIcon,
    UserIcon,
    ArrowDown,
    InfoDialog,
    DeactivateItemPopUp,
    TableHeader,
    CustomCheckbox,
    CommonDialog,
    AlertErrorMsg,
    AssignOnlyCasePopup,
  },
  props: {
    title: {
      type: String
    },
    contactsView: {
      type: String
    },
    searchText: {
      type: String
    },
  },
  data() {
    return {
      infoDialogData: {},
      currentPage: 1,
      size: 8,
      count: 1,
      pageSize: 2,
      users: [],
      sortBy: 'userName',
      order: 'asc',
      loadFlag: false,
      errorMessage: '',
    }
  },
  mixins: [checkAll, getRoleName],
  computed: {
    currentUser() {
      return this.$store.state.profile.user.publicId
    }
  },
  watch: {
    searchText() {
      this.currentPage = 1;
      this.getContact();
    },
    '$route.query.page': {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.currentPage = +newVal;
          this.getContact();
        } else {
          this.currentPage = 1;
        }
      }
    },
    currentPage(newVal, oldVal) {
      if (newVal !== oldVal && +this.$router.currentRoute.query.page !== newVal) {
        this.$router.push(this.linkGen(newVal));
      }
    },
  },
  methods: {
    checkPermission,
    unselectAll() {
      this.users.forEach((item) => {
        item.isActive = false;
      })
    },
    changeCheckbox(item) {
      this.$emit('changeCheckbox', item, this.title)
    },
    openDeactivatePopup() {
      this.$refs.usersPopup.openPopup()
    },
    openDeactivatePopupUser() {
      this.$refs.commonDialogDeleteUser.openPopups();
    },
    linkGen(pageNum) {
      return linkGenerator(pageNum, '/contacts/users')
    },
    sortedBy(filterName) {
      this.order = filterName === this.sortBy && this.order === 'desc' ? 'asc' : 'desc'
      this.sortBy = filterName
      this.getContact()
    },
    fixedEncodeURIComponent (str) {
      return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
        return '%' + c.charCodeAt(0).toString(16);
      });
    },
    getContact() {
      this.$load(async () => {
        this.loadFlag = true
        await this.$api.contacts.getContactEmployees({
          page: this.currentPage,
          size: this.size,
          sortBy: this.sortBy,
          order: this.order,
          contactName: this.fixedEncodeURIComponent(this.searchText),
        }).then((res) => {
          this.users = res.data.body.content.map(i => ({
            ...i,
            // isUserActive: i.isActive,
            isActive: false,
          }))
          this.count = res.data.body.totalPages
          this.pageSize = res.data.body.size
        }, (error) => {
          if (error.response) {
            this.errorMessage = error.response.data.errors.message[0]
          } else {
            this.errorMessage = error.data.errors.message[0]
          }
          this.$refs.alert.showAlert()
        })
        setTimeout(() => {
          this.loadFlag = false
        }, 1000)
      })
    },
    gotoProfile(id) {
      let route = this.$router.resolve({name: 'User Profile', params: { slug: id}})
      window.open(route.href, '_blank');
    },
    confirmActivateUser(user) {
      this.infoDialogData = createActivateDialog('contact');
      this.$refs.activateUserDialog.openPopup(user);
    },
    confirmDeactivateUser(user) {
      this.infoDialogData = createDeactivateDialog('contact');
      this.$refs.deactivateUserDialog.openPopup(user);
    },
    confirmDeactivateUserList(userlist) {
      this.infoDialogData = createDeactivateDialog('custodians');
      this.$refs.infoDialogDeactivateUserList.openPopup(userlist);
    },
    activateUser(user) {
      this.$load(async () => {
        await this.$api.contacts.activateUser(user.id).then(() => {
          this.$refs.activateUserDialog.closePopup();
          this.$refs.commonDialogActivateUser.openPopups();
          this.getContact()
        }, (error) => {
          if (error.response) {
            this.errorMessage = error.response.data.errors.message[0]
          } else {
            this.errorMessage = error.data.errors.message[0]
          }
          this.$refs.alert.showAlert()
        })
      })
    },
    deactivateUser(user) {
      this.$load(async () => {
        await this.$api.contacts.deactivateUser(user.id).then(() => {
          this.$refs.deactivateUserDialog.closePopup();
          this.openDeactivatePopupUser()
          this.getContact()
        }, (error) => {
          if (error.response) {
            this.errorMessage = error.response.data.errors.message[0]
          } else {
            this.errorMessage = error.data.errors.message[0]
          }
          this.$refs.alert.showAlert()
        })
      })
    },
    deactivateArrayOfUsers(arrayOfCheckedUsers) {
      const result = arrayOfCheckedUsers.map(user => user.id)
      result.toString()
      this.$load(async () => {
        this.loadFlag = true
        await this.$api.contacts.deactivateUser(result).then(() => {
          this.$refs.infoDialogDeactivateUserList.closePopup();
          this.openDeactivatePopupUser()
          this.getClients()
        }, (error) => {
          if (error.response) {
            this.errorMessage = error.response.data.errors.message[0]
          } else {
            this.errorMessage = error.data.errors.message[0]
          }
          this.$refs.alert.showAlert()
        })
      })
    },
    openPopupAssignCase(user) {
      this.$refs.assignCasePopup.openPopup(user);
    },
    assignUser(form) {
      form.cases.forEach((caseId) => {
        this.$load(async () => {
          await this.$api.cases.assignUserCase({
            caseId: caseId,
            email: form.email,
            roles: form.roles,
            message: null,
          }).then(() => {
            this.$refs.assignCasePopup.closePopup()
            this.openDialogFormSuccess()
            this.$emit('getCasesUnemployed', null)
            this.isSending = false
          }, (error) => {
            this.isSending = false
            if (error) {
              if (error.response) {
                this.errorMessage = error.response.data.errors.message[0]
              } else {
                this.errorMessage = error.data.errors.message[0]
              }
            }
          })
        })
      });
    },
  },
  mounted() {
    this.currentPage = +this.$route.query.page || 1;
    this.getContact();
  }
}
</script>

<style lang="scss" scoped>

.wrapper {
  display: block;
}

.table-header {
  max-height: 54px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 0 15px 22px;
}

.table-header.active {
  justify-content: space-between;
  padding-right: 13px;
  background-color: var(--accent-color);
}

.table-header-box svg path {
  fill: var(--input-placeholder-value-color);
  transition: all 0.3s;
}

.table-header-box:hover svg:first-child path {
  fill: var(--accent-color);
}

.table-header {
  //justify-content: space-between;
}

.list .check {
  display: flex;
  align-items: center;
  width: 5%;
}

.name span {
  cursor: pointer;
}

.table-body .mail span {
  color: var(--light-gray);
  font-size: 12px;
  font-weight: 400;
}

.list .mail, .list .roles, .list .case {
  width: 22%;
}

.list .name {
  width: 18%;
}

.list .mail {
  width: 18%;
}

.list .roles {
  width: 15%;
}

.list .phone {
  width: 15%;
}

.list .device {
  width: 10%;
}

.list .case {
  width: 7%;
}

.list .permission {
  min-width: 340px;
  width: 18%;
}

.clients .list .name {
  //width: 25%;
}

.case-span {
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
  color: var(--secondary-text-color);
}

.list .holder-btn {
  min-width: 103px;
  display: flex;
  justify-content: flex-end;
}

.clients .list .holder-btn {
  width: 20%;
  justify-content: flex-end;
}

.list .holder-btn .edit-permission {
  padding: 7px 28px;
  margin-right: 14px;
}

.list .holder-btn .btn-deactivate {

}

.list .card {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.list .table-row {
  display: flex;
  align-items: center;
  padding: 22px 0 22px 22px;
  transition: all .5s;
}

.list .table-row:hover {
  box-shadow: 0 4px 20px rgba(77, 106, 147, 0.15);
}

.list .holder-info {
  display: flex;
  align-items: center;
}

.list .img {
  width: 30px;
  margin-right: 10px;
}

.list .info-name {
  width: 200px;
}

.info-name {
  font-weight: 500;
  font-size: 14px;
  color: #333;
  transition: all .3s;
}

.info-name:hover {
  color: var(--accent-color);
}

.list .info {
  display: flex;
  flex-direction: column;
}

.info-mail {
  font-weight: 400;
  font-size: 12px;
  color: var(--light-gray);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.list .btn-span {
  margin-right: 12px;
  font-weight: 500;
  font-size: 12px;
  text-transform: none;
  color: #373C44;
}

.telephone {
  font-weight: 400;
  font-size: 10px;
  color: var(--secondary-text-color);
}

.holder-device {
  margin-right: 10px;
}

.role-data {
  display: inline-block;
  min-width: 95px;
  padding: 8px 8px;
  text-align: center;
  border-radius: 20px;
  margin-right: 37px;
}

.admin {
  background-color: #F1F9E6;
  color: var(--admin-color);
}

.lawyer {
  background-color: #FEF0EE;
  color: #EE6352;
}

.associates {
  background-color: #F5F3FC;
  color: #9B7EDE;
}

// grid
.table-header.grid {
  margin: 0 0 15px;
}

.table-header.grid .check {
  width: 5%;
}

.table-header.grid .name {
  width: 30%;
}

.table-header.grid .roles {
  width: 15%;
}

.table-header.grid .phone {
  display: none;
}

.table-header.grid .holder-btn {
  display: flex;
  justify-content: space-between;
  width: 52%;
}

.grid .device .holder-info {
  display: flex;
  flex-direction: row;
}

.grid .phone, .grid .mail {
  margin-bottom: 10px;
}

.table-header.grid .name,
.table-header.grid .roles,
.table-header.grid .holder-btn,
.table-header.grid .phone,
.table-header.grid .mail,
.table-header.grid .case,
.table-header.grid .permission,
.table-header.grid .device
{
  display: none;
}

.grid {
  display: flex;
  //flex-wrap: wrap;
  margin: 0 -10px;
  margin-bottom: 20px;
}

.grid .table-header {
  margin-bottom: 20px;
}

.grid .table-row {
  width: 20%;
  padding: 0 10px;
  margin-bottom: 20px;
}

.grid .card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 15px;
  box-shadow: 0 0 15px rgba(5, 51, 48, 0.06);
  border-radius: 5px;
  transition: all 0.5s;
  cursor: pointer;
  position: relative;
}

.grid .card:hover {
  box-shadow: 0 4px 20px rgba(77, 106, 147, 0.28);
}

.grid.table-body .check {
  align-self: start;
}

.grid .img {
  width: 45px;
  height: 45px;
  margin: 0 auto;
  margin-bottom: 7px;
}

.grid .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.grid .holder-info span {
  max-width: 210px;
}

.grid .info {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 12px;
}

.grid .holder-info {
  flex-wrap: wrap;
}

.grid .info span {
  margin-bottom: 10px;
}

.grid .info-mail {
  //display: none;
}

.grid .span-cases-wrap {
  text-align: center;
}

.table-body.grid {
  flex-wrap: wrap;
  min-height: auto;
}

.table-body.grid .roles {
  margin: 0 auto;
  margin-bottom: 10px;
}

.table-body.grid .role-data {
  margin: 0;
  margin-bottom: 10px;
}

.table-body.grid .holder-btn {
  position: absolute;
  top: 20px;
  right: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.table-body.grid .holder-btn span {
  max-width: initial;
}

.table-body.grid .holder-btn .btn {
  min-width: auto;
  padding: 0;
  border: none;
  background: var(--white-color);
  height: auto;
}

.table-body.grid .btn-span {
  display: none;
}

.table-body.grid .holder-btn span:first-child {
  margin-right: 30px;
}

.table-body.grid .holder-btn .btn svg path {
  fill: var(--input-placeholder-value-color);
  transition: all 0.3s;
}

.table-body.grid .holder-btn .gray-btn svg path {
  fill: var(--accent-color);
}

.table-body.grid .holder-btn .btn:hover svg path {
  fill: var(--bright-accent-color);
}

.btn-deactivate {
  width: 136px;
  height: 35px;
  min-width: inherit;
  font-size: 12px;
  font-weight: 500;
}

.table-body.grid .holder-btn .btn {
  width: auto;
}

.edit-permission:disabled,
.btn-deactivate:disabled {
  pointer-events: none !important;
}

.table-header .btn {
  transition: none;
}

.active .btn:hover {
  background-color: white;
}

.label-checkbox span {
  color: white;
}

.grid .btn-span {
  margin-right: 12px;
  font-size: 14px;
  text-transform: none;
  color: var(--secondary-text-color);
}

.roles .span-cases {
  text-decoration: none;
  font-size: 12px;
  line-height: 19px;
  font-weight: 500;
}

.span-cases-wrap {
  display: inline-block;
  text-align: left;
  border-radius: 20px;
  margin-right: 10px;
  min-width: 135px;
}

.span-cases-wrap .span-cases {
  padding: 0;
  font-style: normal;
  font-size: 12px;
  line-height: 19px;
  min-width: auto;
}

.span-cases-wrap {
  border: none;
  padding: 0;
}

.dialog-message-title {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  color: #292B2F;
  text-align: center;
  margin: 0;
  display: block;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 25px;
}
.dialog-message-description {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #696F79;
  display: block;
  width: 100%;
  margin-top: 20px;
}

.checkbox-wrap span {
  top: 50%;
  transform: translateY(-50%);
}

.checkbox-wrap .checkbox-unselect {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  text-transform: initial;
  cursor: pointer;
}

.custom-checkbox-group .label-checkbox {
  margin-bottom: 0;
}

[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}
[data-title]:after {
  content: attr(data-title);
  background: #FFFFFF;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.07);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #696F79;
  position: absolute;
  bottom: -1.6em;
  padding: 7px 14px;
  left: 100%;
  white-space: nowrap;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
}
[data-title] {
  position: relative;
}

.span-cases-wrap .span-cases:first-child {
  margin-right: 0;
}

.empty-contacts {
  width: 100%;
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.not-found {
  margin-top: 50px;
  text-align: center;
}

.not-found > div,
.not-found > div > span {
  color: var(--light-gray);
}

.not-found > div > span {
  color: var(--accent-color);
  text-decoration: underline;
  cursor: pointer;
}

.invite-user-btn {
  font-size: 12px;
  line-height: 19px;
  padding: 7px 30px;
  min-width: 120px;
  background-color: var(--white-color);
  color: var(--accent-color);
  border: 1px solid var(--accent-color);
}

.invite-user-btn:hover {
  background-color: var(--accent-color);
  color: var(--white-color);
}

.activate-icon {
  transform: rotate(-45deg);
}

</style>
