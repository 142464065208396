<template>
  <div class="holder-table">
    <AlertErrorMsg
      :title="errorMessage"
      class="page-alert-error"
      ref="alert"
    />
    <div class="table-body">
      <div
        class="table-row"
        v-for="(item, index) in localContactsList"
        :key="index"
      >
        <div class="card" @click="onContactClick(item, index)">

          <div class="full-name">
            <div class="holder-info">
              <span>{{ item.firstName + " " + item.lastName }}</span>
            </div>
          </div>

          <div class="phone">
            <div class="holder-info">
              <span>{{ item.phone ? item.phone : "No phone number" }}</span>
            </div>
          </div>

          <div class="card-arrow">
            <div class="holder-info">
              <arrowDown class="arrow" :class="{ 'active-arrow': item.isActive }" />
            </div>
          </div>

        </div>

        <transition
          @before-enter="beforeEnter"
          @enter="enter"
          @leave="leave"
        >
          <div
            v-if="item.isActive"
            class="contact-details-wrapper"
          >
            <div class="email">
              <span>Email</span>
              <span>{{ item.email}}</span>
            </div>

            <div class="field1">
              <span>field 1</span>
              <span>{{ item.field1}}</span>
            </div>

            <div class="field2">
              <span>field 2</span>
              <span>{{ item.field2}}</span>
            </div>

            <div class="field3">
              <span>field 3</span>
              <span>{{ item.field3}}</span>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import AlertErrorMsg from "@/components/profile/AlertErrorMsg.vue";
import reformatDate from "@/mixins/reformatDate";
import reformatDateToTime from "@/mixins/reformatDateToTime";
import reformatMillis from "@/mixins/reformatMillis";
import capitalizeFirstLetter from "@/mixins/capitalizeFirstLetter";
import parseDate from "@/mixins/parseDate";
import ArrowDown from "@/components/icons/ArrowDown.vue";

export default {
  name: 'ContactsListAdvanced',
  components: {
    ArrowDown,
    AlertErrorMsg,
  },
  props: {
    contactsList: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      sortBy: 'email',
      order: 'asc',
      errorMessage: '',
      localContactsList: '',
    }
  },
  watch: {
    contactsList: {
      immediate: true,
      deep: true,
      handler(newData) {
        if (newData) {
          this.localContactsList = newData.map(contact => ({
            ...contact,
            isActive: false,
          }));
        }
      }
    }
  },
  mixins: [reformatDate, reformatDateToTime, reformatMillis, capitalizeFirstLetter, parseDate],
  methods: {
    sortedBy(filterName) {
      this.order = filterName === this.sortBy && this.order === 'desc' ? 'asc' : 'desc'
      this.sortBy = filterName
    },
    onContactClick(item, index) {
      this.localContactsList[index].isActive = !item.isActive;
    },
    beforeEnter(el) {
      el.style.height = '0';
      el.style.opacity = '0';
      el.style.padding = '0 32px';
      el.style.overflow = 'hidden';
    },
    enter(el, done) {
      const { scrollHeight } = el;
      el.style.transition = 'all 0.7s ease';
      el.style.height = `${scrollHeight}px`;
      requestAnimationFrame(() => {
        el.style.height = '';
        el.style.overflow = '';
        el.style.padding = '';
        el.style.opacity = '';
      });
      setTimeout(() => {
        done();
      }, 700);
    },
    leave(el, done) {
      el.style.transition = 'all 0.7s ease';
      el.style.height = `${el.scrollHeight}px`;
      el.style.overflow = 'hidden';
      requestAnimationFrame(() => {
        el.style.height = '0';
        el.style.opacity = '0';
        el.style.padding = '0 32px';
      });
      setTimeout(() => {
        done();
      }, 700);
    },
  }
}
</script>

<style lang="scss" scoped>

.first-name {
  min-width: 25%;
}

.last-name {
  min-width: 25%;
}

.phone {
  min-width: 43%;
}

.card {
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.5s;
  padding: 21px 0 22px 32px;
  border-top: 1px solid var(--case-btn-border-color);
}

.card:hover {
  box-shadow: 0 4px 20px rgba(77, 106, 147, 0.28);
}

.table-row:first-child .card {
  border-top: none;
}

.table-row:last-child:last-child {
  border-top: none;
  border-bottom: 1px solid var(--case-btn-border-color);
}

.holder-info {
  display: flex;
  align-items: center;
  color: var(--secondary-text-color);
}

.holder-info span {
  font-size: 12px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
  color: var(--secondary-text-color);
}

.arrow {
  transition: transform 0.3s;
}

.card-arrow .holder-info .arrow {
  width: 21px;
  height: 21px;
}

.active-arrow {
  transform: rotate(-180deg);
}

.table-header-box:hover .header-table-text {
  text-decoration: none;
}

.contact-details-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px;
  border-top: 1px solid var(--case-btn-border-color);
}
.contact-details-wrapper > * {
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 45px;
}

.contact-details-wrapper > * >:first-child {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  color: var(--secondary-black-color);
}

.contact-details-wrapper > * >:nth-child(2) {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: left;
  color: var(--secondary-text-color);
}
</style>