<template>
  <tbody>
    <tr v-for="(user, index) in usersList"
        :key="index">
      <td class="holder-checkbox">
        <CustomCheckbox
            :id="user.publicId"
            :labelText="'text'"
            :inputElement="user"
        />
      </td>
      <td>
        <div
            :id="`goto_user_profile_${user.publicId}`"
            @click="gotoProfile(user.publicId)"
            class="holder-info name">
          <div class="img">
            <img
                v-if="user.avatar"
                :src="user.avatar"
                onerror="this.src='https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/girl_female_woman_avatar-512.png';"
                alt=""
            >
            <img
                v-else
                src="https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/girl_female_woman_avatar-512.png"
                alt=""
            >
          </div>
          <div class="info">
            <span
                class="info-name">{{ capitalizeFirstLetter(user.firstName) }} {{ capitalizeFirstLetter(user.lastName) }}</span>
          </div>
        </div>
      </td>
      <td>
        <span class="member-phone">{{ user.phoneNumber }}</span>
      </td>
      <td>
        <span class="member-duty">
          <span
            v-for="(role, index) in user.roles"
            :key="role"
          >
            {{ getRoleNameFE(role) }}
            <span v-if="user.roles.length > 1 && user.roles.length !== index">, </span>
          </span>
        </span>
      </td>
      <td>
        <span class="registration-date-text">{{ parseDate(user.registrationDate) }}</span>
      </td>
      <td>
        <div class="holder-btns" :id="`disabled-edit-permissions_${user.publicId}`" :ref="`disabledEditPermissions_${user.publicId}`">
          <button
              :id="`edit_permission_user_${user.publicId}`"
              :disabled="currentUser === user.publicId || !checkPermission(['manage_individual_rights'])"
              @click="$router.push({path: `/permissions/case/${$route.params.slug || $route.params.case_id}/${user.publicId}`})"
              class="btn edit-btn">
            <span class="btn-span">Edit permissions</span>
            <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M16.6125 11.8647C16.3649 11.8647 16.1643 12.0664 16.1643 12.3153V16.316C16.1634 17.0623 15.5619 17.6669 14.8194 17.6678H2.24136C1.49891 17.6669 0.897421 17.0623 0.896546 16.316V4.57412C0.897421 3.82801 1.49891 3.22324 2.24136 3.22236H6.22154C6.46914 3.22236 6.66981 3.02065 6.66981 2.77177C6.66981 2.52289 6.46914 2.32118 6.22154 2.32118H2.24136C1.00406 2.32259 0.00140085 3.33043 0 4.57412V16.316C0.00140085 17.5597 1.00406 18.5675 2.24136 18.5689H14.8194C16.0568 18.5675 17.0594 17.5597 17.0608 16.316V12.3153C17.0608 12.0664 16.8601 11.8647 16.6125 11.8647Z"
                  fill="#082655"/>
              <path
                  d="M16.8837 1.162C16.0952 0.371355 14.819 0.371355 14.0307 1.162L6.03302 9.20094C5.97838 9.25604 5.93863 9.32433 5.91797 9.39913L4.86646 13.2152C4.8232 13.3717 4.86716 13.5393 4.98133 13.6542C5.09567 13.769 5.26237 13.8131 5.41804 13.7698L9.21453 12.7127C9.28912 12.6919 9.35724 12.652 9.41205 12.5969L9.43744 12.5712L17.4093 4.55813C18.1971 3.76643 18.1971 2.48278 17.4093 1.69109L16.8837 1.162ZM6.58793 10.3445L8.27403 12.0395L5.94161 12.689L6.58793 10.3445ZM9.12067 12.253V12.2526L9.41292 12.5464L9.12067 12.253ZM9.12067 11.6158L7.00959 9.49383L13.5551 2.91454L15.666 5.03617L9.12067 11.6158ZM16.7755 3.92097L16.2999 4.39901L14.1891 2.2772L14.6647 1.79916C15.1025 1.36001 15.8115 1.36001 16.2494 1.79916L16.7755 2.32842C17.2132 2.7681 17.2132 3.48129 16.7755 3.92097Z"
                  fill="#082655"/>
            </svg>
          </button>
        </div>
        <tooltip v-if="!checkPermission(['manage_individual_rights'])" side="bottom" :target-ref="$refs[`disabledEditPermissions_${user.publicId}`]">
          <span>To be able to edit user permissions - you need to have the 'Manage individual rights' permission on that case</span>
        </tooltip>
      </td>
      <td>
        <div :id="`deactivate-user_${user.publicId}`" :ref="`deactivateUser_${user.publicId}`">
          <button
              :id="`deactivate_user_${user.publicId}`"
              @click.stop="$emit('confirmDeactivateUser', user)"
              :disabled="currentUser === user.publicId || !checkPermission(['assign_user_to_case'])"
              class="btn btn-deactivate">
            <span class="btn-span">Deactivate</span>
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M10 0.499969C4.47 0.499969 0 4.96997 0 10.5C0 16.03 4.47 20.5 10 20.5C15.53 20.5 20 16.03 20 10.5C20 4.96997 15.53 0.499969 10 0.499969ZM15 14.09L13.59 15.5L10 11.91L6.41 15.5L5 14.09L8.59 10.5L5 6.90997L6.41 5.49997L10 9.08997L13.59 5.49997L15 6.90997L11.41 10.5L15 14.09Z"
                  fill="#D7DCE6"/>
            </svg>
          </button>
        </div>
        <tooltip  v-if="!checkPermission(['manage_individual_rights'])" side="bottom" :target-ref="$refs[`deactivateUser_${user.publicId}`]">
          <span>To be able to deactivate users from a case - you need to have the 'Assign user to case' permission on that case</span>
        </tooltip>
      </td>
    </tr>
  </tbody>
</template>

<script>
import checkPermission from "../../../utils/permission";
import '@/assets/css/table.css';
import CustomCheckbox from "../../inputs/CustomCheckbox";
import parseDate from "../../../mixins/parseDate";
import capitalizeFirstLetter from "../../../mixins/capitalizeFirstLetter";
import Tooltip from "@/components/common/Tooltip.vue";
import getRoleName from "@/mixins/getRoleName";

export default {
  name: 'CaseTableItem',
  emits: ['confirmDeactivateUser'],
  components: {
    Tooltip,
    CustomCheckbox,
  },
  props: {
    casesManager: {
      type: Object
    },
    usersList: {
      type: Array
    },
    loadFlag: {
      type: Boolean
    }
  },
  data() {
    return {
      imgAvatarDefault: 'https://cdn-icons.flaticon.com/png/512/3177/premium/3177440.png?token=exp=1638452612~hmac=b9deb411c3c774d6b1128693a1eab123',
      el: {
        isActive: true
      },
    }
  },
  mixins: [parseDate, capitalizeFirstLetter, getRoleName],
  computed: {
    currentUser() {
      return this.$store.state.profile.user.publicId
    }
  },
  methods: {
    checkPermission,
    gotoProfile(id) {
      let route = this.$router.resolve({name: 'User Profile', params: {slug: id}})
      window.open(route.href, '_blank');
    }
  },
}
</script>

<style scoped>

.holder-info.name {
  cursor: pointer;
}

.info {
  width: 100%;
  max-width: calc(100% - 40px);
}

.info-name {
  transition: all 0.3s;
}

.info-name:hover {
  color: var(--accent-color);
}

.member-phone {
  display: block;
  font-size: 12px;
  font-weight: 500;
  color: var(--secondary-text-color);
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}

.member-duty {
  display: block;
  font-size: 12px;
  font-weight: 500;
  color: var(--secondary-text-color);
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}

.registration-date-text {
  display: block;
  font-size: 12px;
  font-weight: 500;
  color: var(--secondary-text-color);
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}

.btn-deactivate {
  min-width: unset;
  width: 100%;
  max-width: 146px;
  min-height: unset;
  padding: 6px 20px;
  font-size: 14px;
  line-height: 14px;
  justify-content: space-between;
}

.btn-span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: calc(100% - 25px);
}

.edit-btn {
  padding: 7px 20px;
  min-width: unset;
  justify-content: space-between;
  width: 100%;
  max-width: 180px;
  border-color: var(--case-filters-border-color);
  background-color: var(--white-color);
  font-size: 14px;
  line-height: 14px;
  color: var(--secondary-text-color);
}

.edit-btn svg path {
  fill: var(--accent-color);
}

.case-user-table.non-border .table-row:last-child {
  border-bottom: none;
}

.holder-btns .btn-deactivate:disabled svg path {
  fill: var(--white-color);
}

</style>
