<template>
  <div class="wrapper">
    <Sidebar />
    <div class="content-wrapper">
      <HeaderRegistered
        :without-route-name="true"
        :return-path="true"
        :sub-title="caseTitle"
      />
      <main class="main">
        <AlertErrorMsg
            :title="errorMessage"
            class="page-alert-error"
            ref="alert"
        />
        <router-view @show-error="setErrorMessage" />

<!--        <section-->
<!--            class="messages-section"-->
<!--            :class="isAdvancedFilter || isAdvancedFilterApplied ? 'advanced-filter' : 'simple-filter'"-->
<!--        >-->

<!--          <div class="top-bar">-->
<!--            <div v-show="!isAdvancedFilter && !isAdvancedFilterApplied" class="top-bar-wrapper">-->
<!--              <div class="bar-group bar-group-filter">-->
<!--                <div class="holder-select-users">-->
<!--                  <label-->
<!--                      :for="`sort_users_case_${caseId}`"-->
<!--                  >Name</label>-->
<!--                  <CustomSelect-->
<!--                      :id="`sort_users_case_${caseId}`"-->
<!--                      v-if="isLoad"-->
<!--                      :default="usersName"-->
<!--                      :typeSelect="'filterUser'"-->
<!--                      v-model="currentUser"-->
<!--                      :custom-class="'selectMessages'"-->
<!--                      :options="users" />-->
<!--                </div>-->
<!--                <div class="holder-select-messanger">-->
<!--                  <label-->
<!--                      :for="`sort_messengers_case_${caseId}`"-->
<!--                  >Messenger</label>-->
<!--                  <CustomSelect-->
<!--                      :id="`sort_messengers_case_${caseId}`"-->
<!--                      v-if="messengers.length"-->
<!--                      :default="'All'"-->
<!--                      :typeSelect="'filterMessengers'"-->
<!--                      v-model="currentMessengers"-->
<!--                      :custom-class="'selectMessages'"-->
<!--                      :options="messengers" />-->
<!--                </div>-->
<!--                <div class="holder-datepicker">-->
<!--                  <label-->
<!--                      :for="`sort_messengers_case_${caseId}`"-->
<!--                  >Date</label>-->
<!--                  <date-picker-->
<!--                      ref="datePicker"-->
<!--                      v-model="dataRange"-->
<!--                      range-->
<!--                      id="messengers-date-picker"-->
<!--                      :format="'DD/MM/YYYY'"-->
<!--                      :range-separator="' - '"-->
<!--                      :editable="false"-->
<!--                      :input-class="'notifications-input'"-->
<!--                      :popup-class="'notifications-popup'"-->
<!--                      :placeholder="placeholderDate"-->
<!--                  >-->
<!--                    <slot name="icon-calendar">-->
<!--                      <svg id="icon-calendar" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                        <path d="M20.1453 10.9137C23.3453 12.8528 24.3654 17.0156 22.4238 20.2115C21.2063 22.2157 19.0373 23.448 16.6903 23.4688C14.5906 23.473 12.6115 22.4896 11.3484 20.8145C10.422 19.644 9.92492 18.1921 9.93978 16.7002C9.95443 12.987 12.9724 9.98461 16.6903 9.98461C17.9052 9.97435 19.0998 10.2955 20.1453 10.9137Z" fill="#0FC7D2"/>-->
<!--                        <path d="M20.1444 7.72852V10.9138C19.0989 10.2957 17.9043 9.97441 16.6895 9.98472C12.9716 9.98467 9.9536 12.9871 9.93895 16.7003C9.9241 18.1922 10.4212 19.6441 11.3475 20.8146H2.12537C1.24472 20.8146 0.530762 20.1015 0.530762 19.222V7.72857H20.1444V7.72852Z" fill="white"/>-->
<!--                        <path d="M20.1444 3.87973V7.72856H0.530762V3.87973C0.530762 3.00017 1.24472 2.28711 2.12537 2.28711H4.30468V3.93283H6.82949V2.28711H13.8192V3.93283H16.344V2.28711H18.5499C19.4305 2.28711 20.1444 3.00012 20.1444 3.87973Z" fill="#0FC7D2"/>-->
<!--                        <path d="M16.3446 2.28708V3.9328H13.8198V2.28708V0.455566H16.3446V2.28708Z" fill="#0FC7D2"/>-->
<!--                        <path d="M6.82994 2.28708V3.9328H4.30518V2.28708V0.455566H6.82994V2.28708Z" fill="#0FC7D2"/>-->
<!--                        <path d="M11.3217 21.3723H2.12615C0.951924 21.3723 0 20.4216 0 19.2488V3.85348C0 2.68072 0.951924 1.72998 2.12615 1.72998H4.30545V2.79173H2.12615C1.53901 2.79173 1.06307 3.26707 1.06307 3.85348V19.2488C1.06307 19.8352 1.53901 20.3105 2.12615 20.3105H11.3217V21.3723Z" fill="#0FC7D2"/>-->
<!--                        <path d="M20.756 10.675V3.85327C20.756 2.68534 19.7195 1.86248 18.5501 1.86248H16.7695V0.429186C16.8013 0.226418 16.6625 0.036301 16.4595 0.00449635C16.4213 -0.00149878 16.3824 -0.00149878 16.3443 0.00449635H13.8194C13.5271 0.00449635 13.3145 0.137202 13.3145 0.429186V1.86253H7.4676V0.429186C7.4676 0.137202 7.12209 0.00449635 6.82974 0.00449635H4.30498C4.01263 0.00449635 3.74689 0.137202 3.74689 0.429186V3.93293C3.75915 4.24021 3.99829 4.49038 4.30498 4.5169H6.82979C7.15322 4.49577 7.41846 4.25291 7.46765 3.93293V2.92428H13.3146V3.93293C13.299 4.23127 13.5217 4.48886 13.8195 4.5169H16.3444C16.6367 4.5169 16.7696 4.22492 16.7696 3.93293V2.92428H18.5502C19.1349 2.92428 19.693 3.26935 19.693 3.85332V7.17127H0.557617V8.23302H19.6929V10.0911C16.052 8.41973 11.7439 10.0128 10.0705 13.6492C9.6264 14.6142 9.40014 15.6649 9.40772 16.727C9.43666 20.7317 12.6801 23.971 16.6898 23.9999C20.7115 24.0152 23.9842 20.7714 23.9995 16.7547C24.0087 14.3128 22.7905 12.0293 20.756 10.675ZM6.40452 3.45515H4.80991V1.06624H6.40452V3.45515ZM15.7064 3.45515H14.3776V1.06624H15.7064V3.45515ZM16.6898 22.9382C14.7519 22.9342 12.9257 22.032 11.7464 20.4962C9.66217 17.7697 10.1856 13.8718 12.9155 11.7901C15.6455 9.70843 19.5482 10.2312 21.6325 12.9577C23.7167 15.6843 23.1933 19.5821 20.4634 21.6638C19.3794 22.4904 18.0535 22.9381 16.6898 22.9382Z" fill="#0FC7D2"/>-->
<!--                        <path d="M19.1882 17.7886H16.3445C16.051 17.7886 15.813 17.5509 15.813 17.2577V13.5416C15.813 13.2484 16.051 13.0107 16.3445 13.0107C16.6381 13.0107 16.8761 13.2484 16.8761 13.5416V16.7269H19.1882C19.4667 16.7118 19.7047 16.9251 19.7198 17.2032C19.7207 17.2214 19.7207 17.2395 19.7198 17.2577C19.7198 17.5509 19.4818 17.7886 19.1882 17.7886Z" fill="white"/>-->
<!--                      </svg>-->
<!--                    </slot>-->
<!--                  </date-picker>-->
<!--                </div>-->

<!--              </div>-->
<!--              <div class="bar-group bar-group-layout">-->
<!--                <div class="holder-layout-checkbox">-->
<!--                  <div class="custom-checkbox-group">-->
<!--                    <div class="checkbox-wrap">-->
<!--                      <input-->
<!--                          id="change_view_messages"-->
<!--                          class="input-checkbox"-->
<!--                          type="checkbox"-->
<!--                          v-model="groupCheckbox"-->
<!--                          @change="changeCheckbox"-->
<!--                      >-->
<!--                      <label for="change_view_messages" class="label-checkbox">-->
<!--                        <span>Group chats</span>-->
<!--                      </label>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                &lt;!&ndash;                  <div class="holder-search">&ndash;&gt;-->
<!--                &lt;!&ndash;                    <button class="btn">Search</button>&ndash;&gt;-->
<!--                &lt;!&ndash;                  </div>&ndash;&gt;-->
<!--              </div>-->
<!--            </div>-->
<!--            <div v-show="isAdvancedFilter && isAdvancedFilterApplied" class="filters-amount">-->
<!--              <div v-if="filtersAmountApplied">-->
<!--                {{ filtersAmountApplied }} {{ filtersAmountApplied === 1 ? 'Filter' : 'Filters' }} applied-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="settings-wrapper">-->
<!--              <template v-if="isAdvancedFilterApplied || isAdvancedFilter">-->
<!--                <div v-if="isAdvancedFilter" class="filter-types">-->
<!--                  <button-->
<!--                      :class="{selected: !filterTypeSaved}"-->
<!--                      class="filter-type"-->
<!--                      @click="filterTypeSaved = false"-->
<!--                  >-->
<!--                    Default-->
<!--                  </button>-->
<!--                  <button-->
<!--                      :class="{selected: filterTypeSaved}"-->
<!--                      class="filter-type"-->
<!--                      @click="filterTypeSaved = true"-->
<!--                  >-->
<!--                    Saved-->
<!--                  </button>-->
<!--                </div>-->
<!--                <button-->
<!--                    class="btn device-configure"-->
<!--                    @click="$router.push({ name: 'Configure Device', query: { clientId } })"-->
<!--                >-->
<!--                  Configure Device-->
<!--                </button>-->
<!--              </template>-->
<!--              <div class="advanced-change-button-wrapper" :class="{open: isAdvancedFilterApplied || isAdvancedFilter}">-->
<!--                <button v-if="stringifiedRules" class="advanced-change-button add-to-favorites" @click="openSaveFilterPatternPopup">-->
<!--                  <svg width="16" height="23" viewBox="0 0 16 23" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                    <path d="M15.9221 0.09375V22.3134C15.9219 22.5357 15.7975 22.7394 15.5996 22.8409C15.4017 22.9424 15.1636 22.9248 14.9829 22.795L7.99416 17.7856L1.00567 22.795C0.824907 22.9248 0.586905 22.9424 0.388995 22.8409C0.191085 22.7394 0.066638 22.5357 0.0664062 22.3134V0.09375H15.9221Z" fill="#082655"/>-->
<!--                  </svg>-->

<!--                  <span>Add To Favorites</span>-->
<!--                </button>-->
<!--                <button-->
<!--                    v-if="isAdvancedFilterApplied || isAdvancedFilter"-->
<!--                    :disabled="!isAdvancedFilterApplied"-->
<!--                    class="reset-filters"-->
<!--                    @click="resetFilters"-->
<!--                >-->
<!--                  <svg fill="none" height="28" viewBox="0 0 28 28" width="28" xmlns="http://www.w3.org/2000/svg">-->
<!--                    <path d="M14 2.3335C7.54833 2.3335 2.33333 7.5485 2.33333 14.0002C2.33333 20.4518 7.54833 25.6668 14 25.6668C20.4517 25.6668 25.6667 20.4518 25.6667 14.0002C25.6667 7.5485 20.4517 2.3335 14 2.3335ZM19.8333 18.1885L18.1883 19.8335L14 15.6452L9.81166 19.8335L8.16666 18.1885L12.355 14.0002L8.16666 9.81183L9.81166 8.16683L14 12.3552L18.1883 8.16683L19.8333 9.81183L15.645 14.0002L19.8333 18.1885Z" fill="#FF9537" />-->
<!--                  </svg>-->
<!--                  Reset Filters-->
<!--                </button>-->
<!--                <button @click="changeFilterView" class="advanced-change-button">-->
<!--                  <svg :class="{'advanced-mode': isAdvancedFilter}" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                    <path d="M13.7236 19.6382L10.7236 21.1382C10.3912 21.3044 10 21.0627 10 20.691V13.7071C10 13.5745 9.94732 13.4473 9.85355 13.3536L4.14645 7.64645C4.05268 7.55268 4 7.4255 4 7.29289V5C4 4.72386 4.22386 4.5 4.5 4.5H19.5C19.7761 4.5 20 4.72386 20 5V7.29289C20 7.4255 19.9473 7.55268 19.8536 7.64645L14.1464 13.3536C14.0527 13.4473 14 13.5745 14 13.7071V19.191C14 19.3804 13.893 19.5535 13.7236 19.6382Z" stroke="#082655"/>-->
<!--                  </svg>-->

<!--                  <span>Advanced</span>-->

<!--                  <svg :class="{'advanced-mode-arrow': isAdvancedFilter}" width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                    <path d="M7.99059 12.498C7.79067 12.4984 7.59692 12.4287 7.44298 12.3009L2.3091 8.01743C2.13437 7.87202 2.02448 7.66306 2.00362 7.43652C1.98276 7.20999 2.05263 6.98444 2.19787 6.80948C2.34311 6.63453 2.55181 6.52451 2.77806 6.50363C3.00432 6.48274 3.22959 6.5527 3.40433 6.69811L7.99059 10.5361L12.5768 6.83519C12.6644 6.76402 12.7651 6.71088 12.8732 6.67881C12.9813 6.64675 13.0946 6.63638 13.2068 6.64833C13.3189 6.66027 13.4275 6.69428 13.5265 6.7484C13.6254 6.80252 13.7127 6.87568 13.7833 6.96369C13.8617 7.05178 13.921 7.15512 13.9576 7.26724C13.9942 7.37936 14.0073 7.49784 13.9961 7.61526C13.9848 7.73268 13.9495 7.84652 13.8923 7.94963C13.835 8.05273 13.7572 8.1429 13.6635 8.21447L8.52964 12.3523C8.37128 12.4599 8.18148 12.5111 7.99059 12.498Z" fill="#8692A6"/>-->
<!--                  </svg>-->
<!--                </button>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div v-if="isLoad" v-show="isAdvancedFilter" class="advanced-filter-wrap">-->
<!--              <SavedFilters-->
<!--                  v-if="filterTypeSaved"-->
<!--                  @attachFilter="attachFilter"-->
<!--                  @backToDefault="filterTypeSaved = false"-->
<!--              />-->
<!--              <div v-else class="control-section">-->
<!--                <div class="col-lg-12 querybuilder-control">-->
<!--                  <ejs-querybuilder-->
<!--                      ref="querybuilder"-->
<!--                      :dataSource="dataSource"-->
<!--                      :maxGroupCount="100"-->
<!--                      :rule="importRules"-->
<!--                      @change="ruleChanged"-->
<!--                  >-->
<!--                    <e-columns>-->
<!--                      <e-column field='clientEmail' label='Search your client' type='string' :template='defaultTemplate' :operators="[{ value: 'in', key: 'in' }, { value: 'not_in', key: 'not in' }]" />-->
<!--                      <e-column field='message' label='Search for a Phrase' type='string' :operators="[{ value: 'is', key: 'is' }, { value: 'is_not', key: 'is not' }, { value: 'contain', key: 'contain' }, { value: 'not_contain', key: 'not contain' }]" />-->
<!--                      &lt;!&ndash;                      <e-column field='SearchContact' label='Search the contact' type='boolean' :values="values"/>&ndash;&gt;-->
<!--                      <e-column field='username' label='Search the contact' :template='defaultTemplate' type='string' :operators="[{ value: 'is', key: 'is' }, { value: 'is_not', key: 'is not' }, { value: 'in', key: 'in' }, { value: 'not_in', key: 'not in' }, { value: 'contain', key: 'contain' }, { value: 'not_contain', key: 'not contain' }]" />-->
<!--                      <e-column field='imei' label='Search for IMEI' :template='defaultTemplate' type='string' :operators="[{ value: 'is', key: 'is' }, { value: 'is_not', key: 'is not' }, { value: 'in', key: 'in' }, { value: 'not_in', key: 'not in' }, { value: 'contain', key: 'contain' }, { value: 'not_contain', key: 'not contain' }]" />-->
<!--                      &lt;!&ndash;                      <e-column field='Email' label='Hire Date' type='date' format='dd/MM/yyyy' />&ndash;&gt;-->
<!--                      <e-column field='phoneNumber' label='Phone number' :template='defaultTemplate' type='string' :operators="[{ value: 'is', key: 'is' }, { value: 'is_not', key: 'is not' }, { value: 'in', key: 'in' }, { value: 'not_in', key: 'not in' }, { value: 'contain', key: 'contain' }, { value: 'not_contain', key: 'not contain' }]" />-->
<!--                      <e-column field='dataType' label='Data type' type='string' :template='defaultTemplate' :operators="[{ value: 'in', key: 'in' }, { value: 'not_in', key: 'not in' }]" />-->
<!--                      <e-column field='osSource' label='OS source' type='string' :template='defaultTemplate' :operators="[{ value: 'in', key: 'in' }, { value: 'not_in', key: 'not in' }]" />-->
<!--                      &lt;!&ndash;                      <e-column field='device' label='Device' type='string' :template='messengerTemplate' :operators="['is', 'is not']" />&ndash;&gt;-->
<!--                      <e-column field='messengerType' label='Messenger' type='string' :template='defaultTemplate' :operators="[{ value: 'in', key: 'in' }, { value: 'not_in', key: 'not in' }]" />-->
<!--                      <e-column field='deviceOs' label='Device OS' type='string' :template='defaultTemplate' :operators="[{ value: 'is', key: 'is' }, { value: 'is_not', key: 'is not' }, { value: 'in', key: 'in' }, { value: 'not_in', key: 'not in' }]" />-->
<!--                      <e-column field='deviceId' label='Device Id' :template='defaultTemplate' type='string' :operators="[{ value: 'is', key: 'is' }, { value: 'is_not', key: 'is not' }, { value: 'in', key: 'in' }, { value: 'not_in', key: 'not in' }, { value: 'contain', key: 'contain' }, { value: 'not_contain', key: 'not contain' }]" />-->
<!--                      <e-column field='date' label='Date' type='date' :template='dateTemplate' :operators="[{ value: 'range', key: 'range' }]" format='dd/MM/yyyy' />-->
<!--                    </e-columns>-->
<!--                  </ejs-querybuilder>-->
<!--                  <div class="advanced-btn-wrapper">-->
<!--                    <button class="btn btn-apply" type="button" @click="addRule">+ Add Rule</button>-->
<!--                    <button class="btn btn-apply" type="button" @click="addGroup">+ Add Group</button>-->
<!--                    <button-->
<!--                        :disabled="emptyRule"-->
<!--                        class="btn btn-apply"-->
<!--                        type="button"-->
<!--                        @click="debounce(filter, 0, false)"-->
<!--                    >Apply-->
<!--                    </button>-->
<!--                    <span>{{ resultCount }} matches</span>-->
<!--                  </div>-->
<!--                  &lt;!&ndash;                  <div>{{ queryFilterParams }}</div>&ndash;&gt;-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div v-if="isAdvancedFilterApplied && !isAdvancedFilter" class="advanced-filter-wrap">-->
<!--              <div class="filter-tags-close">-->
<!--                <button class="advanced-change-button" @click="isOpenedFilterList = !isOpenedFilterList">-->
<!--                  <span>{{ isOpenedFilterList ? 'Close' : 'Show' }} Applied Filters</span>-->
<!--                  <svg-->
<!--                      :class="{'advanced-mode-arrow': isOpenedFilterList}"-->
<!--                      class="filter-tags-arrow"-->
<!--                      fill="none"-->
<!--                      height="17"-->
<!--                      viewBox="0 0 16 17"-->
<!--                      width="16"-->
<!--                      xmlns="http://www.w3.org/2000/svg"-->
<!--                  >-->
<!--                    <path-->
<!--                        d="M7.99059 12.498C7.79067 12.4984 7.59692 12.4287 7.44298 12.3009L2.3091 8.01743C2.13437 7.87202 2.02448 7.66306 2.00362 7.43652C1.98276 7.20999 2.05263 6.98444 2.19787 6.80948C2.34311 6.63453 2.55181 6.52451 2.77806 6.50363C3.00432 6.48274 3.22959 6.5527 3.40433 6.69811L7.99059 10.5361L12.5768 6.83519C12.6644 6.76402 12.7651 6.71088 12.8732 6.67881C12.9813 6.64675 13.0946 6.63638 13.2068 6.64833C13.3189 6.66027 13.4275 6.69428 13.5265 6.7484C13.6254 6.80252 13.7127 6.87568 13.7833 6.96369C13.8617 7.05178 13.921 7.15512 13.9576 7.26724C13.9942 7.37936 14.0073 7.49784 13.9961 7.61526C13.9848 7.73268 13.9495 7.84652 13.8923 7.94963C13.835 8.05273 13.7572 8.1429 13.6635 8.21447L8.52964 12.3523C8.37128 12.4599 8.18148 12.5111 7.99059 12.498Z"-->
<!--                        fill="#8692A6"-->
<!--                    />-->
<!--                  </svg>-->
<!--                </button>-->
<!--              </div>-->

<!--              <ul v-show="isOpenedFilterList" class="control-tags">-->
<!--                <li-->
<!--                    v-for="tag of filteredTags"-->
<!--                    :key="tag.id"-->
<!--                    :class="{ 'removed': tag.removed }"-->
<!--                    class="filter-tag"-->
<!--                >-->
<!--                  <button type="button" @click="removeFilter(tag)">-->
<!--                    <svg fill="none" height="28" viewBox="0 0 28 28" width="28" xmlns="http://www.w3.org/2000/svg">-->
<!--                      <path-->
<!--                          d="M14 2.3335C7.54833 2.3335 2.33333 7.5485 2.33333 14.0002C2.33333 20.4518 7.54833 25.6668 14 25.6668C20.4517 25.6668 25.6667 20.4518 25.6667 14.0002C25.6667 7.5485 20.4517 2.3335 14 2.3335ZM19.8333 18.1885L18.1883 19.8335L14 15.6452L9.81166 19.8335L8.16666 18.1885L12.355 14.0002L8.16666 9.81183L9.81166 8.16683L14 12.3552L18.1883 8.16683L19.8333 9.81183L15.645 14.0002L19.8333 18.1885Z"-->
<!--                          data-v-1f88d880=""-->
<!--                          fill="#D7DCE5"-->
<!--                      />-->
<!--                    </svg>-->
<!--                  </button>-->

<!--                  <strong>{{ tag.label | filteringLabel }}</strong>:&nbsp;-->
<!--                  <span>{{ tag.initialValue || tag.value }}</span>-->
<!--                </li>-->
<!--              </ul>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="filter-content">-->
<!--            <div-->
<!--                v-if="isViewWithSidebar"-->
<!--                class="nav-chat">-->
<!--              <vue-custom-scrollbar v-if="arrayOfMessages.length" class="scroll-area" :settings="settings">-->
<!--                <MessagesList :messages="arrayOfMessages" :tabItemMsg="tabItemMsg" @setTabItemMsg='getTabItemMsg' />-->
<!--              </vue-custom-scrollbar>-->
<!--              <div v-else class="empty-chats-wrap">-->
<!--                <span>No results</span>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div-->
<!--                v-if="isViewWithSidebar && !isAdvancedFilterApplied"-->
<!--                class="chat-list"-->
<!--            >-->
<!--              <infinite-loading ref="scrollWrapper" direction="top" :identifier="infiniteId" @infinite="infiniteHandler">-->
<!--                <div slot="spinner">Loading...</div>-->
<!--                <div slot="no-more">No more messages</div>-->
<!--                <div slot="no-results">No results message</div>-->
<!--              </infinite-loading>-->
<!--              <SetMessages-->
<!--                  v-if="tabItemMsg"-->
<!--                  :tabItemMsg="tabItemMsg"-->
<!--                  :messages="messages"-->
<!--                  @changeCheckbox="changeMessageCheckbox"-->
<!--                  @downloadFile="downloadAttachment"-->
<!--              />-->
<!--              <SelectedMessagesInfo-->
<!--                  :messages="messages"-->
<!--                  :selectedMessagesId="selectedMessagesId"-->
<!--                  @updatedActiveStatus="updatedActiveStatus($event, 'messages')"-->
<!--                  @exportMessages="exportMessages($event)"-->
<!--                  @selectDB="format = $event"-->
<!--              />-->
<!--            </div>-->
<!--            <div v-else-if="!isAdvancedFilterApplied && !isViewWithSidebar" class="chat-list-group">-->
<!--              <infinite-loading ref="scrollWrapper2" direction="top" :identifier="infiniteMerged" @infinite="infiniteHandlerMerged">-->
<!--                <div slot="spinner">Loading...</div>-->
<!--                <div slot="no-more">No more messages</div>-->
<!--                <div slot="no-results">No results message</div>-->
<!--              </infinite-loading>-->
<!--              <SetGroupMessages-->
<!--                  :messages="messagesMerged"-->
<!--                  @changeCheckbox="changeMessageCheckbox"-->
<!--                  @downloadFile="downloadAttachment"-->
<!--              />-->
<!--              <SelectedMessagesInfo-->
<!--                  :messages="messagesMerged"-->
<!--                  :selectedMessagesId="selectedMessagesId"-->
<!--                  @updatedActiveStatus="updatedActiveStatus($event, 'messagesMerged')"-->
<!--                  @exportMessages="exportMessages($event)"-->
<!--                  @selectDB="format = $event"-->
<!--              />-->
<!--            </div>-->
<!--            <div v-else-if="isAdvancedFilterApplied" class="chat-list-group">-->
<!--              <infinite-loading ref="scrollWrapper3" direction="top" :identifier="infiniteAdvancedId" @infinite="infiniteHandlerAdvanced">-->
<!--                <div slot="spinner">Loading...</div>-->
<!--                <div slot="no-more">No more messages</div>-->
<!--                <div slot="no-results">No results message</div>-->
<!--              </infinite-loading>-->
<!--              <SetAdvancedMessages-->
<!--                  v-if="tabItemMsg"-->
<!--                  :tabItemMsg="tabItemMsg"-->
<!--                  :messages="messagesAdvanced"-->
<!--                  @changeCheckbox="changeMessageCheckbox"-->
<!--                  @downloadFile="downloadAttachment"-->
<!--              />-->
<!--              <SelectedMessagesInfo-->
<!--                  :messages="messagesAdvanced"-->
<!--                  :selectedMessagesId="selectedMessagesId"-->
<!--                  @updatedActiveStatus="updatedActiveStatus($event, 'messagesAdvanced')"-->
<!--                  @exportMessages="exportMessages($event)"-->
<!--                  @selectDB="format = $event"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->
          <!--          <div v-if="messages.length === 0" class="empty-messages-wrapper">-->
          <!--            <div class="empty-messages-content">-->
          <!--              <img src="@/assets/img/no-msg.png" alt="">-->
          <!--              <p>No messages yet</p>-->
          <!--            </div>-->
          <!--          </div>-->
<!--        </section>-->
        <!--        <section v-else class="messages-section section-no-found">-->
        <!--          <img src="@/assets/img/no-msg.png" alt="">-->
        <!--          <p>No messages yet</p>-->
        <!--          <button-->
        <!--              @click="$router.push({name: 'case-details', params: { slug: caseId}}).catch(()=>{})"-->
        <!--              id="back_to_case_button"-->
        <!--              class="btn">Back to Case</button>-->
        <!--        </section>-->
      </main>
    </div>
<!--    <SuccessPopup-->
<!--        ref="successPopup"-->
<!--        :text="`You exported ${ selectedMessagesLength } messages`"-->
<!--    />-->
<!--    <SaveFilterPattern-->
<!--        ref="saveFilterPattern"-->
<!--        @saveFilterPattern="saveFilterPattern"-->
<!--    />-->
  </div>
</template>

<script>
// import InfiniteLoading from 'vue-infinite-loading';
// import DatePicker from "vue2-datepicker";
import Sidebar from "../../Sidebar";
import HeaderRegistered from "../../layout/HeaderRegistered";
// import MessagesList from "./MessageList";
// import vueCustomScrollbar from 'vue-custom-scrollbar';
import AlertErrorMsg from "../../profile/AlertErrorMsg";
// import CustomSelect from "../../inputs/CustomSelect";
// import SetMessages from "./SetMessages";
// import SetGroupMessages from "./SetGroupMessages"
// import settings from "../../../mixins/scrollSettings";
// import Vue from "vue";
// import { QueryBuilderPlugin } from "@syncfusion/ej2-vue-querybuilder";
// import { DateRangePicker } from '@syncfusion/ej2-calendars';
// import { MultiSelect, CheckBoxSelection, AutoComplete } from '@syncfusion/ej2-dropdowns';
// import { MultiSelect, CheckBoxSelection } from '@syncfusion/ej2-dropdowns';
// import { createElement, getComponent } from "@syncfusion/ej2-base";
// import { Query } from '@syncfusion/ej2-data';
// import SelectedMessagesInfo from '@/components/selected-messages-info/SelectedMessagesInfo';
// import SuccessPopup from '@/components/popups/SuccessPopup';
// import SetAdvancedMessages from '@/components/сases/case-messages/SetAdvancedMessages';
// import moment from 'moment';
// import debounce from '@/mixins/debounce';
// import SavedFilters from '@/components/сases/case-messages/SavedFilters';
// import SaveFilterPattern from '@/components/popups/SaveFilterPattern';

// MultiSelect.Inject(CheckBoxSelection);

// Vue.use(QueryBuilderPlugin);
// Vue.use(DropDownListPlugin);

// let inOperators = ['is', 'is_not', 'contain', 'not_contain'];
// const types = ['value', 'deleteRule', 'deleteGroup', 'condition'];

export default {
  name: "CaseMessages",
  components: {
    // SaveFilterPattern,
    // SavedFilters,
    // SetAdvancedMessages,
    // SelectedMessagesInfo,
    // InfiniteLoading,
    // DatePicker,
    // SetMessages,
    // SetGroupMessages,
    // CustomSelect,
    // CustomSearch,
    AlertErrorMsg,
    // SuccessPopup,
    HeaderRegistered,
    Sidebar,
    // MessagesList,
    // vueCustomScrollbar,
  },
  // mixins: [settings, debounce],
  data() {
    return {
      caseTitle: '',
      // filtersAppliedCount: 0,
      // isFiltered: false,
      // filtersAmountApplied: 0,
      // isAdvancedFilterApplied: false,
      // isLoad: true,
      // dataRange: '',
      // caseId: this.$route.params.slug,
      // messengers: [],
      // selectedMessagesLength: 0,
      errorMessage: "",
      // filterTypeSaved: false,
      // searchText: "",
      // keySearch: '',
      // sortBy: '',
      // type: '',
      // isAdvancedFilter: false,
      // dateFrom: '',
      // dateTo: '',
      // clientId: '',
      // arrayOfMessages: [],
      // users: [],
      // usersName: '',
      // tabItemMsg: {},
      // msgFilters: [],
      // isOpenedFilterList: true,
      // size: 3,
      // chatId: '',
      // messages: [],
      // messagesAdvanced: [],
      // messagesMerged: [],
      // selectedMessagesId: [],
      // format: 'sql',
      // filteredTags: [],
      // isCheckedAllMessages: false,
      // defaultId: null,
      // viewMessages: 'with-sidebar',
      // withSidebar: 'with-sidebar',
      // withoutSidebar: 'without-sidebar',
      // infiniteId: +new Date(),
      // infiniteAdvancedId: +new Date(),
      // infiniteMerged: +new Date(),
      // MessagesChatPage: 0,
      // MessagesChatSize: 20,
      // MessagesMergedPage: 1,
      // MessagesMergedSize: 20,
      // MessagesAdvancedPage: 0,
      // MessagesAdvancedSize: 20,
      // advancedInfiniteHandlerState: null,
      // currentUser: 0,
      // currentMessengers: 'All',
      // selectUser: false,
      // placeholderDate: 'Date range chats',
      // dataSource: [],
      // filterData: null,
      // emptyRule: true,
      // queryFilterParams: null,
      // defaultQueryFilterParams: [],
      // defaultImportRules: {'condition': "and",'rules': [{}, { field: 'clientEmail', operator: 'in', key: 'in', type: 'string', label: 'Search your client' }]},
      // importRules: {'condition': "and",'rules': [{}, { field: 'clientEmail', operator: 'in', key: 'in', type: 'string', label: 'Search your client' }]},
      // stringifiedRules: '',
      // disabledFiltersId: [],
      // resultCount: 0,
      // clientList: [],
      // groupCheckbox: false,
      // initialData: [],
      // dateTemplate: {
      //   create: () => {
      //     return createElement('input', { attrs: { 'type': 'text' } });
      //   },
      //   destroy: (args) => {
      //     let daterangepicker = getComponent(document.getElementById(args.elementId), 'daterangepicker');
      //     if(daterangepicker)
      //       daterangepicker.destroy();
      //   },
      //   write: (args) => {
      //     let daterangepicker = new DateRangePicker({
      //       placeholder: 'Select date range',
      //       disabled: true,
      //       change: (e) => {
      //         this.$refs.querybuilder.$el.ej2_instances[0].notifyChange(e.value, e.element);
      //       },
      //     });
      //
      //     if (args.values) {
      //       daterangepicker.startDate = new Date(this.formatDate(args.values[0]));
      //       daterangepicker.endDate = new Date(this.formatDate(args.values[1]));
      //     }
      //
      //     daterangepicker.appendTo('#' + args.elements.id);
      //   }
      // },

      // defaultTemplate: {
      //   create: () => {
      //     return createElement('input', { attrs: { 'type': 'text' } });
      //   },
      //   destroy: (args) => {
      //     let multiselect = getComponent(document.getElementById(args.elementId), 'multiselect');
      //     if(multiselect)
      //       multiselect.destroy();
      //     let autoComplete = getComponent(document.getElementById(args.elementId), 'autocomplete');
      //     if(autoComplete)
      //       autoComplete.destroy();
      //   },
      //   write: (args) => {
      //     let ds = []
      //
      //     if(args.field === 'clientEmail') {
      //       ds = this.clientList
      //     }else {
      //       this.initialData.forEach(item => {
      //         if(item.fieldName === args.field) {
      //           item.values.forEach(el => {
      //             let elName = el[0].toUpperCase() + el.toLowerCase().slice(1)
      //             ds.push({ id: el, value: elName })
      //           })
      //         }
      //       })
      //     }
      //
      //     if(inOperators.indexOf(args.operator) > -1) {
      //       let autoCompleteObj  = new AutoComplete({
      //         dataSource: ds,
      //         // value: args.values,
      //         fields: { text: 'value', value: 'id' },
      //         placeholder: `Find a ${this.stringModify(args.field)}`,
      //         highlight: true,
      //         itemTemplate:"<span class='filtering-name'>${id}</span>",
      //         change: (e) => {
      //           const { field } = this.$refs.querybuilder.$el.ej2_instances[0].prevItemData;
      //           let name = '';
      //
      //           if (e.itemData) {
      //             name = e.itemData.id;
      //           }
      //
      //           if (field === 'phoneNumber' || field === 'imei') {
      //             name = name.replace(/[()]/g, '');
      //           }
      //
      //           this.$refs.querybuilder.$el.ej2_instances[0].notifyChange(name, e.element);
      //         }
      //       });
      //       autoCompleteObj.appendTo('#' + args.elements.id);
      //     }else {
      //       let multiSelectObj = new MultiSelect({
      //         dataSource: ds,
      //         value: args.values,
      //         fields: { text: 'value', value: 'id' },
      //         mode: 'CheckBox',
      //         placeholder: `Select ${this.stringModify(args.field)}`,
      //         change: (e) => {
      //           this.$refs.querybuilder.$el.ej2_instances[0].notifyChange(e.value, e.element);
      //         },
      //         filtering: function (e) {
      //           let query = new Query();
      //           //frame the query based on search string with filter type.
      //           query = (e.text != "") ? query.where("value", "startswith", e.text, true) : query;
      //           //pass the filter data source, filter query to updateData method.
      //           e.updateData(ds, query);
      //         }
      //       });
      //       multiSelectObj.appendTo('#' + args.elements.id);
      //       if (args.values) {
      //         multiSelectObj.change(multiSelectObj);
      //       }
      //     }
      //
      //
      //     // if (inOperators.indexOf(args.operator) > -1) {
      //     //   multiSelectObj.maximumSelectionLength = 1
      //     // } else {
      //     //   multiSelectObj.maximumSelectionLength = 1000
      //     // }
      //
      //   }
      // },

      // textTemplate: {
      //   create: () => {
      //     return createElement('input', { attrs: { 'type': 'text' } });
      //   },
      //   destroy: (args) => {
      //     let autoComplete = getComponent(document.getElementById(args.elementId), 'autocomplete');
      //     autoComplete.destroy();
      //   },
      //   write: (args) => {
      //     let ds = []
      //
      //     this.initialData.forEach(item => {
      //       if(item.fieldName === this.$refs.querybuilder.$el.ej2_instances[0].prevItemData.field) {
      //         item.values.forEach(el => {
      //           ds.push({ Name: el, Code: el })
      //         })
      //       }
      //     })
      //
      //     let autoCompleteObj  = new AutoComplete({
      //       dataSource: ds,
      //       // value: args.values,
      //       fields: { value: "Code", text:"Name" },
      //       placeholder: `Find a ${this.$refs.querybuilder.$el.ej2_instances[0].prevItemData.field}`,
      //       highlight: true,
      //       itemTemplate:"<span class='filtering-name'>${Name}</span>",
      //       change: (e) => {
      //         const { field } = this.$refs.querybuilder.$el.ej2_instances[0].prevItemData;
      //         let name = e.itemData.Name;
      //
      //         if (field === 'phoneNumber' || field === 'imei') {
      //           name = name.replace(/[()]/g, '');
      //         }
      //
      //         this.$refs.querybuilder.$el.ej2_instances[0].notifyChange(name, e.element);
      //       }
      //     });
      //     autoCompleteObj.appendTo('#' + args.elements.id);
      //   }
      // },
    }
  },
  methods: {
    setErrorMessage(error) {
      // this.errorMessage = error.response.data.errors.message.toString();
      this.errorMessage = error;
      this.$refs.alert.showAlert();
    },
  },
  mounted() {
    this.caseTitle = localStorage.getItem('title');
  },
  // watch: {
  //   viewMessages(val) {
  //     this.selectedMessagesId = [];
  //     this.isCheckedAllMessages = false;
  //     if (val === this.withSidebar) {
  //       this.messages.forEach(message => message.isActive = false);
  //     } else {
  //       this.messagesMerged.forEach(message => message.isActive = false);
  //     }
  //   },
  //   searchText() {
  //     if(!this.isViewWithSidebar) {
  //       this.messagesMerged = []
  //       this.infiniteMerged += 1;
  //     }else {
  //       this.getCaseMessages()
  //     }
  //   },
  //   currentUser(value) {
  //     if(value) {
  //       if (value !== +this.$route.query.clientId) {
  //         this.$router.replace({ query: { clientId: value } });
  //       }
  //
  //       this.clientId = value
  //       if(this.selectUser) {
  //         if(!this.isViewWithSidebar) {
  //           this.MessagesMergedPage = 1
  //           this.messagesMerged = []
  //           this.infiniteMerged += 1;
  //         }else {
  //           this.getCaseMessages(value)
  //         }
  //       }
  //
  //       this.getFilterRules();
  //       this.selectUser = true
  //     }
  //   },
  //   currentMessengers(value) {
  //     if(value) {
  //       this.currentMessengers = value
  //       if(!this.isViewWithSidebar) {
  //         this.MessagesMergedPage = 1
  //         this.messagesMerged = []
  //         this.infiniteMerged += 1;
  //       }else {
  //         this.getCaseMessages(this.clientId)
  //       }
  //     }
  //   },
  //   dataRange(value) {
  //     if(value) {
  //       this.dateFrom = isNaN(Date.parse(value[0])) == false ? Date.parse(value[0]) + 10800000 : ''
  //       this.dateTo = isNaN(Date.parse(value[1])) == false ? Date.parse(value[1]) + 97200000  : ''
  //
  //       if (this.groupCheckbox) {
  //         this.MessagesMergedPage = 1;
  //         this.messagesMerged = [];
  //         this.infiniteMerged += 1;
  //       } else {
  //         this.MessagesChatPage = 0;
  //         this.messages = [];
  //         this.getCaseMessages();
  //       }
  //     } else {
  //       this.dataRange = ''
  //       this.dateFrom = ''
  //       this.dateTo = ''
  //       this.MessagesChatPage = 0
  //       this.messages = []
  //       // this.groupCheckbox = false;
  //       // this.viewMessages = this.withSidebar;
  //       this.getCaseMessages()
  //       // this.MessagesMergedPage = 1
  //       // this.messagesMerged = []
  //       // this.infiniteMerged += 1;
  //     }
  //   },
  // },
  // filters: {
  //   filteringLabel(label) {
  //     let labelsList = label.split(" ")
  //     labelsList = labelsList.map(phrase => {
  //       if (phrase === 'a' || phrase === "for" || phrase === 'Search' || phrase === 'the') {
  //         return
  //       }
  //       return phrase;
  //     });
  //
  //     return labelsList.join(" ")
  //   }
  // },
  // computed: {
  //   isViewWithSidebar() {
  //     return this.viewMessages === this.withSidebar;
  //   },
  //   isValidQueryFilterParams() {
  //     return this.queryFilterParams.length === 1 && this.queryFilterParams[0] && !this.queryFilterParams[0].rules.length || this.queryFilterParams[0].rules[0] && !this.queryFilterParams[0].rules[0].value;
  //   },
  // },
  // methods: {
  //   setErrorMessage(error) {
  //     this.errorMessage = error.response.data.errors.message.toString();
  //     this.$refs.alert.showAlert();
  //   },
  //   stringModify(str) {
  //     return str.split('').map((letter, idx) => {
  //       return letter.toUpperCase() === letter
  //           ? `${idx !== 0 ? ' ' : ''}${letter.toLowerCase()}`
  //           : letter;
  //     }).join('');
  //   },
  //   reset() {
  //     this.$refs.querybuilder.ej2Instances.reset()
  //     this.$refs.querybuilder.ej2Instances.levelColl = []
  //   },
  //   addRule(_, rule) {
  //     let count = Object.keys(this.$refs.querybuilder.ej2Instances.levelColl)[Object.keys(this.$refs.querybuilder.ej2Instances.levelColl).length - 1].slice(-1)
  //     this.$refs.querybuilder.ej2Instances.addRules([rule || { field: 'clientEmail' }], `group${count}`);
  //   },
  //   addGroup(_, group) {
  //     let count = Object.keys(this.$refs.querybuilder.ej2Instances.levelColl)[Object.keys(this.$refs.querybuilder.ej2Instances.levelColl).length - 1].slice(-1)
  //     this.$refs.querybuilder.ej2Instances.addGroups([group || {'condition': "and",'rules': [{}, { field: 'clientEmail' }]}], `group${count}`);
  //
  //     document.querySelectorAll('.e-group-container').forEach(container => {
  //       const { classList } = container.previousSibling;
  //
  //       if (classList && !classList.contains('last-rule-container')) {
  //         container.previousSibling.classList.add('last-rule-container');
  //       }
  //     })
  //   },
  //   updatedActiveStatus($event, messagesName) {
  //     this[messagesName] = $event.messages;
  //     this.isCheckedAllMessages = $event.isCheckedAllMessages;
  //     this.selectedMessagesId = $event.selectedMessagesId;
  //   },
  //   isCondition(item) {
  //     return item.condition !== undefined
  //   },
  //   hasRuleset(condition) {
  //     return !!condition.rules && !!condition.rules.length
  //   },
  //   formatDate(date) {
  //     const initial = date.split(/\//);
  //
  //     return [initial[1], initial[0], initial[2]].join('/')
  //   },
  //   filterTags(rules) {
  //     rules.forEach(rule => {
  //       rule.rules ? this.filterTags(rule.rules) : this.filteredTags.push(rule);
  //     })
  //   },
  //   removeFilter(disabledTag) {
  //     let areThereRules = false;
  //
  //     if (disabledTag.removed) {
  //       const disabledFilterId = this.disabledFiltersId.findIndex(el => el === disabledTag.id);
  //
  //       this.disabledFiltersId.splice(disabledFilterId, 1);
  //     } else {
  //       this.disabledFiltersId.push(disabledTag.id);
  //     }
  //
  //     disabledTag.removed = !disabledTag.removed;
  //
  //     this.queryFilterParams = this.defaultQueryFilterParams
  //         .map(parameter => {
  //           const rules = parameter.rules.filter(rule => {
  //             for (let i = 0; i < this.disabledFiltersId.length; i++) {
  //               if (rule.id === this.disabledFiltersId[i]) {
  //                 return false;
  //               }
  //             }
  //
  //             return true;
  //           });
  //
  //           if (rules.length) {
  //             areThereRules = true;
  //           }
  //
  //           return {
  //             ...parameter,
  //             rules,
  //           };
  //         })
  //         .filter(queryParam => !!queryParam.rules.length);
  //
  //     if (areThereRules) {
  //       this.messagesAdvanced = [];
  //       this.MessagesAdvancedPage = 0;
  //
  //       this.debounce(() => {
  //         this.infiniteHandlerAdvanced(this.advancedInfiniteHandlerState);
  //       }, 1000);
  //     } else {
  //       this.resetFilters();
  //     }
  //   },
  //
  //   flattenRules(rootRule) {
  //     if (!this.isCondition(rootRule) || !this.hasRuleset(rootRule)) return;
  //
  //     const flat = (currentCondition) => {
  //       if (!this.hasRuleset(currentCondition)) return;
  //
  //       // Filtering current ruleset to find inner conditions with non-empty rulesets;
  //       const nestedConditions = currentCondition.rules.filter(
  //           (item) => this.isCondition(item) && this.hasRuleset(item)
  //       );
  //
  //       return [
  //         {
  //           ...currentCondition,
  //           // We exclude inner conditions from the ruleset and leave only the descriptions;
  //           rules: currentCondition.rules.filter((item) => !this.isCondition(item)),
  //         },
  //         ...nestedConditions.reduce(
  //             // We recursively call this function with all of the nested conditions;
  //             (acc, condition) => [...acc, ...flat(condition)],
  //             []
  //         ),
  //       ];
  //     };
  //
  //     return flat(rootRule);
  //   },
  //   flatten(conditionsResult, setId) {
  //     let conditionsResultArray = this.flattenRules(conditionsResult)
  //
  //     conditionsResultArray.forEach((item) => {
  //       if (item.condition === null) {
  //         item.condition = 'and'
  //       }
  //       if(item.rules.length) {
  //         item.rules.forEach((rule) => {
  //           if (setId) {
  //             rule.id = Math.random().toString(36).substr(2, 9);
  //           }
  //           if(rule.field !== 'date') {
  //             if (rule.field === 'clientEmail') {
  //               rule.field = 'clientId';
  //             }
  //
  //             if (Array.isArray(rule.value)) {
  //               rule.value = rule.value.join(';')
  //             }
  //           }
  //         })
  //       }
  //     })
  //
  //     return conditionsResultArray
  //   },
  //   changeFilterView() {
  //     this.isAdvancedFilter = !this.isAdvancedFilter;
  //   },
  //   // changeFilter() {
  //   //   let validRule = this.$refs.querybuilder.ej2Instances.getValidRules(this.$refs.querybuilder.ej2Instances.rule)
  //   //   this.queryFilterParams = this.flatten(validRule)
  //   //   this.filtersAppliedCount = this.queryFilterParams.reduce((a, b) => a + b.rules.length, 0)
  //   // },
  //   filter(getResultCount) {
  //     if (!getResultCount) {
  //       this.stringifiedRules = JSON.stringify({
  //         clientId: this.clientId,
  //         rules: this.$refs.querybuilder.ej2Instances.rule.properties
  //       });
  //
  //       localStorage.setItem('rules', this.stringifiedRules);
  //       this.queryFilterParams = this.getQueryParams(true);
  //     }
  //
  //     this.filtersAmountApplied = this.queryFilterParams.reduce((a, b) => a + b.rules.length, 0)
  //     this.queryFilterParams = this.queryFilterParams.filter(param => param.rules.length);
  //
  //     this.queryFilterParams.forEach(item => {
  //        if(item.rules.length) {
  //          item.rules.forEach((el, i) => {
  //            if(!el.value) {
  //              item.rules.splice(i, 1);
  //              return;
  //            }
  //            if(el.field === 'date') {
  //
  //              if(el.value.length) {
  //                let dateObj = {
  //                  field: "date",
  //                  label: "Date",
  //                  operator: "gth",
  //                  type: "date",
  //                  value: ""
  //                }
  //                let dateFromObj = Object.assign({}, dateObj)
  //                let dateToObj = Object.assign({}, dateObj)
  //                let dateFrom = el.value[0].split('/')
  //                let dateTo = el.value[1].split('/')
  //                let dateFromTime = `${dateFrom[2]}-${dateFrom[1]}-${dateFrom[0]}`
  //                let dateToTime = `${dateTo[2]}-${dateTo[1]}-${dateTo[0]}`
  //                dateFrom = moment(dateFromTime).valueOf()
  //                dateTo = moment(dateToTime).endOf('day').valueOf()
  //                dateFromObj.value = dateFrom
  //                dateFromObj.operator = 'gth'
  //                dateToObj.value = dateTo
  //                dateToObj.operator = 'lth'
  //                dateFromObj.initialValue = dateFromTime
  //                dateToObj.initialValue = dateToTime
  //                item.rules.push(dateFromObj)
  //                item.rules.push(dateToObj)
  //                item.rules.splice(i, 1);
  //              }
  //            } else if (el.field === 'clientId') {
  //              let arr = el.value.split(';')
  //              arr.map((item, i) => {
  //                const client = this.clientList.find(client => client.id === item);
  //
  //                if (client) {
  //                  arr[i] = client.value
  //                }
  //              })
  //              el.initialValue = arr.join(';')
  //            }
  //          })
  //        }
  //     })
  //
  //     this.filterData = JSON.stringify(this.msgFilters, null, 4)
  //     this.MessagesAdvancedPage = 0;
  //
  //     if(this.advancedInfiniteHandlerState) {
  //       this.advancedInfiniteHandlerState.reset();
  //     }
  //
  //     if (getResultCount) {
  //       this.getAdvancedResultAmount();
  //     } else {
  //       this.viewMessages = this.withoutSidebar;
  //       this.messagesAdvanced = [];
  //       this.filteredTags = [];
  //       this.isAdvancedFilterApplied = true;
  //       this.infiniteAdvancedId += 1;
  //       this.infiniteHandlerAdvanced();
  //       this.isAdvancedFilter = false;
  //       this.defaultQueryFilterParams = this.queryFilterParams;
  //       this.filterTags(this.queryFilterParams);
  //     }
  //   },
  //   openSaveFilterPatternPopup() {
  //     this.$refs.saveFilterPattern.openPopups();
  //   },
  //   async saveFilterPattern(filterName) {
  //     await this.$load(async() => {
  //       try {
  //         await this.$api.cases.saveFilterPattern(this.caseId, {
  //           name: filterName,
  //           value: this.stringifiedRules,
  //         });
  //         this.stringifiedRules = '';
  //       } catch (error) {
  //         this.errorMessage = error.response.data.errors.message.toString();
  //         this.$refs.alert.showAlert();
  //       }
  //     })
  //   },
  //   async attachFilter(filterId) {
  //     await this.$load(async () => {
  //       const filter = await this.$api.cases.getFilterPattern(this.caseId, filterId);
  //       this.filterTypeSaved = false;
  //       this.setParsedRules(JSON.parse(filter.data.body.value));
  //     });
  //   },
  //   resetFilters() {
  //     localStorage.removeItem('rules');
  //     this.stringifiedRules = '';
  //     this.isAdvancedFilter = true;
  //     this.isAdvancedFilterApplied = false;
  //     this.filtersAmountApplied = 0;
  //     this.MessagesChatPage = 0
  //     this.MessagesAdvancedPage = 0
  //     this.queryFilterParams = null;
  //     this.messages = [];
  //     this.messagesAdvanced = [];
  //     this.viewMessages = this.withSidebar;
  //     this.groupCheckbox = false
  //     this.filteredTags = [];
  //     this.isOpenedFilterList = true;
  //     this.resultCount = 0;
  //     this.reset();
  //     this.$refs.querybuilder.ej2Instances.setRules(this.defaultImportRules)
  //     this.filtersAppliedCount = 0
  //     this.getUsersInMsg();
  //     this.getMessengers();
  //   },
  //   getQueryParams(setId = false) {
  //     this.msgFilters = this.$refs.querybuilder.ej2Instances.getValidRules(this.$refs.querybuilder.ej2Instances.rule);
  //     return this.flatten(this.msgFilters, setId);
  //   },
  //   setSavedRules(rules) {
  //     rules.forEach(rule => {
  //       if (rule.rules && rule.rules.length) {
  //         this.addGroup(false, {'condition': rule.condition || 'and', 'rules': [{}]});
  //         this.setSavedRules(rule.rules);
  //       } else if (rule.value) {
  //         this.addRule(false, rule);
  //       }
  //     });
  //   },
  //   changedQueryBuilder(e) {
  //     if (e.type === 'insertRule') {
  //       return;
  //     }
  //
  //     this.queryFilterParams = this.getQueryParams();
  //
  //     if (this.isValidQueryFilterParams) {
  //       this.resultCount = 0
  //       return;
  //     }
  //
  //     if (e.type === 'deleteGroup') {
  //       const groupID = e.groupID.split('group').join('');
  //
  //       this.queryFilterParams.splice(groupID, 1);
  //     }
  //
  //     if (types.some(type => type === e.type)) {
  //       this.filter(true);
  //     }
  //   },
  //   checkIsNestedRulesNotEmpty(rules) {
  //     return rules.every(rule => {
  //       if (rule.value === undefined) return true;
  //
  //       return rule.rules
  //           ? this.checkIsNestedRulesNotEmpty(rule.rules)
  //           : rule.value && rule.value.length;
  //     });
  //   },
  //   ruleChanged(event) {
  //     const rules = [];
  //
  //     this.$refs.querybuilder.ej2Instances.rule.properties.rules.forEach(rule => {
  //       if (rule.rules) {
  //         if (!rule.rules.length) {
  //           return;
  //         }
  //
  //         rules.push(this.checkIsNestedRulesNotEmpty(rule.rules));
  //         return;
  //       }
  //
  //       rules.push(rule.value && !!rule.value.length);
  //     });
  //
  //     this.emptyRule = rules.some(rule => rule !== undefined && !rule);
  //
  //     if (!this.emptyRule || (!this.emptyRule && event.type === 'condition')) {
  //       this.debounce(this.changedQueryBuilder, 1000, event)
  //     }
  //   },
  //   changeCheckbox() {
  //     this.dataRange = ""
  //     this.dateFrom = ""
  //     this.dateTo = ""
  //     this.viewMessages = this.isViewWithSidebar ?  this.withoutSidebar : this.withSidebar
  //     // if(!this.viewMessages === 'without-sidebar') {
  //     //   this.dataRange = ""
  //     //   this.dateFrom = ""
  //     //   this.dateTo = ""
  //     //   this.messagesMerged = []
  //     //   this.infiniteMerged += 1;
  //     // }
  //   },
  //   changeMessageCheckbox(item) {
  //     if(item.isActive) {
  //       this.selectedMessagesId.push(item.id);
  //     } else {
  //       this.selectedMessagesId = this.selectedMessagesId.filter(id => id !== item.id);
  //     }
  //   },
  //   downloadAttachment(attachment) {
  //     this.$load(async () => {
  //       await this.$api.cases.downloadAttachment({ caseId: this.caseId, attachmentId: attachment.id })
  //           .then(response => {
  //             const url = window.URL.createObjectURL(new Blob([response.data]));
  //             const link = document.createElement('a');
  //             link.href = url;
  //             link.setAttribute('download', attachment.name);
  //             document.body.appendChild(link);
  //             link.click();
  //             link.parentNode.removeChild(link);
  //           })
  //     })
  //   },
  //   getTabItemMsg(data) {
  //     if (this.tabItemMsg.id !== data.id) {
  //       this.MessagesChatPage = 0
  //       this.messages = []
  //       this.tabItemMsg = data
  //       this.chatId = data.id
  //       this.infiniteId += 1;
  //     }
  //   },
  //
  //   // users in select
  //   async getUsersInMsg() {
  //     await this.$load(async() => {
  //       // this.loadFlagPermission = true
  //       await this.$api.cases.getUsersInMessages({
  //         caseId: this.caseId,
  //       }).then((res) => {
  //         this.clientList = []
  //         this.users = res.data.body
  //         this.users.forEach((item) => {
  //           this.clientList.push({ id: String(item.id), value: item.name })
  //         })
  //
  //         const currentUser = this.users.find(user => user.id === this.currentUser);
  //
  //         if (currentUser) {
  //           this.usersName = currentUser.name;
  //         } else {
  //           this.currentUser = this.users[0].id;
  //           this.usersName = this.users[0].name;
  //         }
  //
  //         this.getCaseMessages(this.currentUser)
  //         this.isLoad = true
  //       }, (error) => {
  //         if(error.response) {
  //           this.errorMessage = error.response.data.errors.message[0]
  //         }else {
  //           this.errorMessage = error.data.errors.message[0]
  //         }
  //         this.$refs.alert.showAlert()
  //       })
  //       setTimeout(() => {
  //         // this.loadFlagPermission = false
  //       }, 1000)
  //     })
  //   },
  //
  //   // viber.... in select
  //   getMessengers() {
  //     this.$load(async() => {
  //       // this.loadFlagPermission = true
  //       await this.$api.cases.getMessengersInMessages(this.caseId, this.currentUser).then((res) => {
  //         this.messengers = res.data.body.types.map(i => {
  //           return {
  //             name: i
  //           }
  //         })
  //       }, (error) => {
  //         if(error.response) {
  //           this.errorMessage = error.response.data.errors.message[0]
  //         }else {
  //           this.errorMessage = error.data.errors.message[0]
  //         }
  //         this.$refs.alert.showAlert()
  //       })
  //       setTimeout(() => {
  //         // this.loadFlagPermission = false
  //       }, 1000)
  //     })
  //   },
  //
  //   fixedEncodeURIComponent (str) {
  //     return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
  //       return '%' + c.charCodeAt(0).toString(16);
  //     });
  //   },
  //
  //   // left list
  //   getCaseMessages(value) {
  //     this.placeholderDate = 'Date range chats'
  //     this.$load(async() => {
  //       await this.$api.cases.getCaseMessages({
  //         caseId: this.caseId,
  //         clientId: value || this.clientId,
  //         messengers: this.currentMessengers,
  //         keySearch: this.fixedEncodeURIComponent(this.searchText),
  //         sortBy: this.sortBy,
  //         type: this.type,
  //         dateFrom: this.dateFrom,
  //         dateTo: this.dateTo,
  //       }).then((res) => {
  //         this.arrayOfMessages = res.data.body
  //         this.tabItemMsg = this.arrayOfMessages[0]
  //         this.chatId = this.arrayOfMessages[0].id
  //         this.MessagesChatPage = 0
  //         this.messages = []
  //         this.infiniteId += 1;
  //       }, (error) => {
  //         if(error.response) {
  //           this.errorMessage = error.response.data.errors.message[0]
  //         }else {
  //           this.errorMessage = error.data.errors.message[0]
  //         }
  //         this.$refs.alert.showAlert()
  //       })
  //       setTimeout(() => {
  //         // this.loadFlagPermission = false
  //       }, 1000)
  //     })
  //   },
  //   infiniteHandler($state) {
  //     this.placeholderDate = 'Date range chats'
  //     if(this.chatId) {
  //       this.$load(async() => {
  //         this.MessagesChatPage += 1;
  //         await this.$api.cases.getMessagesChat({
  //           caseId: this.caseId,
  //           chatId: this.chatId,
  //           page: this.MessagesChatPage,
  //           size: this.MessagesChatSize
  //         }).then((res) => {
  //           if (res.data.body.content.length) {
  //             if (this.isCheckedAllMessages) {
  //               res.data.body.content.forEach(message => {
  //                 this.selectedMessagesId.push(message.id);
  //                 message.isActive = true;
  //               });
  //             }
  //             this.messages.unshift(...res.data.body.content.reverse());
  //             $state.loaded();
  //           } else {
  //             $state.complete();
  //           }
  //         }, (error) => {
  //           if(error.response) {
  //             this.errorMessage = error.response.data.errors.message[0]
  //           }else {
  //             this.errorMessage = error.data.errors.message[0]
  //           }
  //           $state.error()
  //           this.$refs.alert.showAlert()
  //         })
  //       })
  //     }
  //   },
  //   getAdvancedResultAmount() {
  //     if (this.queryFilterParams.length) {
  //       this.$load(() => {
  //         this.$api.cases.sendAdvancedFilterParams(this.caseId, this.queryFilterParams, 1, this.MessagesAdvancedSize, true)
  //             .then(res => {
  //               this.resultCount = res.data.body.totalElements;
  //             })
  //       });
  //     }
  //   },
  //   infiniteHandlerAdvanced($state) {
  //     if (this.queryFilterParams) {
  //       this.$load(() => {
  //         this.MessagesAdvancedPage += 1;
  //         this.$api.cases.sendAdvancedFilterParams(this.caseId, this.queryFilterParams, this.MessagesAdvancedPage, this.MessagesAdvancedSize, false).then((res) => {
  //           if(res.data.body.content.length) {
  //             this.messagesAdvanced.unshift(...res.data.body.content.reverse());
  //             if ($state) {
  //               // this.infiniteAdvancedId += 1;
  //               this.advancedInfiniteHandlerState = $state;
  //               $state.loaded();
  //             }
  //           }else {
  //             if ($state) {
  //               $state.complete();
  //             }
  //           }
  //         }, (error) => {
  //           if(error.response) {
  //             this.errorMessage = error.response.data.errors.message[0]
  //           }else {
  //             this.errorMessage = error.data.errors.message[0]
  //           }
  //           this.$refs.alert.showAlert()
  //         })
  //       })
  //     }
  //   },
  //   infiniteHandlerMerged($state) {
  //     this.placeholderDate = 'Date range messages'
  //     this.$load(async() => {
  //       await this.$api.cases.getMessagesMerged({
  //         caseId: this.caseId,
  //         clientId: this.clientId,
  //         messengers: this.currentMessengers,
  //         dateFrom: this.dateFrom,
  //         dateTo: this.dateTo,
  //         page: this.MessagesMergedPage,
  //         size: this.MessagesMergedSize
  //       }).then((res) => {
  //
  //         // res.data.body.content.forEach(item => {
  //         //   // console.log(item.id)
  //         // })
  //
  //         if (res.data.body.content.length) {
  //           this.MessagesMergedPage += 1;
  //           this.messagesMerged.unshift(...res.data.body.content.reverse());
  //           $state.loaded();
  //         } else {
  //           $state.complete();
  //         }
  //       }, (error) => {
  //         if(error.response) {
  //           this.errorMessage = error.response.data.errors.message[0]
  //         }else {
  //           this.errorMessage = error.data.errors.message[0]
  //         }
  //         $state.error()
  //         this.$refs.alert.showAlert()
  //       })
  //     })
  //   },
  //
  //   // right msgs
  //   // getMessagesChat(chatId) {
  //   // this.$load(async() => {
  //   //   await this.$api.cases.getMessagesChat(this.caseId, chatId).then((res) => {
  //   //     this.messages = res.data.body.content
  //   //   }, (error) => {
  //   //     if(error.response) {
  //   //       this.errorMessage = error.response.data.errors.message[0]
  //   //     }else {
  //   //       this.errorMessage = error.data.errors.message[0]
  //   //     }
  //   //     this.$refs.alert.showAlert()
  //   //   })
  //   //   setTimeout(() => {
  //   //   }, 1000)
  //   // })
  //   // },
  //
  //   exportMessages(data) {
  //     this.$load(async() => {
  //       await this.$api.cases.exportMessages(this.caseId, this.format, {messageIds: data}).then(() => {
  //         this.selectedMessagesLength = data.length;
  //         this.$refs.successPopup.openPopup();
  //       }, (error) => {
  //         if(error.response) {
  //           this.errorMessage = error.response.data.errors.message[0]
  //         }else {
  //           this.errorMessage = error.data.errors.message[0]
  //         }
  //         this.$refs.alert.showAlert()
  //       })
  //     })
  //   },
  //
  //   getFilterRules() {
  //     this.isLoad = false;
  //     this.$load(async() => {
  //       await this.$api.cases.getFilterRules(this.caseId, this.clientId).then((res) => {
  //         this.initialData = res.data.body;
  //         this.isLoad = true;
  //       })
  //     })
  //   },
  //
  //   setParsedRules(parsedRules) {
  //     this.importRules = {};
  //     this.viewMessages = this.withoutSidebar;
  //     this.isAdvancedFilterApplied = true;
  //
  //     setTimeout(() => {
  //       const { rules } = parsedRules.rules;
  //       const lastRuleRules = rules[rules.length - 1].rules;
  //
  //       this.setSavedRules(!lastRuleRules || lastRuleRules && lastRuleRules.length ? rules : rules.reverse());
  //       this.queryFilterParams = this.getQueryParams(true);
  //
  //       this.filter(true);
  //       this.filter();
  //     }, 1000);
  //   }
  // },
  // async mounted() {
  //   let el = document.querySelectorAll("*[id='js-licensing']")
  //   if(el[0]) {
  //     el[0].remove()
  //   }
  //
  //   const rulesFromServer = localStorage.getItem('rules');
  //   const clientId = +this.$route.query.clientId
  //
  //   if (clientId) {
  //     this.clientId = clientId;
  //     this.currentUser = clientId;
  //   }
  //
  //   await this.getUsersInMsg()
  //   await this.getFilterRules()
  //   this.getMessengers()
  //
  //   if (rulesFromServer) {
  //     const parsedRules = JSON.parse(rulesFromServer);
  //
  //     if (parsedRules.clientId === this.clientId) {
  //       this.setParsedRules(parsedRules);
  //     }
  //   }
  // }
}
</script>
<style>
@import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-querybuilder/styles/material.css";
.e-query-builder {
  margin: 0 auto;
}
</style>
<style scoped>
/*.messages-section {*/
/*  position: relative;*/
/*  display: flex;*/
/*  flex-wrap: wrap;*/
/*  box-shadow: 0 3px 20px rgba(10, 20, 34, 0.1);*/
/*  border-radius: 30px;*/
/*  margin: 27px 55px 27px 35px;*/
/*}*/

/*.filter-content {*/
/*  display: flex;*/
/*  width: 100%;*/
/*}*/

/*.dashboard-search {*/
/*  width: 60%;*/
/*  margin-right: 10px;*/
/*}*/

/*.holder-search {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*}*/

/*.btn-apply {*/
/*  margin: 30px 0;*/
/*}*/

/*.nav-chat {*/
/*  margin-top: 92px;*/
/*  width: 381px;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  border-right: 1px solid #D7DCE6;*/
/*}*/

/*.advanced-filter  .nav-chat {*/
/*  margin-top: 0;*/
/*}*/

/*.chat-list {*/
/*  width: calc(100% - 283px);*/
/*  height: calc(100vh - 200px);*/
/*  min-height: 940px;*/
/*  overflow: auto;*/
/*  margin-top: 30px;*/
/*}*/

/*.holder-selects {*/
/*  display: flex;*/
/*  margin-bottom: 10px;*/
/*}*/

/*.holder-selects .custom-select {*/
/*  width: 40%;*/
/*}*/

/*.custom-select:first-child {*/
/*  margin-right: 10px;*/
/*}*/

/*.custom-select:last-child.selected {*/
/*  width: 147px;*/
/*}*/

/*.holder-select-users {*/
/*  width: 100%;*/
/*  max-width: 30%;*/
/*  margin-right: 12px;*/
/*}*/

/*.section-no-found {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  height: 100vh;*/
/*}*/

/*.section-no-found img {*/
/*  margin-bottom: 30px;*/
/*}*/

/*.section-no-found p {*/
/*  margin-bottom: 18px;*/
/*  font-size: 16px;*/
/*  font-weight: 500;*/
/*  color: var(--light-gray);*/
/*}*/

/*!* top bar *!*/
/*.bar-group {*/
/*  display: flex;*/
/*  align-items: flex-end;*/
/*}*/

/*.top-bar {*/
/*  position: absolute;*/
/*  top: 0;*/
/*  right: 0;*/
/*  left: 0;*/
/*  z-index: 1;*/
/*  padding: 15px 22px;*/
/*  display: flex;*/
/*  justify-content: space-between;*/
/*  align-items: flex-end;*/
/*  flex-wrap: wrap;*/
/*  background: #FFFFFF;*/
/*  box-shadow: 0 4px 10px rgba(77, 106, 147, 0.15);*/
/*  border-radius: 0 29px 0 0;*/
/*}*/

/*.advanced-filter .top-bar {*/
/*  position: static;*/
/*  width: 100%;*/
/*}*/

/*.top-bar-wrapper {*/
/*  display: flex;*/
/*  justify-content: space-between;*/
/*  width: calc(100% - 350px);*/
/*}*/

/*.filters-amount {*/
/*  display: flex;*/
/*  align-items: center;*/
/*  margin-right: 15px;*/
/*  height: 30px;*/
/*  font-size: 14px;*/
/*  font-weight: 500;*/
/*  color: #8692a6;*/
/*}*/

/*.advanced-change-button-wrapper {*/
/*  margin-left: auto;*/
/*  display: flex;*/
/*  align-items: flex-end;*/
/*  height: 60px;*/
/*}*/

/*.advanced-change-button-wrapper.open {*/
/*  margin-left: unset;*/
/*}*/

/*.settings-wrapper {*/
/*  display: flex;*/
/*  justify-content: space-between;*/
/*  align-items: flex-end;*/
/*  flex: 1;*/
/*}*/

/*.filter-types {*/
/*  border-radius: 79px;*/
/*  outline: 1px solid var(--case-btn-border-color);*/
/*  width: fit-content;*/
/*}*/

/*.filter-type {*/
/*  position: relative;*/
/*  z-index: 2;*/
/*  transition: outline 0.3s, color 0.3s;*/
/*  border: none;*/
/*  border-radius: 79px;*/
/*  outline: 1px solid transparent;*/
/*  width: 144px;*/
/*  padding: 5px 16px;*/
/*  background-color: transparent;*/
/*  font-size: 14px;*/
/*  font-weight: 600;*/
/*  line-height: 17px;*/
/*  color: var(--light-gray);*/
/*}*/

/*.filter-type.selected,*/
/*.filter-type:hover {*/
/*  outline: 1px solid var(--accent-color);*/
/*  color: var(--accent-color);*/
/*}*/

/*.device-configure {*/
/*  height: 28px;*/
/*  margin-right: 23px;*/
/*}*/

/*.reset-filters {*/
/*  background-color: transparent;*/
/*  display: flex;*/
/*  align-items: center;*/
/*  border: none;*/
/*  margin-right: 23px;*/
/*  padding: 0;*/
/*  font-weight: 500;*/
/*  font-size: 14px;*/
/*}*/

/*.reset-filters:disabled path {*/
/*  fill: var(--case-btn-border-color);*/
/*}*/

/*.reset-filters:disabled {*/
/*  cursor: not-allowed;*/
/*  color: var(--button-disabled-color);*/
/*}*/

/*.reset-filters svg {*/
/*  margin-right: 10px;*/
/*}*/

/*.add-to-favorites {*/
/*  margin-right: 23px;*/
/*}*/

/*.advanced-change-button {*/
/*  height: 30px;*/
/*  display: flex;*/
/*  align-items: center;*/
/*  border: none;*/
/*  outline: none;*/
/*  padding: 0;*/
/*  background-color: unset;*/
/*}*/

/*.advanced-change-button span {*/
/*  margin: 0 6px;*/
/*  font-weight: 500;*/
/*  font-size: 14px;*/
/*  line-height: 17px;*/
/*  color: #373C44;*/
/*}*/

/*.advanced-change-button:hover span {*/
/*  text-decoration: underline;*/
/*}*/

/*.advanced-mode {*/
/*  fill: #082655;*/
/*}*/

/*.advanced-mode-arrow {*/
/*  transform: rotate(180deg);*/
/*}*/

/*.holder-layout-checkbox {*/
/*  width: 178px;*/
/*  margin-right: 12px;*/
/*}*/

/*.holder-name {*/
/*  display: flex;*/
/*  justify-content: flex-start;*/
/*  align-items: center;*/
/*}*/

/*.img-item {*/
/*  width: 32px;*/
/*  height: 32px;*/
/*  overflow: hidden;*/
/*  border: 1px solid var(--white-color);*/
/*  border-radius: 50%;*/
/*}*/

/*.holder-social {*/
/*  display: flex;*/
/*  align-items: center;*/
/*}*/

/*.social-img {*/
/*  width: 14px;*/
/*  margin-right: 7px;*/
/*}*/

/*.social-name {*/
/*  text-transform: uppercase;*/
/*  font-weight: 600;*/
/*  font-size: 10px;*/
/*}*/

/*.holder-select-messanger {*/
/*  width: 100%;*/
/*  max-width: 30%;*/
/*  margin-right: 12px;*/
/*}*/

/*.holder-datepicker {*/
/*  width: 100%;*/
/*  max-width: 30%;*/
/*}*/

/*.mx-datepicker-range {*/
/*  width: 100%;*/
/*}*/

/*!* end top bar *!*/

/*!* for view group *!*/
/*.chat-list-group {*/
/*  width: 100%;*/
/*  height: calc(100vh - 500px);*/
/*  min-height: 940px;*/
/*  overflow: auto;*/
/*  margin-top: 30px;*/
/*}*/

/*.bar-group-layout {*/

/*}*/

/*.bar-group-filter {*/
/*  width: calc(100% - 200px);*/
/*}*/

/*.infinite-loading-container {*/
/*  margin-top: 100px;*/
/*}*/

/*.empty-chats-wrap {*/
/*  text-align: center;*/
/*  margin-top: 45px;*/
/*}*/

/*label {*/
/*  display: block;*/
/*  margin-bottom: 5px;*/
/*}*/

/*.advanced-filter-wrap {*/
/*  width: 100%;*/
/*  margin-top: 40px;*/
/*  position: relative;*/
/*  !*display: none;*!*/
/*}*/

/*.e-query-builder {*/
/*  width: 100% !important;*/
/*}*/

/*.control-tags {*/
/*  margin-bottom: 0;*/
/*  display: flex;*/
/*  gap: 10px;*/
/*  flex-wrap: wrap;*/
/*}*/

/*.filter-tag {*/
/*  display: flex;*/
/*  align-items: center;*/
/*  width: max-content;*/
/*  padding: 6px 12px;*/
/*  border-radius: 20px;*/
/*  background-color: var(--accent-color);*/
/*  font-size: 13px;*/
/*  color: var(--white-color);*/
/*}*/

/*.filter-tag button {*/
/*  display: flex;*/
/*  margin-right: 8px;*/
/*  border: navajowhite;*/
/*  padding: 0;*/
/*  background-color: unset;*/
/*}*/

/*.filter-tag.removed path {*/
/*  fill: #ff9537;*/
/*}*/

/*.filter-tag svg {*/
/*  width: 18px;*/
/*  height: 18px;*/
/*}*/

/*.filter-tag path {*/
/*  transition: fill 0.3s;*/
/*}*/

/*.filter-tag strong {*/
/*  text-transform: capitalize;*/
/*}*/

/*.filter-tags-close {*/
/*  display: flex;*/
/*  align-items: center;*/
/*}*/

/*.filter-tags-arrow {*/
/*  transition: all 0.3s;*/
/*}*/

/*.filtering-name b {*/
/*  color: red;*/
/*}*/


</style>
