import { render, staticRenderFns } from "./PermissionsCaseItem.vue?vue&type=template&id=a6b32d64&scoped=true"
import script from "./PermissionsCaseItem.vue?vue&type=script&lang=js"
export * from "./PermissionsCaseItem.vue?vue&type=script&lang=js"
import style0 from "./PermissionsCaseItem.vue?vue&type=style&index=0&id=a6b32d64&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../usr/local/lib/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a6b32d64",
  null
  
)

export default component.exports