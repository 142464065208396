import { render, staticRenderFns } from "./SetMessages.vue?vue&type=template&id=71540e3c&scoped=true"
import script from "./SetMessages.vue?vue&type=script&lang=js"
export * from "./SetMessages.vue?vue&type=script&lang=js"
import style0 from "./SetMessages.vue?vue&type=style&index=0&id=71540e3c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../usr/local/lib/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71540e3c",
  null
  
)

export default component.exports