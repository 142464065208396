export default {
    getPermissionsRole({ commit }, data) {
        return new Promise((resolve, reject) => {
            window.instance.get(`/permission/role?role=${data}`).then((res) => {
                commit('SET_ROLE_DATA', res.data.body);
                resolve(res);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    getPermissionUser({ commit }, data) {
        return new Promise((resolve, reject) => {
            window.instance.get(`/permission/user?userId=${data}`).then((res) => {
                commit('SET_USER_DATA', res.data.body);
                resolve(res);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    getPermissionsCaseUser({ commit }, {userId, caseId}) {
        return new Promise((resolve, reject) => {
            window.instance.get(`/permission/user/case?userId=${userId}&caseId=${caseId}`).then((res) => {
                commit('SET_USER_DATA', res.data.body);
                resolve(res);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    resetPermissionsUser({ commit }, userId) {
        return new Promise((resolve, reject) => {
            window.instance.post(`/permission/user/reset?userId=${userId}`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((res) => {
                commit('SET_USER_DATA', res.data.body);
                resolve(res);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    getPermissionCase({ commit }, data) {
        return new Promise((resolve, reject) => {
            window.instance.get(`/cases/search?caseName=${data}`).then((res) => {
                commit('SET_CASE_DATA', res.data.body);
                resolve(res);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    getPermissionCaseUser({ commit }, {caseId, sortBy, keySearch, order, roles}) {
        return new Promise((resolve, reject) => {
            window.instance.get(`/caseUser/${caseId}/users?sortBy=${sortBy}&keySearch=${keySearch}&order=${order}&roles=${roles}`).then((res) => {
                commit('SET_CASE_USERS_DATA', res.data.body);
                resolve(res);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    getUser({ commit }) {
        return new Promise((resolve, reject) => {
            window.instance.get(`/profile`).then((res) => {
                res.data.body.fullName = (res.data.body.firstName.toLowerCase()[0].toUpperCase() + res.data.body.firstName.toLowerCase().slice(1)) + ' ' + (res.data.body.lastName.toLowerCase()[0].toUpperCase() + res.data.body.lastName.toLowerCase().slice(1))
                commit('SET_USER_INFO', res.data.body);
                resolve(res);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    getPermissionPerCase({ commit }, {caseId}) {
        return new Promise((resolve, reject) => {
            window.instance.get(`/caseUser/${caseId}/permissions`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((res) => {
                commit('SET_PERMISSION_PER_CASE', res.data.body);
                resolve(res);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    // loginOtp({ commit }, {params}) {
    //     return new Promise((resolve, reject) => {
    //         window.instance.post(`/login/otp`, params).then((res) => {
    //             commit('SET_LOGIN_DATA_OTP', res.data.body);
    //             resolve(res);
    //         })
    //             .catch((err) => {
    //                 reject(err);
    //             });
    //     });
    // },







}