<template>
  <div v-if="loadFlag" class="col-lg-12 querybuilder-control">
    <ejs-querybuilder
        ref="querybuilder"
        :dataSource="dataSource"
        :maxGroupCount="100"
        :rule="importRulesCopy"
        @change="ruleChanged"
    >
      <e-columns>
        <e-column
            :operators="[{ value: 'is', key: 'is' }, { value: 'is_not', key: 'is not' }, { value: 'contain', key: 'contain' }, { value: 'not_contain', key: 'not contain' }]"
            field="folder"
            label="Folder"
            type="string"
        />
        <e-column
            :operators="[{ value: 'is', key: 'is' }, { value: 'is_not', key: 'is not' }, { value: 'contain', key: 'contain' }, { value: 'not_contain', key: 'not contain' }]"
            :template="textTemplate"
            field="message"
            label="Phrase"
            type="string"
        />
        <e-column
            :operators="[{ value: 'is', key: 'is' }, { value: 'is_not', key: 'is not' }, { value: 'contain', key: 'contain' }, { value: 'not_contain', key: 'not contain' }]"
            field="subject"
            label="Subject"
            type="string"
        />
        <e-column
            :operators="[{ value: 'is', key: 'is' }, { value: 'is_not', key: 'is not' }, { value: 'contain', key: 'contain' }, { value: 'not_contain', key: 'not contain' }]"
            :template="defaultTemplate"
            field="senderName"
            label="Sender name"
            type="string"
        />
        <e-column
            :operators="[{ value: 'in', key: 'in' }, { value: 'not_in', key: 'not in' }, { value: 'contain', key: 'contain' }, { value: 'not_contain', key: 'not contain' }]"
            :template="defaultTemplate"
            field="senderEmail"
            label="Sender email"
            type="string"
        />
        <e-column
            :operators="[{ value: 'is', key: 'is' }, { value: 'is_not', key: 'is not' }, { value: 'contain', key: 'contain' }, { value: 'not_contain', key: 'not contain' }]"
            :template="defaultTemplate"
            field="recipientName"
            label="Recipient name"
            type="string"
        />
        <e-column
            :operators="[{ value: 'in', key: 'in' }, { value: 'not_in', key: 'not in' }, { value: 'contain', key: 'contain' }, { value: 'not_contain', key: 'not contain' }]"
            :template="defaultTemplate"
            field="recipientEmail"
            label="Recipient email"
            type="string"
        />
        <e-column
            :operators="[{ value: 'is', key: 'is' }, { value: 'is_not', key: 'is not' }, { value: 'contain', key: 'contain' }, { value: 'not_contain', key: 'not contain' }]"
            :template="defaultTemplate"
            field="recipientTo"
            label="Recipient of TO type"
            type="string"
        />
        <e-column
            :operators="[{ value: 'is', key: 'is' }, { value: 'is_not', key: 'is not' }, { value: 'contain', key: 'contain' }, { value: 'not_contain', key: 'not contain' }]"
            :template="defaultTemplate"
            field="recipientCc"
            label="Recipient of CC type"
            type="string"
        />
        <e-column
            :operators="[{ value: 'is', key: 'is' }, { value: 'is_not', key: 'is not' }, { value: 'contain', key: 'contain' }, { value: 'not_contain', key: 'not contain' }]"
            :template="defaultTemplate"
            field="recipientBcc"
            label="Recipient of BCC type"
            type="string"
        />
        <e-column
            :operators="[{ value: 'range', key: 'range' }]"
            :template="dateTemplate"
            field="date"
            format="dd/MM/yyyy"
            label="Date"
            type="date"
        />
      </e-columns>
    </ejs-querybuilder>
    <div class="advanced-btn-wrapper">
      <button class="btn btn-apply" type="button" @click="addRule">+ Add Rule</button>
      <button class="btn btn-apply" type="button" @click="addGroup">+ Add Group</button>
      <button
          :disabled="emptyRule"
          class="btn btn-apply"
          type="button"
          @click="$emit('filter', false, getQueryParams(true))"
      >
        Apply
      </button>
      <span>{{ resultCount }} matches</span>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { QueryBuilderPlugin } from "@syncfusion/ej2-vue-querybuilder";
import { DateRangePicker } from '@syncfusion/ej2-calendars';
import { MultiSelect, CheckBoxSelection, AutoComplete } from '@syncfusion/ej2-dropdowns';
import { createElement, getComponent } from "@syncfusion/ej2-base";
import { Query } from '@syncfusion/ej2-data';
import debounce from '@/mixins/debounce';

MultiSelect.Inject(CheckBoxSelection);
Vue.use(QueryBuilderPlugin);

let inOperators = ['is', 'is_not', 'contain', 'not_contain'];
const actionTypeList = ['value', 'deleteRule', 'deleteGroup', 'condition'];

export default {
  name: 'AdvancedSearch',
  emits: ['filter', 'reset-count', 'set-advanced-messages', 'reset-search'],
  mixins: [debounce],
  props: {
    initialData: {
      type: Array,
      required: true,
    },
    dataSource: {
      type: Array,
      required: true,
    },
    importRules: {
      type: Object,
      required: true,
    },
    resultCount: {
      type: Number,
      required: true,
    },
    clientList: {
      type: Array,
      required: true,
    },
    currentClientId: {
      type: String,
      required: true,
    },
    localStorageRulesName: {
      type: String,
      required: true,
      default: '',
    }
  },
  data() {
    return {
      observerId: null,
      loadFlag: false,
      importRulesCopy: {},
      emptyRule: true,
      queryFilterParams: [],
      localStorageData: {},
      dateTemplate: {
        create: () => {
          return createElement('input', { attrs: { 'type': 'text' } });
        },
        destroy: (args) => {
          let daterangepicker = getComponent(document.getElementById(args.elementId), 'daterangepicker');
          if (daterangepicker)
            daterangepicker.destroy();
        },
        write: (args) => {
          let daterangepicker = new DateRangePicker({
            placeholder: 'Select date range',
            disabled: true,
            change: (e) => {
              this.$refs.querybuilder.$el.ej2_instances[0].notifyChange(e.value, e.element);
            },
          });

          if (args.values) {
            daterangepicker.startDate = new Date(this.formatDate(args.values[0]));
            daterangepicker.endDate = new Date(this.formatDate(args.values[1]));
          }

          daterangepicker.appendTo('#' + args.elements.id);
        }
      },
      defaultTemplate: {
        create: () => {
          return createElement('input', { attrs: { 'type': 'text' } });
        },
        destroy: (args) => {
          let multiselect = getComponent(document.getElementById(args.elementId), 'multiselect');
          if (multiselect)
            multiselect.destroy();
          let autoComplete = getComponent(document.getElementById(args.elementId), 'autocomplete');
          if (autoComplete)
            autoComplete.destroy();
        },
        write: (args) => {
          let ds = [];

          this.initialData.forEach(item => {
            if (item.fieldName === args.field) {
              item.values.forEach(el => {
                // let elName = el[0].toUpperCase() + el.toLowerCase().slice(1);
                // console.log('args el name: ', elName)
                // ds.push({ id: el, value: elName });
                ds.push({ id: this.createUniqKey(), value: el });
              });
            }
          });

          // if (args.field === 'folder') {
          //   ds = this.clientList;
          // } else {
          //   this.initialData.forEach(item => {
          //     if (item.fieldName === args.field) {
          //       item.values.forEach(el => {
          //         let elName = el[0].toUpperCase() + el.toLowerCase().slice(1);
          //         ds.push({ id: el, value: elName });
          //       });
          //     }
          //   });
          // }

          if (inOperators.indexOf(args.operator) > -1) {
            let autoCompleteObj = new AutoComplete({
              dataSource: ds,
              value: args.values,
              fields: { text: 'value', value: 'id' },
              placeholder: `Find a ${this.stringModify(args.field)}`,
              highlight: true,
              itemTemplate: "<span class='filtering-name'>${id}</span>",
              change: (e) => {
                const { prevItemData } = this.$refs.querybuilder.$el.ej2_instances[0];
                let name = '';

                if (e.itemData) {
                  name = e.itemData.id;
                }

                if (prevItemData) {
                  if (prevItemData.field === 'phoneNumber' || prevItemData.field === 'imei') {
                    name = name.replace(/[()]/g, '');
                  }
                }

                this.$refs.querybuilder.$el.ej2_instances[0].notifyChange(name, e.element);
                // if (!prevItemData) {
                //   return;
                // }
                // let name = '';
                // if (e.itemData) {
                //   name = e.itemData.id;
                // }
                // if (prevItemData.field === 'phoneNumber' || prevItemData.field === 'imei') {
                //   name = name.replace(/[()]/g, '');
                // }
                // this.$refs.querybuilder.$el.ej2_instances[0].notifyChange(name, e.element);
              }
            });

            autoCompleteObj.appendTo('#' + args.elements.id);
          } else {
            // hot fix for weird bug with parse symbols <> like a html tag
            const newDs = ds.map(el => {
              return {
                value: el.value.replace(/[<>]/g, ''),
                id: el.id
              }
            });
            //

            let multiSelectObj = new MultiSelect({
              allowCustomValue: true,
              dataSource: newDs,
              value: args.values,
              fields: { text: 'value', value: 'id' },
              mode: 'CheckBox',
              placeholder: `Select ${this.stringModify(args.field)}`,
              change: (e) => {
                // hot fix for weird bug with parse symbols <> like a html tag
                const idList = [];
                const resultList = [];

                newDs.forEach(value => {
                  e.value.forEach(value2 => {
                    if (value.id === value2) {
                      idList.push(value.id);
                    }
                  });
                });

                ds.forEach(el => {
                  if (idList.includes(el.id)) {
                    resultList.push(el.value);
                  }
                });
                //

                this.$refs.querybuilder.$el.ej2_instances[0].notifyChange(resultList, e.element);
              },
              filtering: function (e) {
                let query = new Query();
                //frame the query based on search string with filter type.
                query = (e.text !== '') ? query.where("value", "startswith", e.text, true) : query;
                //pass the filter data source, filter query to updateData method.
                e.updateData(newDs, query);
              }
            });
            multiSelectObj.appendTo('#' + args.elements.id);
            if (args.values) {
              multiSelectObj.change(multiSelectObj);
            }
          }

          // if (inOperators.indexOf(args.operator) > -1) {
          //   multiSelectObj.maximumSelectionLength = 1
          // } else {
          //   multiSelectObj.maximumSelectionLength = 1000
          // }

        }
      },
      textTemplate: {
        create: () => {
          return createElement('input', { attrs: { 'type': 'text' } });
        },
        destroy: (args) => {
          let autoComplete = getComponent(document.getElementById(args.elementId), 'autocomplete');
          autoComplete.destroy();
        },
        write: (args) => {
          let ds = [];

          this.initialData.forEach(item => {
            if (this.$refs.querybuilder.$el.ej2_instances[0].prevItemData && item.fieldName === this.$refs.querybuilder.$el.ej2_instances[0].prevItemData.field) {
              item.values.forEach(el => {
                ds.push({ Name: el, Code: el });
              });
            }
          });

          let autoCompleteObj = new AutoComplete({
            dataSource: ds,
            value: args.values,
            fields: { value: "Code", text: "Name" },
            placeholder: `Find a `,
            highlight: true,
            itemTemplate: "<span class='filtering-name'>${Name}</span>",
            change: (e) => {
              const { field } = this.$refs.querybuilder.$el.ej2_instances[0].prevItemData;
              let name = e.itemData.Name;

              if (field === 'phoneNumber' || field === 'imei') {
                name = name.replace(/[()]/g, '');
              }

              this.$refs.querybuilder.$el.ej2_instances[0].notifyChange(name, e.element);
            }
          });
          autoCompleteObj.appendTo('#' + args.elements.id);
        }
      },
    };
  },

  watch: {
    currentClientId(newVal) {
      if (this.localStorageData.clientId && this.localStorageData.clientId === newVal) {
        this.setParsedRules(this.localStorageData);
      }
    },
    emptyRule(newVal) {
      if (newVal) {
        this.$emit('reset-count');
      }
    },
  },

  computed: {
    isValidQueryFilterParams() {
      return this.queryFilterParams
      && this.queryFilterParams.length
        ? this.queryFilterParams.length === 1
        && this.queryFilterParams[0]
        && !this.queryFilterParams[0].rules.length
        || this.queryFilterParams[0].rules[0]
        && !this.queryFilterParams[0].rules[0].value
        : true;
    },
    // isValidQueryFilterParams() {
    //   console.log('queryFilterParams computed: ', this.queryFilterParams);
    //   return this.queryFilterParams.length === 1
    //       && this.queryFilterParams[0]
    //       && !this.queryFilterParams[0].rules.length
    //       || this.queryFilterParams[0].rules[0]
    //       && !this.queryFilterParams[0].rules[0].value;
    // },
  },

  methods: {
    createUniqKey(num = 1) {
      return +num + (Math.round(Math.random() * 1000) * Math.round(Math.random() * 1000));
    },
    formatDate(date) {
      const initial = date.split(/\//);

      return [initial[1], initial[0], initial[2]].join('/');
    },
    stringModify(str) {
      return str.split('').map((letter, idx) => {
        return letter.toUpperCase() === letter
            ? `${idx !== 0 ? ' ' : ''}${letter.toLowerCase()}`
            : letter;
      }).join('');
    },

    // checkIsNestedRulesNotEmpty(rules) {
    //   return rules.every(rule => {
    //     if (rule.value === undefined) return true;
    //
    //     return rule.rules
    //         ? this.checkIsNestedRulesNotEmpty(rule.rules)
    //         : rule.value && rule.value.length;
    //   });
    // },

    isCondition(item) {
      return item.condition !== undefined;
    },

    hasRuleset(condition) {
      return !!condition.rules && !!condition.rules.length;
    },

    flat(currentCondition) {
      if (!this.hasRuleset(currentCondition)) {
        return;
      }

      // Filtering current ruleset to find inner conditions with non-empty rulesets;
      const nestedConditions = currentCondition.rules.filter(
          (item) => this.isCondition(item) && this.hasRuleset(item)
      );

      return [
        {
          ...currentCondition,
          // We exclude inner conditions from the ruleset and leave only the descriptions;
          rules: currentCondition.rules.filter((item) => !this.isCondition(item)),
        },
        ...nestedConditions.reduce(
            // We recursively call this function with all of the nested conditions;
            (acc, condition) => [...acc, ...this.flat(condition)],
            []
        ),
      ];
    },

    flattenRules(rootRule) {
      if (!this.isCondition(rootRule) || !this.hasRuleset(rootRule)) {
        return [];
      }

      return this.flat(rootRule);
    },

    flatten(conditionsResult, setId) {
      let conditionsResultArray = this.flattenRules(conditionsResult);
      conditionsResultArray.forEach((item) => {
        if (item.condition === null) {
          item.condition = 'and';
        }

        if (item.rules.length) {
          item.rules.forEach((rule) => {
            if (setId) {
              rule.id = Math.random().toString(36).substr(2, 9);
            }

            if (rule.field !== 'date') {
              // if (rule.field === 'folder') {
              //   rule.field = 'clientId';
              // }

              if (Array.isArray(rule.value)) {
                rule.value = rule.value.join(';');
              }
            }
          });
        }
      });

      return conditionsResultArray;
    },

    getQueryParams(setId = false) {
      const filters = this.$refs.querybuilder.ej2Instances.getValidRules(this.$refs.querybuilder.ej2Instances.rule);
      // console.log('getQueryParams: ', filters, setId);
      return this.flatten(filters, setId);
    },

    changedQueryBuilder(e) {
      // console.log('changedQueryBuilder: ', e);
      if (e.type === 'insertRule') {
        return;
      }

      this.queryFilterParams = this.getQueryParams();

      if (this.isValidQueryFilterParams) {
        if (e.type === 'deleteRule') {
          // console.log('deleteRule e: ', e);
        }
        this.$emit('reset-count');
        return;
      }

      if (e.type === 'deleteGroup') {
        const groupID = e.groupID.split('group').join('');

        this.queryFilterParams.splice(groupID, 1);
      }

      if (actionTypeList.some(type => type === e.type)) {
        this.$emit('filter', true, this.queryFilterParams);
      }
    },

    ruleChanged(e) {
      this.emptyRule = true;

      const validDataArr = [];
      const validFieldCheck = function (arr = []) {
        if (!arr.length) {
          return;
        }

        arr.forEach((el) => {
          if (el.rules && el.rules.length) {
            validFieldCheck(el.rules);
            return;
          }
          if (!el.field) {
            return;
          }
          if (el.value === undefined) {
            return;
          }

          validDataArr.push(el.value ? !!el.value.length : false);
        });
      };

      validFieldCheck(this.$refs.querybuilder.getRules().rules);

      const textInputDelay = Object.prototype.hasOwnProperty.call(e, 'cancel') && !e.cancel;
      this.debounce(() => {
          this.emptyRule = validDataArr.length ? validDataArr.some(boolean => !boolean) : true;
          this.changedQueryBuilder(e);
      },
        textInputDelay ? 300 : 0
      );
    },

    // ruleChanged(event) {
    //   const rules = [];
    //
    //   this.$refs.querybuilder.getRules().rules.forEach((rule, ruleIndex) => {
    //     if (rule.rules) {
    //       if (!rule.rules.length) {
    //         return;
    //       }
    //
    //       rules.push(this.checkIsNestedRulesNotEmpty(rule.rules));
    //       return;
    //     }
    //
    //     // for first pos empty object
    //     if (!Object.prototype.hasOwnProperty.call(rule, 'value') && ruleIndex < 1) {
    //       return;
    //     }
    //
    //     rules.push(rule.value && !!rule.value.length);
    //   });
    //
    //   const textInputDelay = Object.prototype.hasOwnProperty.call(event, 'cancel') && !event.cancel;
    //   this.debounce(() => {
    //       this.emptyRule = rules.length && rules.some(rule => rule !== undefined && !rule);
    //       this.changedQueryBuilder(event);
    //     },
    //     textInputDelay ? 300 : 0
    //   );
    // },
    addRule(_, rule) {
      const colArr = Object.keys(this.$refs.querybuilder.ej2Instances.levelColl);
      const lastElIndex = colArr.length - 1;
      const groupId = colArr[lastElIndex].split('group')[1];

      this.$refs.querybuilder.ej2Instances.addRules([rule || { field: 'folder', value: null }], `group${groupId}`);

      const nodeList = this.$refs.querybuilder.$el.querySelectorAll('.e-query-builder > .e-group-container .e-rule-container');
      const arr = Array.prototype.slice.call(nodeList);
      // founded rules in first .e-group-container
      const ruleList = arr.filter(el => el.id.includes('group0'));

      ruleList.forEach((ruleEl, ruleIndex) => {
        if (ruleIndex !== ruleList.length - 1) {
          ruleEl.classList.remove('last-rule-container');
        }
      });
    },
    addGroup(_, group) {
      this.$refs.querybuilder.ej2Instances.addGroups([group || {
        'condition': 'and',
        'rules': [{}, { field: 'folder', value: null }]
      }], 'group0');

      document.querySelectorAll('.e-group-container').forEach(container => {
        const { classList } = container.previousSibling;

        if (classList && !classList.contains('last-rule-container')) {
          container.previousSibling.classList.add('last-rule-container');
        }
      });
    },
    setSavedRules(rules) {
      rules.forEach(rule => {
        if (rule.rules && rule.rules.length) {
          this.addGroup(false, { 'condition': rule.condition || 'and', 'rules': [{}] });
          this.setSavedRules(rule.rules);
        } else if (rule.value) {
          this.addRule(false, rule);
        }
      });
    },
    setParsedRules(parsedRules) {
      this.importRulesCopy = {};
      this.loadFlag = true;
      setTimeout(() => {
        const { rules } = parsedRules.rules;
        const lastRuleRules = rules[rules.length - 1].rules;

        this.setSavedRules(!lastRuleRules || lastRuleRules && lastRuleRules.length ? rules : rules.reverse());

        const queryFilterParams = this.getQueryParams(true);
        if (queryFilterParams.length) {
          this.$emit('set-advanced-messages', queryFilterParams);
        }

        this.queryFilterParams = queryFilterParams;
      }, 1000);
    },
    replacer(str = '') {
      const regex = /[;\s]/g;
      return str.toLowerCase().replace(regex, '');
    },
    startObserver() {
      this.observerId = new MutationObserver(this.removeLicensePopups);
      this.observerId.observe(document.body, { childList: true });
    },
    removeLicensePopups(mutations) {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList' && mutation.addedNodes[0]) {
          const keyWords = ['z-index:999999999', 'rgb(238, 242, 255)', 'rgba(0, 0, 0, 0.5)', 'z-index:99999'];
          const keyPhrase = 'Claim your free account';
          const element = mutation.addedNodes[0];
          const styles = element.style.cssText.split(';') || [];
          const result = [];

          if (styles && styles.length) {
            styles.forEach(el => {
              keyWords.forEach(keyEl => {
                if (this.replacer(el).includes(this.replacer(keyEl))) {
                  result.push(1);
                }
              });
              // if (result.length === keyWords.length) {
              //   element.remove();
              // }
            });
            if (result.length > 1) {
              element.remove();
            }
            if (this.replacer(element.textContent).includes(this.replacer(keyPhrase))) {
              element.remove();
            }
          }
        }
      });
    },
  },

  mounted() {
    const el = document.querySelectorAll("*[id='js-licensing']");
    if (el[0]) {
      el[0].remove();
    }
    // document.querySelectorAll('a').forEach(element => {
    //   if (element.textContent.includes('Claim your free account')) {
    //     element.parentNode.remove();
    //   }
    // });
    this.startObserver();

    const storageRules = localStorage.getItem(this.localStorageRulesName);
    if (storageRules) {
      this.localStorageData = JSON.parse(storageRules);
      if (this.localStorageData.clientId) {
        if (this.localStorageData.clientId === this.currentClientId) {
          this.setParsedRules(this.localStorageData);
        }
      } else {
        this.$emit('reset-search');
      }
    } else {
      this.importRulesCopy = this.importRules;
      this.loadFlag = true;
    }

  },
  beforeDestroy() {
    if (this.observerId) {
      const mutations = this.observerId.takeRecords();

      this.observerId.disconnect();
      if (mutations && mutations.length) {
        this.removeLicensePopups(mutations);
      }
    }
  }
};
</script>

<style scoped>

</style>