<template>
  <charts ref="fff" :options="options"></charts>
</template>

<script>
import moment from 'moment';

export default {
  name: "ColumnChart",
  props: {
    dataActivity: {
      type: Object
    },
    max: {
      type: [String, Number]
    }
  },
  data() {
    return {
      options: {
        series: [{
          name: 'pme',
          data: [],
          borderRadiusTopLeft: 50,
          borderRadiusTopRight: 50
        }],
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        chart: {
          type: 'column',
          height: 200,
          width: 400,
        },
        title: {
          text: 'title',
          style: {
            color: '#fff',
          }
        },
        xAxis: {
          categories: [],
          crosshair: true,
          type: 'category',
          labels: {
            style: {
              color: '#8692A6'
            }
            // formatter: function(d) {
            //   console.log(d)
            //   return '<a>text</a>'
            // },
            // useHTML:true
          }
        },
        yAxis: {
          lineColor: '#D7DCE6',
          lineWidth: 1,
          gridLineWidth: 0,
          min: 0,
          max: this.max,
          tickInterval: 25,
          plotBands: [{
            from: 40,
            to: 60,
            color: '#fff',
            label: {
              text: ''
            }
          }],
          labels: {
            style: {
              color: '#8692A6'
            },
          }
        },
        tooltip: {
          headerFormat: '',
          pointFormat: '<td style="padding:0"><b>{point.y}</b></td></tr>',
          footerFormat: '',
          shared: true,
          useHTML: true
        },
        colors: [
          '#EFF0F1',
        ],
        plotOptions: {
          series: {
            states: {
              hover: {
                color: '#082655',
                borderColor: '#082655',
                animation: {
                  duration: 400
                }
              }
            },
            borderRadiusTopLeft: '50%',
            borderRadiusTopRight: '50%'
          }
        },
      }
    }
  },

  methods: {},

  mounted() {
    this.dataActivity.messages.forEach(message => {
      this.options.xAxis.categories.push(moment(message.messagesDate).format('DD/MM'));
      this.options.series[0].data.push(message.messagesAmount)
    })
  }
}


</script>

<style scoped>

</style>
