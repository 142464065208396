<template>
  <div class="wrapper">
    <Sidebar />
    <div class="content-wrapper">
      <HeaderRegistered return-path/>
      <main class="main">
        <section class="profile-section">
          <ul class="profile-nav">
            <router-link
              :to="{ path: `/cases/${this.$route.params.slug}/settings/general` }"
              tag="li"
              id="general"
              exact-path
              :class="{'active': $route.path === ('/cases')}"
            >
              <a>General</a>
            </router-link>
          </ul>
          <div class="profile-content">
            <router-view></router-view>
          </div>
        </section>
      </main>
    </div>
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import Sidebar from "@/components/Sidebar";
import HeaderRegistered from "@/components/layout/HeaderRegistered";

export default {
  name: "CaseSettings",
  components: {
    Sidebar,
    HeaderRegistered,
  },
  methods: {
    checkPermission,
  },
  mounted() {
    document.title = 'Case Settings'
  },
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}

p {
  margin-top: 0;
}

ul {
  padding: 0;
}

.wrapper {
  display: flex;
}

.content-wrapper {
  width: calc(100% - 120px);
}

.profile-section {
  display: flex;
  background-color: var(--white-color);
  padding: 0 15px;
  align-items: initial;
}

.profile-nav {
  display: flex;
  flex-direction: column;
  list-style: none;
  width: 260px;
  height: 85vh;
  border-right: 1px solid var(--border-color);
  margin: 0 0 0 20px;
}

.profile-nav li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--light-gray);
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all .2s;
  border-bottom: 1px solid var(--border-color);
}

.profile-nav li:after {
  content: '';
  width: 8px;
  height: 100%;
  background-color: var(--accent-color);
  border-radius: 10px 0 0 10px;
  opacity: 0;
  transition: all .2s;
}

.profile-nav li a {
  display: block;
  color: var(--light-gray);
  width: 100%;
  height: 100%;
  text-decoration: none;
  padding: 25px 0 25px 20px;
}

.profile-nav li a:hover {
  color: var(--secondary-black-color);
  background-color: rgba(28, 202, 205, 0.05);
}

.profile-nav li:hover:after  {
  opacity: 1;
}

.profile-nav li.active::after {
  opacity: 1;
}

.profile-nav li.active a {
  background: var(--active-accent-color);
  color: var(--secondary-black-color);
}

.profile-content {
  width: calc(100% - 270px);
  padding: 20px 40px;
}
</style>