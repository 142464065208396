import axios from 'axios'
import LocalStorageService from "../services/LocalStorageService";
import fixBaseURL from "@/utils/fixBaseURL";

const localStorageService = LocalStorageService.getService();

const fixedBaseURL = fixBaseURL(`${process.env.VUE_APP_API}/api/v1`);

const customAxios = axios.create({
    baseURL: fixedBaseURL,
    headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
    }
})

// Add a request interceptor
customAxios.interceptors.request.use(
    config => {
        // config.headers['Content-Type'] = 'application/json';
        const token = localStorageService.getAccessToken();
        const tokenLogin = localStorageService.getAccessTokenLogin();
        if (token) {
            localStorageService.clearTokenLogin()
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        if (tokenLogin) {
            config.headers['Authorization'] = 'Bearer ' + tokenLogin;
        }
        config.headers['Cache-Control'] = 'no-cache';
        config.headers['Pragma'] = 'no-cache';
        config.headers['Expires'] = '0';
        return config;
    },
    error => {
        Promise.reject(error)
    }
);

//Add a response interceptor

customAxios.interceptors.response.use((response) => {
    return response
}, function (error) {
//   const originalRequest = error.config;
//
//   if (error.response.status === 401 && originalRequest.url === `${originalRequest.baseURL}/api/v2/token/refresh`) {
//     router.push('/login');
//   return Promise.reject(error);
// }
//
// if (error.response.status === 401 && !originalRequest._retry) {
//     originalRequest._retry = true;
//   const refreshToken = localStorageService.getRefreshToken();
//   return  axios.post(`${originalRequest.baseURL}api/v2/token/refresh`,
//       {
//           "refresh": refreshToken,
//       })
//       .then((response) => {
//           localStorageService.setToken(response.data.body.accessToken, response.data.body.refreshToken);
//           instance.defaults.headers.common['Authorization'] = localStorageService.getAccessToken();
//           return instance(originalRequest);
//       }, (error) => {
//           if(error) {
//               localStorageService.clearToken();
//               router.push({ name: 'Login' });
//               return instance(originalRequest);
//           }
//       });
//       // .then(res => {
//       //     alert(res)
//       //     if (res.status === 401) {
//       //         router.push('/login');
//       //         return instance(originalRequest);
//       //     }
//       //     else if (res.status === 200) {
//       //         localStorageService.setToken(res.data);
//       //             instance.defaults.headers.common['Authorization'] = localStorageService.getAccessToken();
//       //         return instance(originalRequest);
//       //     }
//       // })
// }

    return Promise.reject(error);
});

export default customAxios;
